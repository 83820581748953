import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Editbox, Button, Util, TabButton, Error, Formgroup, Combobox } from 'object/src';
import { URL, EID, PTYPE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  .p-line { ${cs.w.full} ${cs.border.top} ${cs.border.darkgray} ${cs.h.get(1)} ${cs.max.wmd} ${cs.align.bottom} }

  &.ad-setting { ${cs.bg.trans}
    .tab-grp { ${cs.m.t30} ${cs.p.l20} }
    .hp-frame { ${cs.p.a20} }
  }

  &.sect {
    ${cs.m.v20} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(80)}
    ${cs.max.wlg} ${cs.w.full}
    .edit-box, .combo-box { ${cs.m.t10} .guide { ${cs.font.xs} ${cs.font.lightprimary} ${cs.m.t3} } }
    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} }
    & > .sec-box { ${cs.p.a10} }
    p.noti { ${cs.font.sm} ${cs.font.bold} ${cs.font.yellow} ${cs.m.t5} ${cs.font.line(16)}
      ${cs.font.prewrap}
      &.hover:hover { ${cs.font.underline} ${cs.mouse.pointer} } 
    }

    .g-btn { ${cs.align.rtop} ${cs.top(0)} }
  }

  &.set-order { ${cs.pos.relative} ${cs.m.b50}
    .btns { ${cs.align.lbottom} ${cs.w.fit} 
      & > .button { ${cs.m.r20} }
    }
    .form-grp {
      ${cs.over.unset}
    }
  }

  @media screen and (max-width : 600px) {
    &.ad-setting .hp-frame { ${cs.p.h10} }
    .tab-button { ${cs.p.l10} .tab-frame .tab-btn { ${cs.p.h5} } }
    .button .btn-label { ${cs.p.h5} ${cs.font.xs} }
    &.sect { .save { ${cs.m.r10} } .test-btn { ${cs.m.l10} } }
  }
}`;

const STS = ST.SETTING;

const Setting = (props) => {
  const [refresh, setRefresh] = useState(new Date());
  const [tab, setTab] = useState('orderlist');

  const doReload = (code) => {
    Util.showAlert(props, code);
    setRefresh(new Date());
  }

  const onClick = (eid, item, e) => {
    setTab(eid);
  }

  const TABS = [
    { id: 'orderlist', title: STS.TITLE },
  ];

  const issys = Util.isSystemAdmin();
  if (!issys) return <Error className={'t3'} title={ST.LOGINSYSADMIN} />

  return <StyledObject className={cx('ad-setting')}>
    <TabButton className={"tab-grp"} size={'md'} onClick={onClick}
      list={TABS} select={tab} color={'gd-gray'} />
    {/* {tab === 'order' && <div className={cx('hp-frame', tab)}>
      <SetOrder doReload={doReload} refresh={refresh} openConfirm={props.openConfirm} />
    </div>} */}
    {tab === 'orderlist' && <div className={cx('hp-frame', tab)}>
      <SetOrderlist doReload={doReload} refresh={refresh} openConfirm={props.openConfirm} />
    </div>}
  </StyledObject>
};

export default Setting;

// const SetOrder = (props) => {
//   var refs = {};
//   const [data, setData] = useState(null);
//   const [list, setList] = useState(null);
//   const [modified, setModified] = useState(false);

//   useEffect(() => {
//     actions.doSelect(URL.API.APPINFO, { stag: 'package' }).then(({ result }) => {
//       let json = {};
//       result.map(a => json[a.skey] = a.sval);
//       setList(result && result.length > 0 ? result : null);
//       setData(json);
//       setModified(false);
//     });
//     return () => { }
//   }, [props.refresh]);

//   const onSave = (eid, e) => {
//     if (!refs) return;

//     const isvalidate = Object.keys(refs).every((key) => refs[key].isValidateJson());
//     if (!isvalidate) return;

//     const array = Object.keys(refs).map(key => {
//       const item = list && list.find(a => a.skey === key);
//       return { rowid: item ? item.cfgid : null, stag: 'package', skey: key, sval: refs[key].getValue() }
//     });

//     actions.doInsert(API.SETTING, array).then(({ code, result }) => {
//       props.doReload && props.doReload(code);
//       setModified(false);
//     });

//     return;
//   }

//   if (!data) return null;

//   return <StyledObject className={cx('ws-package sect')}>
//     <p className={'title'}>{STS.PACKAGE}</p>
//     <div className={'sec-box'}>
//       <Editbox ref={(ref) => { refs.startprodid = ref }} className={"startprodid"} value={data.startprodid}
//         name="startprodid" type="text" validate={true} onChange={() => setModified(true)} />
//       <p className={'noti'} >{`${STS.PACKAGE_START}`}</p>
//     </div>

//     <Button className={'red bottom right save'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
//   </StyledObject>
// }


const SetOrderlist = (props) => {
  const SFORM = (no = 0) => { return { id: '', title: '', type: '' } };
  // const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [modified, setModified] = useState(false);
  const [noti, setNoti] = useState(null);
  const [rowid, setRowid] = useState(null);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    setNoti(null);
    setModified(false);

    actions.doSelect(URL.API.PRODUCT, {}).then((res) => {
      const temps = res && res.value && res.value.map(a => {
        a['name'] = `[${a.menuname}]${a.title}`;
        a['id'] = a.prodid;
        (!a.title) && (a.name = `N/A(${a.prodid}`);
        return a;
      })
      setProduct(temps);

      actions.doSelect(URL.API.APPINFO, { stag: 'package', skey: 'set' }).then(({ result }) => {
        const { sval, cfgid } = result && result.length > 0 && result[0];
        if (sval) {
          const arrays = JSON.parse(sval);
          setList(arrays);
          setRowid(cfgid);
        }
      });
    });
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const onSave = (eid, e) => {

    const valid = list && list.every(a => a.title && a.id && a.month && a.type);
    if (!valid) {
      setTimeout(() => setNoti(''), 2000);
      return setNoti(STS.INPUT_ALL);
    }

    const value = JSON.stringify(list);
    actions.doInsert(URL.API.SETTING, { rowid: rowid || null, stag: 'package', skey: 'set', sval: value }).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onClick = (eid) => {
    if (eid === EID.NEW) {
      setList(list ? [...list, { ...SFORM(list.length + 1) }] : [{ ...SFORM(1) }]);
    } else if (eid === EID.DELETE) {
      setList(list ? [...list.slice(0, list.length - 1)] : [{ ...SFORM(1) }]);
    } else {
      return;
    }

    setModified(true);
  }

  const onChange = (value, label, pos) => {
    const array = [...list];
    array[pos][label] = value;
    setList(array);
    setModified(true);
  }

  const onChangeCombo = (item, label, pos) => {
    const value = item.id;
    const array = [...list];
    array[pos][label] = value;
    if (label === 'id') {
      // console.dir(item);
      array[pos]['menuid'] = item.menuid;
      array[pos]['title'] = item.name;
    } else if (label === 'type') {
      array[pos]['type'] = item.id;
    }
    setList(array);
    setModified(true);
  }

  if (!product) return null;

  const months = [{ id: 0, name: ST.SELECTION }, { id: '1', name: STS.MONTH(1) }, { id: '3', name: STS.MONTH(3) },
  { id: '6', name: STS.MONTH(6) }, { id: '12', name: STS.MONTH(12) }];
  const plist = [{ id: 0, name: ST.SELECTION }, ...product];
  const combos = [{ id: 0, name: ST.SELECTION }, { id: PTYPE.nurishop, name: PTYPE.nurishop.toUpperCase() }, { id: PTYPE.nurihome, name: PTYPE.nurihome.toUpperCase() }];

  return <StyledObject className={cx('set-order sect')}>
    <p className={'title'}>{STS.PACKAGE}</p>
    <div className={'sec-box'}>
      <div className={'s-row'}>
        <span className={'btns'}>
          <Button className={'green new sm'} title={ST.ADD} eid={EID.NEW} onClick={onClick} />
          <Button className={'black delete sm'} title={ST.DELETE} eid={EID.DELETE} onClick={onClick} />
        </span>
        <ul>
          <p className={'noti'}>{`${STS.PACKAGE_START}`}</p>
          {list && list.map((item, i) => {
            const { title = '', id = '', month = '', type = PTYPE.nurishop } = item;
            return <li className={'li'} key={i}>
              <Formgroup inline={true}>
                <Combobox className={'full dark sm'} flex={"2 1 120px"} options={{ text: { align: 'left' } }} list={plist}
                  onChange={(s, e, v) => onChangeCombo(v, 'id', i)} pos={id ? plist.findIndex(a => String(id) === String(a.id)) : 0}
                  label={STS.PACK_ID} guide={STS.PACK_ID} />
                <Editbox type="text" label={STS.PACK_TITLE} validate={true} onChange={(v, e, l) => onChange(v, 'title', i)}
                  value={title} maxLength={30} flex={"2 1 120px"} guide={STS.PACK_TITLE} />
                <Combobox className={'full dark sm'} flex={"1 1 80px"} options={{ text: { align: 'left' } }} list={months}
                  onChange={(s, e, v) => onChangeCombo(v, 'month', i)} pos={month ? months.findIndex(a => String(month) === String(a.id)) : 0}
                  label={STS.PACK_MONTH} guide={STS.PACK_MONTH} />
                <Combobox className={'full dark sm'} flex={"1 1 80px"} options={{ text: { align: 'left' } }} list={combos}
                  onChange={(s, e, v) => onChangeCombo(v, 'type', i)} pos={type ? combos.findIndex(a => String(type) === String(a.id)) : 0}
                  label={STS.PACK_TYPE} guide={STS.PACK_TYPE} />
              </Formgroup>
            </li>
          })}
        </ul>
        {noti && <p className={'noti'}>{`${noti}`}</p>}
      </div>
    </div>
    <Button className={'red bottom right'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
  </StyledObject>
}
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Button, Util, TabButton, Error, Tablebox, Svg, Progress, Pagenavi, Loading, Editbox } from 'object/src';
import { URL, EID, CODE, PTYPE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import moment from 'moment';
import { PopupCreate, PopupEditor, PopupToolbar, PopupBacklist, PopupUpgrade, PopupSetter, PopupChangeSchema } from './WebmanCreater';

const StyledObject = styled.div`{
  .p-line { ${cs.w.full} ${cs.border.top} ${cs.border.darkgray} ${cs.h.get(1)} ${cs.max.wmd} ${cs.align.bottom} }

  &.ad-webman { ${cs.bg.trans} ${cs.pos.relative}
    .adw-head { ${cs.max.w(1400)} ${cs.pos.relative} ${cs.m.t30} ${cs.p.l20}
      .l-grp { .h-btn { ${cs.m.l10} } .reload { ${cs.m.l30}} }
      .r-grp { ${cs.float.right} .h-btn { ${cs.m.l10} } }
    }
    .hp-frame { ${cs.p.a20} }
    .up-time { ${cs.align.rtop} ${cs.font.gray} ${cs.top(50)} ${cs.right(10)} ${cs.font.sm} }
  }

  &.sect {
    ${cs.m.v20} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(80)}
    ${cs.max.w(1400)} ${cs.w.full}
    .edit-box, .combo-box { .guide { ${cs.font.xs} ${cs.font.lightprimary} ${cs.m.t3} } }
    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} }
    & > .sec-box { ${cs.p.a10} }
    .sec-tool { ${cs.w.full} ${cs.font.right} & > .button { ${cs.m.l20} } }
    p.noti { ${cs.font.sm} ${cs.font.bold} ${cs.font.yellow} ${cs.m.t5} ${cs.font.line(16)}
      ${cs.font.prewrap}
      &.hover:hover { ${cs.font.underline} ${cs.mouse.pointer} } 
    }

    .g-btn { ${cs.align.rtop} ${cs.top(0)} }
  }

  &.wm-proc {
    .p-tit {${cs.disp.block} ${cs.pos.relative} ${cs.border.bottom}
      .title { ${cs.disp.inblock} ${cs.border.none} }
      .t-filter { ${cs.m.l20}}
      .r-grp { ${cs.align.right} ${cs.top(0)} .h-btn { ${cs.m.l10} } }
    }
    // .t-filter { ${cs.align.top} ${cs.top(0)} }
  }

  &.wm-docker { ${cs.pos.relative} ${cs.m.b50}
    .tab-head {
      ${cs.align.rtop} ${cs.top(50)} ${cs.right(10)}
      .t-find { ${cs.m.r10}}
    }
    .btns { ${cs.align.lbottom} ${cs.w.fit} 
      & > .button { ${cs.m.r20} }
    }
    .tb-col { ${cs.pos.relative}
      .t-icon { ${cs.align.ycenter} ${cs.left(5)} }
      .t-sid { &:hover { ${cs.font.underline} } }
      .t-btn { .svg-icon { ${cs.m.h3} } }
      .dn-icon { ${cs.align.right} ${cs.right(10)} ${cs.opac.alpha} }
    }
    .form-grp {
      ${cs.over.unset}
    }
  }

  @media screen and (max-width : 600px) {
    &.ad-webman .hp-frame { ${cs.p.h10} }
    .tab-button { ${cs.p.l10} .tab-frame .tab-btn { ${cs.p.h5} } }
    .button .btn-label { ${cs.p.h5} ${cs.font.xs} }
    &.sect { .save { ${cs.m.r10} } .test-btn { ${cs.m.l10} } }
  }
}`;

const STW = ST.WEBMAN;

var select = null;
var refreshtimer = null;
const REFRESHTIME = 1000 * 30; //30초마다 갱신  

const Webman = (props) => {
  const [refresh, setRefresh] = useState(new Date());
  const [tab, setTab] = useState('webman');
  const [popup, setPopup] = useState(null);
  const [editor, setEditor] = useState(null);
  const [toolbar, setToolbar] = useState(null);
  const [schema, setSchema] = useState(null);
  const [backlist, setBacklist] = useState(null);
  const [setter, showSetter] = useState(null);
  const [upgrade, setUpgrade] = useState(null);
  const [list, setList] = useState(null);
  const [dockerlist, setDockerlist] = useState(null);

  useEffect(() => {
    refreshtimer = setInterval(() => setRefresh(new Date()), REFRESHTIME);

    actions.doSelect(URL.API.PACKAGE, {}).then(({ result }) => {
      setDockerlist(result);
    })

    return () => {
      clearInterval(refreshtimer);
    }
  }, []);

  const onClick = (eid, item, e) => {
    select = null;
    setTab(eid);
  }

  const onClickProc = (eid, item, e) => {
    select = item;
    if (eid === EID.SELECT) {
      setTab('docker');
    } else if (eid === 'list') {
      setBacklist(item);
    } else {
      setEditor(item);
    }
  }

  const onClickDocker = (eid, item, e) => {
    if (eid === 'upgrade') {
      setUpgrade(item);
    } else if(eid === 'schema') {
      setSchema(item);
    } else {
      setPopup(item);
    }
  }

  const onSelectDocker = (eid, item, e) => {
    setToolbar(item);
  }

  const onRefresh = () => {
    setRefresh(new Date());
  }

  const onReload = () => {
    if (tab === 'docker' && select) {
      actions.doSelect(API.STATUS, { sid: select.sid }).then(({ result }) => {
        setRefresh(new Date());
      })
    } else {
      setRefresh(new Date());
    }
  }

  const TABS = [
    { id: 'webman', title: STW.WEBMAN },
    { id: 'docker', title: STW.DOCKER },
  ];

  const issys = Util.isSystemAdmin();
  if (!issys) return <Error className={'t3'} title={ST.LOGINSYSADMIN} />

  return <StyledObject className={cx('ad-webman')}>
    <div className={'adw-head'}>
      <span className={'l-grp'}>
        <TabButton className={"tab-grp"} size={'md'} onClick={onClick} list={TABS} select={tab} color={'gd-gray'} />
        <Button className={'primary h-btn reload'} title={ST.RELOAD} onClick={onReload} />
        <Button className={'gd-gray h-btn'} title={ST.HELPER} onClick={() => window.open(`${URL.GUIDE}/nuriman`)} />
      </span>
      <span className={'r-grp'}>
        <Button className={'orange h-btn'} title={'WEBMAN'} onClick={() => showSetter(true)} />
      </span>
    </div>
    {tab === 'webman' && <div className={cx('hp-frame', tab)}>
      <WebmanProc dockerlist={dockerlist} doReload={onReload} refresh={refresh} onClick={onClickProc} />
    </div>}
    {tab === 'docker' && <div className={cx('hp-frame', tab)}>
      <WebmanDocker item={select} doReload={onReload} refresh={refresh} onClick={onClickDocker}
        onSelect={onSelectDocker} onList={(arr) => setList(arr)} />
    </div>}
    {popup && <PopupCreate data={select} param={list} dockerlist={dockerlist} onClick={() => setPopup(null)} onRefresh={onRefresh} />}
    {editor && <PopupEditor data={select} dockerlist={dockerlist} onClick={() => setEditor(null)} onRefresh={onRefresh} />}
    {toolbar && <PopupToolbar data={select} param={toolbar} dockerlist={dockerlist} onClick={() => setToolbar(null)} onRefresh={onRefresh} />}
    {backlist && <PopupBacklist data={select} param={backlist} onClick={() => setBacklist(null)} onRefresh={onRefresh} />}
    {upgrade && <PopupUpgrade data={select} param={upgrade} dockerlist={dockerlist} onClick={() => setUpgrade(null)} onRefresh={onRefresh} />}
    {setter && <PopupSetter data={select} onClick={() => showSetter(false)} />}
    {schema && <PopupChangeSchema data={select} param={schema} dockerlist={dockerlist} onClick={() => setSchema(null)} />}
  </StyledObject>
};

export default Webman;

const API = URL.API.WEBMAN;

var uploadindex = -1;

const WebmanProc = (props) => {
  const { dockerlist } = props;

  const [uplist, setUplist] = useState(null);
  const [upgrade, setUpgrade] = useState(null);
  const [version, setVersion] = useState(null);

  const [list, setList] = useState(null);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [filter, setFilter] = useState('notest');
  const order = 'sid asc';
  const limit = 15;

  useEffect(() => {
    doReload();
    // uploadindex = -1;

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const doReload = (p = page, s = search, o = order, l = limit, stab = filter) => {
    let value = {};

    if (s && s.value) {
      value['where'] = `${s.key} like '%${s.value}%'`;
    }

    if (stab === 'notest') {
      value['where'] = value['where'] ? value['where'] + ` and sid not like '%wmlocal%'` : `sid not like '%wmlocal%'`;
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    actions.doList(API.PROC, value).then(({ result }) => {
      if (!result.page) return;

      setTotal(result.page.total)
      setPage(result.page.current);
      setMax(result.page.max);
      setSearch(s);

      // let arrays = result.list && [...result.list.filter(a => a.sid.indexOf('wm') >= 0), ...result.list.filter(a => a.sid.indexOf('wm') < 0)];
      let arrays = result.list && [
        ...result.list.filter(a => { if (a.sid.indexOf("wm") >= 0) { a['disable'] = true; return true } else return false }),
        ...result.list.filter(a => { if (a.sid.indexOf("2021001") >= 0) { a['disable'] = true; return true } else return false }),
        ...result.list.filter(a => a.sid.indexOf("wm") < 0 && a.sid.indexOf("2021001") < 0)
      ]

      // if (filter === 'notest') arrays = arrays.filter(a => a.sid.indexOf("wmlocal") < 0);

      const { app, info, dockers, backups } = result;
      arrays && arrays.forEach(item => {
        item.sval = item.sval ? JSON.parse(item.sval) : null;
        let temp = app && app.find(a => a.sid === item.sid);
        item['app'] = temp ? JSON.parse(temp.sval) : null;

        temp = info && info.find(a => a.sid === item.sid);
        item['info'] = temp ? JSON.parse(temp.sval) : null;

        temp = backups && backups.find(a => a.sid === item.sid);
        item['backups'] = temp ? JSON.parse(temp.sval) : null;

        temp = dockers && dockers.find(a => a.sid === item.sid);
        item['dockers'] = temp ? temp.count : 0;

        item['isupdate'] = false;
      });

      setList(arrays);

      props.onList && props.onList(arrays);
    })
  };

  // const onClickSearch = (value, key, e) => {
  //   doReload(1, { key: key, value: value });
  // }

  const onClickPage = (p) => {
    doReload(p);
  }

  const onClickItem = (eid, rowid, data, e) => {
    switch (eid) {
      case EID.SELECT:
      case EID.MENU:
      case 'list':
        props.onClick && props.onClick(eid, data, e); break;
      default: break;
    }
  }

  const onClickTabs = (eid) => {
    setFilter(eid);
    doReload(page, search, order, limit, eid);
  }

  if (!list) return null;

  const TABS = [{ id: 'all', title: 'ALL' }, { id: 'notest', title: 'NOTEST' }];

  const doUpgrade = (eid) => {
    let array = dockerlist && dockerlist.filter(a => a.repo.indexOf(`sentron/${eid}`) === 0);
    array = array && array.sort((a, b) => { return (a.name > b.name) ? -1 : 1; });
    const repo = array ? array[0] : null;

    const type = eid === PTYPE.nurihome ? 'wh' : 'ws';
    const upgrades = list && list.filter(a => a.app && a.app.sid.indexOf(type) === 0);
    setUplist(upgrades);

    global.openConfirm({
      type: 'info', size: 'sm', msg: ST.WEBMAN.DOCK_UPGRADE_CONFIRM(eid, repo ? repo.name : ''), className: 'dark',
      onClicked: (isOk) => {
        if (isOk) {
          if (!repo) {
            global.showAlert(`${eid} ${ST.WEBMAN.DOCK_UPGRADE_FAIL}`);
            return;
          }

          uploadindex = 0;
          setVersion(repo.name);
          const item = upgrades[uploadindex];
          item && runUpgrade(item.sid, (arr) => {
            setUpgrade(arr);
          });
        }
      }
    });
  }

  const runUpgrade = (sid, callback) => {
    const value = { limit: 10, order: "sid desc", page: 1, where: `type='container' and sid='${sid}' and repo not like '%nginx-proxy%'` };

    actions.doList(API.DOCKER, value).then(({ result }) => {
      if (!result.page) return;
      let temps = result.list.map(a => a.size);
      temps && (temps = temps.filter(a => a !== 'nuriman'));
      callback(temps);
    })
  }

  const onAutoRun = (eid) => {
    console.dir(eid);

    const doRunNext = () => {
      setUpgrade(null);

      // 업그레이드 상태 ON
      let pitem = uplist[uploadindex];
      pitem = list.find(a => a.rowid === pitem.rowid)
      pitem && (pitem.isupdate = true);
      setList([...list]);

      // 다음 nuriman 앱 업그레이
      uploadindex = uploadindex + 1;
      const item = uplist[uploadindex];
      item && setTimeout(() => {
        runUpgrade(item.sid, (arr) => {
          console.dir(arr);
          setUpgrade(arr);
        });
      }, 1000);

      if (!item) {
        uploadindex = -1;
        global.openConfirm({
          type: 'info', size: 'sm', className: 'dark', msg: ST.WEBMAN.DOCK_UPGRADE_DONE, cancel: false,
          onClicked: (isOk) => {
            setTimeout(() => {
              list.forEach(a => a.isupdate = false);
              setList([...list]);
            }, 3000);
          }
        });
      }
    }

    if (eid === 'finish') {
      setTimeout(() => doRunNext(), 3000);
    }
  }

  return <React.Fragment>
    <StyledObject className={cx('wm-proc sect')}>
      <div className={'p-tit'}>
        <p className={'title'}>{STW.PROC}</p>
        <TabButton className={"t-filter"} size={'md'} onClick={onClickTabs} list={TABS} select={filter} color={'gd-gray'} />
        <span className={'r-grp'}>
          <Button className={'green h-btn'} title={'NURIHOME UPGRADE'} onClick={() => doUpgrade(PTYPE.nurihome)} />
          <Button className={'green h-btn'} title={'NURISHOP UPGRADE'} onClick={() => doUpgrade(PTYPE.nurishop)} />
        </span>
      </div>
      <span className={'up-time'}>{`UPTIME : ${moment(props.refresh).format('HH:mm:ss')}`}</span>

      <div className={'sec-box'}>
        {list.map(item => <StatBox key={item.sid} width={260} data={item} onClick={onClickItem} />)}
      </div>

      <div>
        <span className={'s-total'}>{`TOTAL: ${total}`}</span>
        {max > 1 && <Pagenavi className={'s-navi white'} page={page} max={max} onClick={onClickPage} />}
      </div>
      {/* {running && <Loading type={'ring'} />} */}
    </StyledObject>
    {uplist && upgrade && uploadindex > -1 && <PopupUpgrade data={uplist[uploadindex]} param={upgrade} dockerlist={dockerlist}
      onClick={() => setUpgrade(null)} onAutoRun={onAutoRun} version={version} />
    }
  </React.Fragment>
}

const WebmanDocker = (props) => {
  const [list, setList] = useState(null);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [running, setRunning] = useState(false);
  const [filter, setFilter] = useState('container'); // all, container, image
  const [find, setFind] = useState('nuripack'); // all, nuripack, rest

  const order = 'sid desc';
  const limit = 15;

  const head = [
    // { key: 'rowid', title: "ID", flex: '1 1 100px' },
    {
      key: 'sid', title: "SID", flex: '2 1 80px',
      formatter: (v, item) => {
        if (item.type === 'image') {
          return <span>{v}</span>
        } else {
          const color = v.indexOf('wmlocal') >= 0 ? 'black' : cs.color.lightgray;
          return <div className={cx(props.item && 't-sid')} onClick={(e) => onClickUrl(e, item)} >
            <Svg className={cx('sm white t-icon')} icon={'cd'} />
            <span style={{ color }}>{v}</span>
          </div>
        }
      }
    },
    {
      key: 'repo', title: "REPO", flex: '2 1 140px', align: 'left',
      formatter: (v, item) => {
        let color = cs.color.white;
        switch (v) {
          case 'sentron/nuriman': color = cs.color.primary; break;
          case 'sentron/nurihome': color = cs.color.green; break;
          case '<none>': color = cs.color.red; break;
          case 'sentron/nurishop': color = cs.color.greenhover; break;
          default: color = cs.color.lightgray; break;
        }
        const title = v.replace('sentron/', '')
          .replace('jrcs/letsencrypt-nginx-proxy-companion', 'letsen-proxy')
          .replace('jwilder/nginx-proxy', 'nginx-proxy');
        const tag = item.size && item.size.replace('undefined', '');
        const name = item.type === 'container' && tag ? `[${tag}]${title}` : title;
        return <div style={{ color }}>{name}</div>
      }
    },
    {
      key: 'type', title: "TYPE", flex: '1 1 60px',
      formatter: (v, item) => {
        if (v === 'image') {
          return <div>{item.tag}</div>
        } else {
          return <div>
            <span>{item.tag || 'latest'}</span>
          </div>
        }
      }
    },
    {
      key: 'size', title: "SIZE/NAME", flex: '2 1 80px',
      formatter: (v, item) => {
        if (item.type === 'image') {
          return <div>{v}</div>
        } else {
          const onClickDown = (eid, e) => {
            e.stopPropagation();
            const { app } = props.item;
            const url = `http://${app.ip}:${app.port}${item.backup}`;
            window.open(url, '_blank');
          }
          return <div style={{ color: cs.color.orange }}>
            {item.backup && <Svg className={'sm white dn-icon'} style={{ marginTop: '5px' }} icon={'down'} onClick={onClickDown} />}
            <span>{v}</span>
          </div>
        }
      }
    },
    {
      key: 'status', title: "STATUS", flex: '3 1 100px',
      formatter: (v, item) => {
        const color = v.indexOf('seconds') >= 0 ? cs.color.red : cs.color.green;
        return <div style={{ color }}>
          <span>{v}</span>
        </div>
      }
    },
    { key: 'crea', title: "CREATED", flex: '2 1 100px' },
    // { key: 'ctime', title: ST.CTIME, flex: '1 1 60px', type: 'datetime', format: 'YY.MM.DD', mobile: 'hide' },
    {
      key: 'ctime', title: "CONTROL", flex: '1 1 80px',
      formatter: (v, item) => {
        const onClickButton = (eid, e) => {
          e.stopPropagation();
          if (eid === 'menu') {
            if (item && props.item) {
              props.onSelect && props.onSelect('select', item, e);
            }
          } else if (eid === 'schema') {
            if (item && props.item) {
              props.onSelect && props.onClick('schema', item, e);
            }
          }
        }

        return <div className={'t-btn'} style={{ marginTop: '3px' }}>
          <Svg className={'md white'} icon={'menu'} eid={'menu'} onClick={onClickButton} />
          <Svg className={'md white'} icon={'link'} eid={'link'} onClick={(eid, e) => onClickUrl(e, item)} />
          <Svg className={'md white'} icon={'setting'} eid={'schema'} onClick={onClickButton} />
        </div>
      }
    },
    { key: 'utime', title: ST.UTIME, flex: '1 1 60px', type: 'datetime', format: 'YY.MM.DD', mobile: 'hide' },
  ];

  const onClickUrl = (e, item) => {
    e.stopPropagation();

    if (!item || !item.opts) return;
    let { domain } = JSON.parse(item.opts);
    const arr = domain && domain.split(",")
    domain = (arr) && arr[0];

    (domain) ? window.open(`https://${domain}`) : global.showAlert({ msg: STW.UNKNOW_URL });
  }

  useEffect(() => {
    // if (props.item) {
    //   setSearch({ key: 'sid', value: props.item.sid });
    //   doReload(page, { key: 'sid', value: props.item.sid });
    // } else {
    //   doReload();
    // }
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const searchs = [
    // { name: 'DOCKID', id: 'rowid' },
    { name: 'NAME', id: 'size' },
    { name: 'REPO', id: 'repo' },
    { name: 'TYPE', id: 'type' },
    { name: 'SYSTEM ID', id: 'sid' },
  ];

  const doReload = (p = page, s = search, o = order, l = limit, f = filter, fd = find) => {
    let value = {};

    value['where'] = '';

    if (f && f !== 'all') {
      value['where'] = `type='${f}'`;
    }

    if (props.item && props.item.sid) {
      value['where'] && (value['where'] += ' and ');
      value['where'] += `sid = '${props.item.sid}'`;
    }

    if (fd && fd !== 'all') {
      value['where'] && (value['where'] += ' and ');
      if (fd === 'nuripack') value['where'] += `repo not like '%nginx-proxy%'`; //`repo like 'sentron/nuri%'`;
      else if (fd === 'rest') value['where'] += `repo not like 'sentron/nuri%'`;
    }

    if (s && s.value) {
      value['where'] && (value['where'] += ' and ');
      value['where'] += `${s.key} like '%${s.value}%'`;
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    actions.doList(API.DOCKER, value).then(({ result }) => {
      if (!result.page) return;

      setTotal(result.page.total)
      setPage(result.page.current);
      setMax(result.page.max);
      setSearch(s);
      setList(result.list);

      props.onList && props.onList(result.list);
    })
  };

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  const onClickDelete = (rowid, event) => {
    const item = list.find(a => String(a.rowid) === String(rowid));


    if (item) {
      if (item.repo.indexOf('nuriops') >= 0 || item.repo.indexOf('nuriman') >= 0) {
        return global.openConfirm({ type: 'info', size: 'sm', className: 'dark', msg: ST.NO_DELETE, cancel: false });
      }

      let value = '';
      global.openConfirm({
        size: 'sm', type: 'info', className: 'dark', title: STW.IMAGE_DELETE,
        children: () => {
          const onChange = (v, e, l) => { value = v; }

          return <div>
            <p style={{ color: cs.color.orange, fontSize: '12px', padding: '10px 0' }}>{STW.IS_IMAGE_DELETE}</p>
            <Editbox className={"i-limit"} type={'string'} size={'sm'} placeholder={'delete'}
              value={value} onChange={onChange} />
          </div>
        },
        onClicked: (isOk) => {
          if (isOk) {
            if (value === 'delete' && props.item && props.item.app) {
              const { app } = props.item;
              const prarm = { ...app, key: app.skey, type: item.type, rowid: item.rowid, mid: item.mid };
              Util.showSuccessAlert(props);
              actions.doRun(API.DOCK_DELETE, prarm).then((res) => {
                const { code } = res;
                if (code !== CODE.SUCCESS) Util.showFailAlert(props);

                setTimeout(() => {
                  setRunning(false);
                  props.doReload && props.doReload();
                }, 1000);
              });

            } else {
              Util.showFailAlert(props);
            }
          }
        }
      });
    }
  }

  const onSelect = (rowid, e) => {
    const item = list.find(a => String(a.rowid) === String(rowid));
    if (item && props.item) {
      props.onSelect && props.onSelect('select', item, e);
    }
  }

  const onNew = (e) => {
    props.onClick && props.onClick('new', props.item, e);
  }

  const onClick = (eid, e) => {
    if (eid === 'clear') {
      setRunning(true);
      const value = props.item ? props.item.app : { sid: 'all', key: 'all' };
      actions.doRun(API.DOCK_CLEAR, { ...value, key: value.skey }).then((res) => {
        const { code } = res;
        if (code !== CODE.SUCCESS) Util.showFailAlert(props);

        setTimeout(() => {
          setRunning(false);
          props.doReload && props.doReload();
        }, 1000);
      });
    } else if (eid === 'upgrade') {
      const array = list && list.filter(a => (a.type === 'container' && a.repo !== 'sentron/nuriman')).map(a => a.size);
      props.onClick && props.onClick('upgrade', array, e);
    }
    // console.dir(eid);
  }

  const onClickTab = (eid) => {
    setFilter(eid);
    doReload(1, search, order, limit, eid);
  }

  const onClickFind = (eid) => {
    setFind(eid);
    doReload(1, search, order, limit, filter, eid);
  }

  if (!list) return null;

  const TABS = [{ id: 'all', title: 'ALL' }, { id: 'container', title: 'CONTAINER' }, { id: 'image', title: 'IMAGE' }];
  const FIND = [{ id: 'all', title: 'ALL' }, { id: 'nuripack', title: 'NURIPACK' }, { id: 'rest', title: 'REST' }];

  return <StyledObject className={cx('wm-docker sect')}>
    <p className={'title'}>{STW.DOCKER}</p>
    <span className={'up-time'}>{`UPTIME : ${moment(props.refresh).format('HH:mm:ss')}`}</span>
    <span className={'tab-head'}>
      <TabButton className={"t-find"} size={'md'} onClick={onClickFind} list={FIND} select={find} color={'gd-gray'} />
      <TabButton className={"t-filter"} size={'md'} onClick={onClickTab} list={TABS} select={filter} color={'gd-gray'} />
    </span>
    <div className={'sec-box'}>
      <Tablebox className={cx('dark')} naviClass={'white'} head={head} list={list}
        searchkey={search ? search.key : searchs[0].id}
        onClickSearch={onClickSearch} onClickPage={onClickPage} onClickDelete={onClickDelete}
        onSelect={props.item ? (r, e) => onSelect(r, e) : null}
        pos={page} max={max} total={total} searchs={searchs} rowid="rowid" />
    </div>
    <div className={'sec-tool'}>
      {props.item && <React.Fragment>
        <Button className={'green'} title={STW.DOCK_UPGRADE} onClick={(eid, e) => onClick('upgrade', e)} />
        <Button className={'green'} title={STW.DOCK_CLEAR} onClick={(eid, e) => onClick('clear', e)} />
        <Button className={'red'} title={ST.NEW} eid={EID.NEW} onClick={onNew} />
      </React.Fragment>}
    </div>
    {running && <Loading type={'ring'} />}
  </StyledObject>
}

const StyledStat = styled.div`{ 
  &.stat-box {
    ${cs.w.get(340)} ${cs.h.get(200)} ${cs.box.line} ${cs.border.radius(3)} ${cs.mouse.pointer}
    ${cs.disp.inblock} ${cs.p.a5} ${cs.p.h10} ${cs.m.a5} ${cs.pos.relative} ${cs.noselect}

    &.update { ${cs.border.orange} }
    &:hover { ${cs.border.white} }
    &.disable { ${cs.font.darkgray} }

    & > div { ${cs.size.full} ${cs.pos.relative} ${cs.disp.block} }

    &.fail { ${cs.bg.get('#4c0000d6')} ${cs.opac.get(0.7)} };
    
    .stat-head { ${cs.pos.relative}
      .stat-tl {
        ${cs.font.left} ${cs.font.md} ${cs.font.line(30)} ${cs.p.l5}
        ${cs.mouse.pointer}
      }
      .stat-list { ${cs.right(30)} }
      // &:hover { .stat-set .svg-path { ${cs.fill.orange} } }
    }

    .stat-dt {
      ${cs.font.right} ${cs.font.sm} ${cs.pos.absolute} ${cs.bottom(0)} ${cs.w.full}
      ${cs.opac.get(0.5)}
    }

    .stat-grp {
      ${cs.h.calc('100% - 40px')} ${cs.w.full} ${cs.pos.absolute} ${cs.bottom(20)} 
      ${cs.font.center} ${cs.p.t20}

      .itm-a { ${cs.p.v10} ${cs.pos.relative} ${cs.disp.block} }
      // .itm-b { ${cs.align.bottom} ${cs.disp.block} ${cs.w.full} }

      .st-itm { ${cs.h.get(20)} ${cs.w.full} ${cs.disp.inblock} ${cs.pos.relative}
        .st-icon { ${cs.align.right} ${cs.w.get(14)} ${cs.z.over} }
        .st-prog { ${cs.w.calc('100% - 20px')} }
      }
    }

    .stat-off {
      ${cs.h.get(30)} ${cs.w.full} ${cs.align.center} ${cs.font.t0}
    }

  }
}`;

export const StatBox = (props) => {
  const { data, sel } = props;

  const onClicked = (eid, e) => {
    e.stopPropagation();
    props.onClick && props.onClick(eid, data.rowid, data, e);
  }

  const onSelect = (e) => {
    e.stopPropagation();
    props.onClick && props.onClick(EID.SELECT, data.rowid, data, e);
  }

  const onClickList = (eid, e) => {
    e.stopPropagation();
    props.onClick && props.onClick('list', data.rowid, data, e);
  }

  const { app, sval, stext = '', utime, sid, dockers = 0 } = data;
  const title = sid || 'no name';
  const time = moment(utime).format("YYYY.MM.DD HH:mm:ss");
  const status = sval;
  const active = sel === sid;
  const isnull = !(sval && stext);
  const host = app ? app.domain ? app.domain : `${app.ip}:${app.port}` : '';

  const cpu = status.cpu ? Number(status.cpu.use).toFixed(0) : 0;
  const mem = status.cpu ? Number(status.mem.use).toFixed(0) : 0;
  const disk = status.cpu ? Number(status.disk.use).toFixed(0) : 0;
  const disable = data && data.disable;
  const update = data && data.isupdate;

  return <StyledStat className={cx("stat-box", { active }, (status), { disable }, { update })} style={{ width: props.width ? (props.width - 60) + 'px' : '400px' }}>
    <div onClick={onSelect}>
      <div className="stat-head">
        <p className="stat-tl" onClick={(e) => onClicked('title', e)} >{title}</p>
        <Svg className="stat-set md right top" icon={"slider"} onClick={(eid, e) => onClicked('menu', e)} />
        <Svg className="stat-list md right top" icon={"list"} onClick={onClickList} />
      </div>

      {!isnull && <div className="stat-grp">
        <span className={'itm-a'}>
          <span className={'st-itm'}>{host}</span>
          <span className={'st-itm'}>{`PROCESS(${dockers})`}</span>
          {/* <span className={'st-itm'}>{app && app.sid}</span> */}
        </span>

        <span className={'itm-b'}>
          <span className="st-itm cpu"><Svg className="st-icon xs white" icon="cpu" />
            <Progress className={"st-prog white radius"} value={cpu} limit={80} unit={"%"} islevel />
          </span>
          <span className="st-itm mem"><Svg className="st-icon xs white" icon="mem" />
            <Progress className={"st-prog white radius"} value={mem} limit={80} unit={"%"} islevel />
          </span>
          <span className="st-itm disk"><Svg className="st-icon xs white" icon="disk" />
            <Progress className={"st-prog white radius"} value={disk} limit={80} unit={"%"} islevel />
          </span>
        </span>
      </div>}

      <p className="stat-dt">{time}</p>
      {isnull && <div className={"stat-off"}>{ST.NO_SERVICE}</div>}
    </div>
  </StyledStat>
}
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { openConfirm, openModal, openSidemenu, showAlert, eventLayout } from 'object/src/actor/Reducer';
import { connect } from 'react-redux';
import * as actions from 'object/src/actor/Action';

// 기본적인 스타일 선언.
import './css/Default.css';

import { Storage, cs, Button, Sidebar, Layout, Svg, Error } from 'object/src';
import { ST } from 'svc/Lang';
import { URL } from 'svc/Enum';
import cx from 'classnames/bind';
import { STAT } from './object/src/Config';
import styled from 'styled-components';
import { Login, Main, Management, Webstat, Schema, Setting, Webman, GuideBox } from 'view';
import { Util } from 'object/src/Utils';

const StyledObject = styled.div`{
  &.no-admin { ${cs.bg.white}
    .p-noti { ${cs.w.full} ${cs.font.t3} ${cs.font.center} ${cs.align.ycenter} ${cs.font.darkgray} ${cs.font.himelody} 
      ${cs.mouse.pointer}
    }
  }

  &.admin-layout { ${cs.bg.dark}
    .admin-frame { ${cs.bg.dark} ${cs.font.white} ${cs.min.wxl} }
    .guide-btn { ${cs.pos.fixed} ${cs.z.get(199999)} ${cs.left(0)} ${cs.bottom(20)} 
      ${cs.font.white} ${cs.font.thickbold} ${cs.font.lg} ${cs.opac.get(0.3)}
      &:hover { ${cs.opac.show} }
    }

    @media screen and (max-width : 1024px) { 
      .admin-frame { ${cs.min.width('100%')} }
      .guide-btn { ${cs.left('none')} ${cs.right(0)}  ${cs.bottom(5)} }
    }
  
    @media screen and (max-width : 600px) { 
    }
  
    @media screen and (max-width : 480px) { }
    }
  }

  &.noti-box {
    ${cs.pos.fixed} ${cs.right(0)} ${cs.top(0)} ${cs.disp.inblock}
    ${cs.float.right} ${cs.z.popup} ${cs.noselect}

    .nt-button {
      ${cs.pos.relative} ${cs.right(20)} ${cs.top(10)} ${cs.disp.inblock}
      ${cs.size.get(40)} ${cs.float.right} ${cs.border.radius(50)} 
      ${cs.p.get(6)} ${cs.box.line} ${cs.anim.slidein('200ms', '120%', '0', 'noti-btn')} 
      .nt-count { 
        ${cs.align.rtop} ${cs.left(-2)} ${cs.top(0)} ${cs.bg.red} ${cs.font.white}
        ${cs.font.thickbold} ${cs.font.center} ${cs.border.radius(50)} ${cs.font.xs}
        ${cs.h.get(16)} ${cs.font.line(16)} ${cs.w.get(24)} ${cs.z.over}
      }

      .svg-path { ${cs.fill.get('yellow !important')} ${cs.opac.get("1 !important")} }

      .alarm { ${cs.anim.flicking("500ms", '1', '0.3')} }
    }

    .nt-frame {
      ${cs.font.xs} ${cs.font.white} ${cs.w.get(200)} ${cs.z.over}
      ${cs.bg.get('rgba(0,0,0,0.9)')} ${cs.h.fit} ${cs.p.a10} ${cs.align.rbottom}
      ${cs.top(30)} ${cs.right(50)} ${cs.border.radius(5)} ${cs.mouse.pointer}
      ${cs.disp.none}

      .n-li { ${cs.h.get(34)} ${cs.border.bottom} ${cs.pos.relative} ${cs.p.t5} ${cs.border.dark}
        .n-title { ${cs.disp.block} }
        .n-date { ${cs.disp.inblock} ${cs.align.rbottom} ${cs.bottom(2)} ${cs.font.gray} }
        &:last-child { ${cs.border.none} }
      }

      &.show { ${cs.disp.block} ${cs.anim.slideup('200ms', '-120%', '0', 'noti-anim')} }
    }
  }
}`;

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    const guid = Storage.getLocalItem('guid');
    if (!guid) {
      Storage.setLocalItem('guid', Util.getUuid());
    }

    this.menu = [
      { name: ST.MENU.MAIN, url: URL.MAIN, comp: Main, title: ST.MENU.MAIN },
      { name: ST.MENU.WEBSTAT, url: URL.WEBSTAT, comp: Webstat, title: ST.MENU.WEBSTAT },
      { name: ST.MENU.WEBMAN, url: URL.WEBMAN, comp: Webman, title: ST.MENU.WEBMAN },
      { name: ST.MENU.SCHEMA, url: URL.SCHEMA, comp: Schema, title: ST.MENU.SCHEMA },
      { name: ST.MENU.MANAGEMENT, url: URL.MANAGEMENT, comp: Management, title: ST.MENU.MANAGEMENT, hide: true, type: 'system' },
      { name: ST.MENU.SETTING, url: URL.SETTING, comp: Setting, title: ST.MENU.SETTING },
      { name: ST.LOGOUT, url: URL.LOGOUT, comp: null, title: ST.LOGOUT },
    ];

    this.state = { menus: [...this.menu], loaded: true };

    global.openConfirm = this.openConfirm;
    global.openModal = this.openModal;
    global.showAlert = (v) => this.props.showAlert(v);
    global.closeConfirm = (v) => this.props.openConfirm({ ...v, show: false });
    global.closeModal = (v) => this.props.openModal({ ...v, show: false });
    global.onLoaded = this.props.onReady;
  }

  doLoadPageAndMenu = () => {
    actions.doSelect(URL.API.TOKEN).then(({ result, config }) => {
      this.setState({ loaded: true });
    })
  }

  openConfirm = (value) => {
    value.type = value.type == null ? '' : value.type;
    value.show = true;
    value.size = value.size == null ? '' : value.size;
    value.className = value.className == null ? '' : value.className;
    value.title = value.title == null ? ST.ALARM : value.title;
    value.ok = value.ok == null ? ST.OK : value.ok;
    value.cancel = value.cancel == null ? ST.CANCEL : value.cancel;
    value.msg = value.msg == null ? ST.IS_DELETE : value.msg;
    value.children = value.children || null;
    value.onClicked = value.onClicked == null ? null : value.onClicked;
    this.props.openConfirm(value);
  }

  openModal = (value) => {
    value.show = true;
    value.className = value.className == null ? '' : value.className;
    value.title = value.title == null ? ST.ALARM : value.title;
    value.children = value.children == null ? null : value.children;
    value.data = value.data == null ? {} : value.data;
    value.size = value.size == null ? '' : value.size;
    value.desc = value.desc == null ? '' : value.desc;
    value.state = value.state == null ? STAT.N : value.state;
    value.ok = value.ok == null ? ST.OK : value.ok;
    value.cancel = value.cancel == null ? ST.CANCEL : value.cancel;
    this.props.openModal(value);
  }

  render() {
    const props = this.props;
    const { menus = null } = this.state;

    if (!menus) return null;

    const user = Storage.getLocalItem(Storage.key.userinfo);
    const { pathname } = window.location;

    if (pathname === URL.SIGNIN) {
      window.location.href = URL.LOGIN;
      return null;
    }

    return (
      <Router>
        <Switch>
          {/* 로그인 권한이 필요하지 않은 화면들... */}
          <Route exact path={URL.LOGIN} render={(matchProps) => <Login {...matchProps} onLoaded={() => { }} />} {...props} /> {/* 로그인 */}
          <Route exact path={URL.LOGOUT} render={(matchProps) => { Storage.logout(); window.location.href = URL.LOGIN; return null; }} /> {/* 로그아웃 */}

          {/* 관리자 메뉴 */}
          {menus.map((item, index) => {
            // 로그인을 하지 않은 상태면 로그인 화면으로 이동
            if (!user && pathname === URL.ROOT) {
              setTimeout(() => {
                Util.logout();
                window.location.href = URL.LOGIN;
              }, 1000);
              return <Error className={'t3'} title={ST.LOGINADMIN} />
            }

            // 시스템 관리자 메뉴 활성화
            if (user && user.auth === 'system') menus.map(a => a['type'] && a['type'] === 'system' && (a.hide = false));

            // 관리자 메뉴 표시
            return (<LayoutAdmins key={String(index)} exact path={item.url} component={item.comp} {...item} {...props} menus={menus} />)
          })}

          <Route path={URL.GUIDE} render={(matchProps) => <GuideBox {...matchProps} onLoaded={() => { }} />} /> {/* 도움말 */}
          <Route render={(matchProps) => { window.location.href = URL.LOGIN; return <div></div>; }} />

          {/* 그외의 화면 접근 에러 */}
          <Route component={Error} {...props} />
        </Switch>
      </Router>
    );
  };
};


/**
 * 관리자 레이아웃
 */
const LayoutAdmins = (props) => {
  const [company, setCompany] = useState(null);

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'company', skey: 'name' }).then(({ result }) => {
      setCompany(result && result.length > 0 ? result[0].sval : '');
    });

    document.getElementById("body-frame").classList.add("admin-body");

    global.onLoaded = (isok) => {
      props.onReady && props.onReady();
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Component = props.component;

  return (
    <Route {...props.computedMatch} render={matchProps => {
      global.hist = matchProps.history;
      return <AdminLayout title={company ? company.toUpperCase() : ''} menus={props.menus}
        root={URL.MAIN} sidebar={'240px'} topbar={'60px'}
        eventLayout={props.eventLayout} matchProps={matchProps} >
        <Component {...matchProps} showAlert={global.showAlert}
          openModal={global.openModal} openConfirm={global.openConfirm} />
      </AdminLayout>
    }} />
  )
};

const AdminLayout = (props) => {
  const { title, menus, root, sidebar, topbar } = props;
  return <StyledObject className="admin-layout" >
    <Sidebar title={title} list={menus} root={root} sidebarW={sidebar} topbarH={topbar}
      eventLayout={(value) => props.eventLayout && props.eventLayout(value)} />
    <Layout className="admin-frame" leftPadding={sidebar} topPadding={topbar}>
      {props.children}
    </Layout>
    <Notibox onEvent={props.onEvent} />
  </StyledObject>
}

const INTERVAL = 1 * 60 * 1000;
var timer = null;
var pcount = 0;

const Notibox = (props) => {
  const [show, setShow] = useState(false);
  const [list, setList] = useState(null);
  const [count, setCount] = useState(0);
  const [alarm, setAlarm] = useState(false);

  useEffect(() => {
    let body = document.getElementById('body');
    body && body.addEventListener('mouseup', onResize);
    doReload();
    timer = setInterval(() => doReload(), INTERVAL);  // 1분마다

    return () => {
      body = document.getElementById('body');
      body && body.removeEventListener('mouseup', onResize);
      timer && clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResize = (e) => {
    // const { type } = Util.getScreenType();
    const a = Util.isSelfClick(e, (item) => {
      return item.indexOf("n-li") >= 0;
    });
    if (a) return;

    setShow(false);
  }

  const doReload = () => {
    actions.doSelect(URL.API.NOTI).then((res) => {
      if (!res || res.count < 1 || res.count === pcount) return;
      pcount = res.count;
      const array = res && res.value.map(a => {
        if (Util.isJson(a.sval)) {
          a['value'] = a.sval && Util.parseJson(a.sval);
          a['title'] = a.value && a.value.title;
        } else {
          a['value'] = a.sval;
          a['title'] = a.sval;
        }
        a['time'] = a.ctime && Util.toStringSymbol(a.ctime, "/").substr(5, 18);
        return a;
      })
      setList(array);
      setCount(res ? res.count : 0);

      props.onEvent && props.onEvent({ list: array, count: count });

      setAlarm(true);
      setTimeout(() => setAlarm(false), 1000);
    })
  }

  const onClick = (e) => {
    e.stopPropagation();
    setShow(true);
  }

  const onSelect = (item, e) => {
    if (item && item.value) {
      actions.doUpdate(URL.API.ADMIN.NOTI, { ...item }).then(({ code, result }) => { });

      const { url = null, param = null } = item.value;
      url && actions.go(url, param && { ...param });
      setCount(count > 0 ? count - 1 : 0);
      props.onEvent && props.onEvent({ count: count > 0 ? count - 1 : 0 });
    }
    setShow(false);
  }

  const onClear = (eid, e) => {
    e.stopPropagation();
    const values = list.map(a => a.rowid);
    actions.doUpdate(URL.API.ADMIN.NOTI_CLEAR, { values }).then(({ code, result }) => {
      const { err } = result;

      if (err) return;
      if (result && result.list) {
        const array = result.list.map(a => {
          if (Util.isJson(a.sval)) {
            a['value'] = a.sval && Util.parseJson(a.sval);
            a['title'] = a.value && a.value.title;
          } else {
            a['value'] = a.sval;
            a['title'] = a.sval;
          }
          a['time'] = a.ctime && Util.toStringSymbol(a.ctime, "/").substr(5, 18);
          return a;
        })
        setList(array);
        setShow(false);
        setTimeout(() => setShow(true), 200);

        setCount(result.count || 0);
        props.onEvent && props.onEvent({ list: result.list, count: result.count || 0 });
      } else {
        setList(null);
        setShow(false);
        setCount(0);
        props.onEvent && props.onEvent({ list: null, count: 0 });
      }
    });
  }

  return <StyledObject className={'noti-box'}>
    {/* noti 메시지 개수 표시 아이콘 */}
    {count > 0 && <div className={cx("nt-button")}>
      <span onClick={onClick}>
        <Svg className={cx("md black")} icon='alarm' onClick={(eid, e) => onClick(e)} />
        {count > 0 && <span className={cx('nt-count', { alarm })}>{count > 99 ? "99+" : count}</span>}
      </span>
    </div>}
    {/* noti 리스트 프레임 */}
    <div className={cx("nt-frame", { show })}>
      <ul>
        {list && list.map((item, i) => {
          const { title, time } = item;
          return <li key={i} className={'n-li'} onClick={(e) => onSelect(item, e)}>
            <span className={'n-title'}>{`${title}`}</span>
            <span className={'n-date'}>{`${time}`}</span>
          </li>
        })}
      </ul>
      <div className={"nt-foot"}>
        {list && list.length > 0 && <Button className={'n-li sm full'} onClick={onClear} title={ST.ADMIN.NOTI.CLEAR(list.length)} />}
      </div>
    </div>
  </StyledObject>
}

const mapDispatchToProps = (dispatch) => {
  return {
    openConfirm: (obj) => dispatch(openConfirm(obj)),
    openModal: (obj) => dispatch(openModal(obj)),
    showAlert: (obj) => dispatch(showAlert(obj)),
    openSidemenu: (obj) => dispatch(openSidemenu(obj)),
    eventLayout: (obj) => dispatch(eventLayout(obj)),
  };
};

export default connect(null, mapDispatchToProps)(App);
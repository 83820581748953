import * as OBJ from 'object/src/Config';

export const EID = OBJ.EID;
export const KEY = OBJ.KEY;
export const CODE = OBJ.CODE;
export const CONT_TYPE = OBJ.CONT_TYPE;
export const SCREEN = OBJ.SCREEN;
export const STAT = OBJ.STAT;
export const AUTH = OBJ.AUTH;

export const SIZE = {
  full: { label: 'full', width: '100%' },
  lg: { label: 'lg', width: '1200px' },
  md: { label: 'md', width: '1000px' },
  sm: { label: 'sm', width: '800px' },
  none: { label: 'none', width: 'AUTO' },
}

export const ERR = {
  ER_DUP: 'ER_DUP_ENTRY',
  STR: {
    ER_DUP_LOGIN_ID: '중복된 아이디 입니다.',
  },
}

export const PTYPE = {
  nurishop: 'nurishop',
  nurihome: 'nurihome',
  nuriohome: 'nuriohome',
  nurioshop: 'nurioshop',
}

export const EVENT = {
  CLICKED: 'clicked',
}

export const MEMTYPE = {
  SIMPLE: 'simple',
  NORMAL: 'normal',
  MEMBER: 'member',
}

export const STEP = {
  ZERO: "step0",
  ONE: "step1",
  TWO: "step2",
  THREE: "step3",
  OPTIONS: "options",
  ORDER: "order",
}

const ROOT = {
  PAGE: '/page',
  API: {
    ROOT: '/api',
    PAGE: '/api/page',
    WEBMAN: '/api/webman',
  }
}

export const URL = {
  ROOT: '/',
  HOME: '/',
  MAIN: '/',

  LOGIN: '/login',
  SIGNIN: '/signin',
  LOGOUT: '/logout',
  WEBSTAT: '/webstat',
  SCHEMA: '/schema',
  MANAGEMENT: `/mgmt`,
  SETTING: `/setting`,
  WEBMAN: `/webman`,
  GUIDE: '/guide',

  API: {
    LOGOUT: `${ROOT.API.ROOT}/logout`,
    TOKEN: `${ROOT.API.ROOT}/token`,
    APPINFO: `${ROOT.API.ROOT}/appinfo`,

    MAIN: `${ROOT.API.PAGE}/main`,
    NOTI: `${ROOT.API.PAGE}/noti`,
    ADMINS: `${ROOT.API.PAGE}/admins`,
    SETTING: `${ROOT.API.PAGE}/setting`,
    WEBAPP: `${ROOT.API.PAGE}/webapp`,
    WEBAPP_PAY: `${ROOT.API.PAGE}/webapp/pays`,
    WEBAPP_PAY_MAIL: `${ROOT.API.PAGE}/webapp/pays/mail`,
    LAYOUTS: `${ROOT.API.PAGE}/layouts`,
    WEBSTAT: `${ROOT.API.PAGE}/webstat`,
    WEBSTAT_USERS: `${ROOT.API.PAGE}/webstat/users`,
    WEBSTAT_ORDER: `${ROOT.API.PAGE}/webstat/orders`,
    WEBSTAT_URL: `${ROOT.API.PAGE}/webstat/url`,
    SCHEMA: `${ROOT.API.PAGE}/schema`,
    SCHEMA_USERS: `${ROOT.API.PAGE}/schema/users`,
    SCHEMA_GRENT: `${ROOT.API.PAGE}/schema/grent`,
    TABLE: `${ROOT.API.PAGE}/table`,
    PRODUCT: `${ROOT.API.PAGE}/product`,

    ADMINS_PASS: `${ROOT.API.PAGE}/admins/pass`,
    MGMT_LOGS: `${ROOT.API.PAGE}/mgmt/logs`,
    MGMT_TABLE: `${ROOT.API.PAGE}/mgmt/table`,
    MGMT_SCHEMA: `${ROOT.API.PAGE}/mgmt/schema`,
    MGMT_CONN_TEST: `${ROOT.API.PAGE}/mgmt/conn/test`,

    PACKAGE: `${ROOT.API.PAGE}/package`,

    WEBMAN: {
      PROC: `${ROOT.API.WEBMAN}/proc`,
      DOCKER: `${ROOT.API.WEBMAN}/docker`,
      STATUS: `${ROOT.API.WEBMAN}/appinfo`,
      INSTALL: `${ROOT.API.WEBMAN}/web/inst`,
      CONFIG: `${ROOT.API.WEBMAN}/web/config`,
      INITIAL: `${ROOT.API.WEBMAN}/web/init`,
      BACKUP: `${ROOT.API.WEBMAN}/web/backup`,
      DBDUMP: `${ROOT.API.WEBMAN}/web/dbdump`,
      DBCLONE: `${ROOT.API.WEBMAN}/web/dbclone`,
      DOMAIN: `${ROOT.API.WEBMAN}/web/domain`,
      RESTART: `${ROOT.API.WEBMAN}/web/restart`,
      DBCLEAR: `${ROOT.API.WEBMAN}/web/dbclear`,
      SCHEMA_CHANGE: `${ROOT.API.WEBMAN}/web/schema`,
      DOCK_CLEAR: `${ROOT.API.WEBMAN}/web/dock/clear`,
      DOCK_UPGRADE: `${ROOT.API.WEBMAN}/web/dock/upgrade`,
      DOCK_DELETE: `${ROOT.API.WEBMAN}/web/dock/delete`,
      CMDSTATUS: `${ROOT.API.WEBMAN}/cmd/status`,
    }
  },
};
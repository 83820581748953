import * as OBJ from "object/src/Config";

const KOR = {
  ...OBJ.ST,

  START_YEAR: "2020",
  COMPANY_NAME: "COMPANY",
  COMPANY_KOR: "회사명",
  COPYRIGHT: (name = '', y = 2020, isfull = false) => isfull ? `Copyright © ${name} ${y && String(y).substr(0, 4)}, All rights reserved.` : `© ${y && String(y).substr(0, 4)} ${name}`,
  CARDVIEW: "카드보기",
  TABLEVIEW: "리스트보기",
  MEM_COUNT: "명",
  YESTERDAY: "어제",
  TODAY: "오늘",
  WEEK: "주일",
  MONTH: "개월",
  MEMBER_TOTAL: "전체",
  T_SEARCH: "배송조회",
  SEND: '보내기',
  SHORTCAT: '바로가기',
  BACK: '뒤로',
  SELECT: '선택',
  HELPER: '도움말',
  TFORMAT: 'YYYY-MM-DD HH:mm분',
  SUBJECT: "제목",
  NOADMIN: "이 화면은 관리자권한으로 접근이 제한되었습니다.",
  LOGINADMIN: "관리자 권한으로 로그인해야 합니다.\n관리자 로그인 화면으로 이동합니다.",
  LOGINSYSADMIN: "시스템 관리자 권한이 필요합니다.\n시스템 관리자로 다시 로그인하세요.",
  ADMIN_LOGIN: "처음사용을 위해 시스템을 설정해야합니다. 시스템관리자로 로그인하세요.",
  YES: "예",
  NO: "아니오",
  WRITE: "글쓰기",
  MOBILE_NOTI: "휴대폰에서는 정상적으로 보이지 않을수 있습니다.",
  HOUR: "시",
  MINITE: "분",
  SECOND: "초",
  GB: "GB",
  MB: "MB",
  KB: "KB",
  FROM: '까지',
  TO: '부터',
  NO_DELETE: '삭제할수 없습니다.',
  RELOAD: "상태갱신",
  READY: '준비중',

  PASSWORD: "비밀번호",
  PASSWORD_EDIT: "비밀번호변경",
  OR: "또는",
  WON: "원",
  NOLIST: (v) => `${v} 내역이 없습니다.`,
  BOARD_TITLE: (v = '') => `${v ? v + '에서 ' : ''}알려드립니다.`,
  REQUMENT_ERROR: (v = '') => `${v}는 필수 입력사항입니다.`,
  PREVPAGE: '이전 페이지',
  NEXTPAGE: '다음 페이지',
  MAXLEN: (t = '', v = 20) => `${t}을 ${v}글자 이내로 작성하세요.`,

  MENU: {
    HOME: '홈',
    MAIN: '메인',
    WEBSTAT: '웹앱관리',
    SCHEMA: '데이터베이스',
    MANAGEMENT: '시스템관리',
    SETTING: '시스템설정',
    WEBMAN: '웹매니져',
    LOGIN: '로그인',
  },

  MAIN: {
    USERS: '사용자',
    WEBAPP: '웹시스템',
    WEBSTAT: '상태정보',
    RESET_PWD: "기본 비밀번호는 보안에 취약합니다.\n관리자 비밀번호를 재발급하세요.",
    HOST: 'Host',
    APPTYPE: 'Type',
    USERID: 'Userid',
    ACCOUNTID: 'Accountid',
    UPTIME: 'Uptime',
    DISK: 'Disk',
    UPLOAD: 'Upload',
    TABLE: 'Table',
    LOGS: 'Logs',
    SSL: 'SSL',
    USERSET: '사용자등록',
    CREAPP: '앱 생성',
    USER_CHANGE: '사용자를 정말로 변경 하시겠습니까?',
    HISTORY: '구매내역',
    PAYS: 'Pay',
    LIMIT: 'Limit',
    NOUSER: '사용자를 먼저 설정하세요.',
    SEND_PAY_EMAIL: `결제 안내 이메일을 발송합니다.`,
    SET_LIMIT: '만료기한을 수정하시겠습니까?',
    SET_LIMIT_A: '만료기한은',
    SET_LIMIT_B: '일까지입니다.',
    INIT: (v = "") => `초기설정${v && `(${v === 'OK' ? '결제' : '미결제'})`}`,
    LIMITDAYS: (v = 0) => `${v < 0 ? `기한종료 (${Math.abs(v)}일지남)` : `만료기한 (${v}일남음)`}`,
  },

  SETTING: {
    TITLE: '설정',
    PACKAGE: '패키지(제품) 정보 설정',
    PACKAGE_START: '패키지의 타이별 데이터를 설정합니다. 초기/설치, 월구독, 년구독 스타일을 설정. 메인의 구매 이력에서 사용합니다.',
    PACK_TITLE: '상품명',
    PACK_ID: '상품 아이디(번호)',
    PACK_TYPE: '상품 타입',
    PACK_MONTH: '상품 개월(1~12)',
    INPUT_ALL: '값을 모두 입력하세요.',
    MONTH: (v = 0) => `${v}개월`,
  },

  SCHEMA: {
    TITLE: "Schema",
    SCHEMA: "Schema",
    HOST: "Host",
    USER: "User",
    GRENTS: "Grents",
    TABLELIST: "Table list",
    ROWS: "Rows",
    SIZE: "Size",
    TABLE: "테이블",
    NO_DEL_SCHEMA: "스키마 내에 데이터가 있어 삭제할 수 없습니다. 데이터를 모두 삭제한후 다시 실행하세요.",
    NO_DEL_USERS: "사용자에 연결된 테이블이 있어 삭제할 수 없습니다. 확인후 다시 실행하세요.",
    CONN_SUCCESS: "DB 연결에 성공하였습니다.",
    CONN_FAIL: "DB 연결에 실패하였습니다. 설정 정보를 확인후 다시 시도하세요.",
    CREAT_SCHEMA: "새로운 테이블 스키마 생성중...",

    NOTI: {
      NOPASS: '인증코드 및 비밀번호를 입력하세요',
      FAIL: '처리중 오류가 발생했습니다.',
      SUCCESS: '정상적으로 되었습니다.',
      TYPE_WARN: 'database(schema)는 h_ 또는 s_ 로 시작해야 합니다.',
      STYPE_WARN: 'NURIHOME/NURISHOP 중 하나를 선택하세요.',
    },
  },

  LOGIN: {
    TITLE: "로그인",
    WELCOME: `관리자 로그인`,
    PASSWORD_FIND: "비밀번호 찾기",
    NOTI_LOGIN: "로그인 정보를 정확히 입력하세요.",
  },

  MEMBER: {
    TITLE: '회원',
    NO: "번호",
    USERID: "회원번호",
    ACCOUNTID: "사용자아이디",
    AUTH: "권한",
    NAME: "이름(닉네임)",
    PHONE: "연락처",
    EMAIL: "이메일",
    SDATE: "가입일",
    PASSWORD: "비밀번호",
  },

  USER: {
    TITLE: '회원',
    NO: "번호",
    USERID: "회원번호",
    ACCOUNTID: "사용자아이디",
    AUTH: "권한",
    NAME: "이름(닉네임)",
    PHONE: "연락처",
    EMAIL: "이메일",
    SDATE: "가입일",
    PASSWORD: "비밀번호",
  },

  MGMT: {
    TITLE: '시스템 관리',
    INITS: '시스템 설정',
    SCHEMA: '스키마 생성',
    LAYOUTS: '레이아웃 관리',
    TABLES: '테이블 관리',
    ADMINS: '관리자 설정',
    INFO: '시스템 정보',
    LOGINS: '로그인 로그',
    QUERYS: '쿼리 로그',
    USER: '사용자',
    ADMIN: '관리자',

    NO_ENGLISH: '계정은 영문 및 숫자만 입력할수 있습니다.',
    PASSWORD: "특수문자/영문/숫자를 조합하여 8~20자로 입력하세요",
    NO_DELETE_ADMIN: "기본 관리자는 삭제할수 없습니다.",
    PASS_RESET: (v) => `비밀번호가 ${v} 로 변경되었습니다.`,

    ROWID: '고유아이디',
    USERID: '회원번호',
    ACCOUNTID: '계정아이디(권한)',
    ACCOUNT: '계정아이디',
    NAME: '이름',
    AUTH: '권한',
    CONTENT: '내용',
    LOGINTIME: '마지막로그인시간',

    TYPE: '타입',
    QUERY: '쿼리 내용',
    ERROR: '에러 내용',

    TABEL: '테이블명',
    ROWS: '레코드수',
    COMMENT: '설명',
    SIZE: '사용량(MB)',
    CLEAR: '내역정리',
    CLEAR_NOTI: '정리가 필요한 테이블은 [내역정리]에 정리버튼이 활성화됩니다.\n6개월 주기마다 필요에따라 자동정리되므로 정리버튼이 활성화되도 정리가 필수 사항은 아닙니다.',
    TABLE_INIT: '데이터베이스 정보를 재설정하고 시스템을 리부팅힙니다.\n설정이 잘못될 경우, 웹서버에 오류가 발생할 수 있다는 것에 주의하세요.\n웹 시스템을 관리하는 관리자만 이 정보를 설정/관리하시기 바랍니다.',

    THREE: '3개월',
    FOUR: '4개월',
    FIVE: '5개월',
    CLEARL_MSG: '선택한 기간 이전데이터를\n모두 정리(삭제) 하시겠습니까?',

    HOST_SELECT: '호스트를 선택하세요.',
    HOST_ONLY: '특수문자(. - _) 및 영/숫자만 입력할수 있습니다.',
    INPUT_ONLY: 'database/host/user 입력란에는 특수문자(. - _) 및 영/숫자만 입력할수 있습니다.',
    DAB_SAVE: 'DB 설정을 변경 하시겠습니까?\n\n변경후 장애가 발생되면 이후 이 화면에서는 설정이 불가능합니다.',
    CREATEDB: 'DB 생성',
    CREATE_DB_PASS: 'DB 생성을 위한 인증코드를 입력하세요.',
    CREATE_DB_PCODE: 'DB 권한 생성을 위한 비밀번호를 입력하세요.',
    CONN_TEST: '연결테스트',
    CONN_SUCCESS: "DB 연결에 성공하였습니다.\n저장후 리부팅하세요.",
    CONN_FAIL: "DB 연결에 실패하였습니다.\n설정 정보를 확인후 다시 시도하세요.",
    INIT_SUCCESS: (v = 10) => `${v} 초후 시스템이 재시작됩니다.\n재로그인을위해 관리자 로그인 화면으로 이동합니다.`,

    NOTI: {
      NOPASS: '인증코드 및 비밀번호를 입력하세요',
      FAIL: '처리중 오류가 발생했습니다.',
      SUCCESS: '정상적으로 처리중입니다.',
    }
  },

  WEBSTAT: {
    NAME: 'Name(APP ID)',
    URL: 'URL(IP)',
    IP: 'IP',
    ONLY_URL: 'URL',
    DBS: 'DB Schema(host)',
    DISK: 'Disk Space(Usage)',
    UPLOAD: 'Upload Folder(Usage)',
    TABLE: 'Table Size(Rows)',
    LOGS: 'Today Error Log(Logs)',
    LASTLOG: 'Last Error Log',
    LIFETIME: 'Life Time',
    IS_URL: "http 또는 https로 시작해야 합니다.",
    IS_IP: "IP를 정확히 입력하세요(XXX.XXX.XXX.XXX).",
    LAYOUT_DEL: (v = '') => `[${v}]을 정말로 삭제하시겠습니까?`,
    LAYOUT_NEW: (v = '') => `[${v}]을 정말로 추가하시겠습니까?`
  },

  WEBMAN: {
    WEBMAN: 'Webmanager',
    DOCKER: 'Docker',
    PROC: 'Process',
    CONFIG: 'Copy config',
    UPDATE_DOMAIN: 'Update domain(도메인이 여러개일 경우 [,]로 구분)',
    BACKUP_MEDIA: 'Backup media(이미지 및 파일 백업)',
    DATA_DUMP: 'Dump database(데이터베이스 데이터 백업)',
    DATA_CLONE: 'Clone database(데이터베이스를 특정 데이터로 복제)',
    RESTART_CONTAINER: 'Restart container(앱 재시작)',
    DB_INITIAL: 'Database drop and create(데이터베이스 초기화)',
    DB_INITIAL_KEY: '데이터베이스 초기화를 위해 확인키를 입력하세요.',
    TYPE_ERROR: '지원하지 않는 앱입니다. 앱 종류를 확인하세요',
    NO_BACKUP: 'no backup file.',
    NO_DUMPFILE: 'no db dump file.',
    SETAPP: '앱 설정',
    EDITAPP: '앱 수정',
    BACKLIST: '백업 파일 목록',
    UNKNOW_URL: 'URL을 연결할 수 없습니다.',
    DOCK_CLEAR: '프로세스정리',
    DOCK_UPGRADE: '앱업그레이드',
    SCHEMA_CHANGE: '테이블 스키마 전환',
    DOCK_UPGRADE_FAIL: '앱업그레이드를 할수 없습니다.',
    DOCK_UPGRADE_CONFIRM: (s = '', v = '') => `[${s.toUpperCase()}]을 가장 최신버전\n${v ? `[${v}]으로 ` : ''}모두 업그레이드 하시겠습니까?`,
    DOCK_UPGRADE_ERR: '웹 앱이 혼합되어 있으므로 일괄 업그레이드를 이용할수 없습니다.',
    DOCK_UPGRADE_DONE: '웹 앱이 업그레이드가 완료되었습니다.',
    COPYCLIPBOARD: '클립보드에 값이 복사되었습니다.',
    NO_VALID: '값을 모두 입력해야합니다.',
    NO_CONNECT: 'DATABASE에 연결할 수 없습니다. SCHEMA 정보를 확인하세요.',
    OK_CONNECT: 'DATABASE가 정상적으로 연결되었습니다.',
    INSTALL: 'Docker 생성 및 앱 인스톨',
    SCHEMA: '테이블 스키마 생성 및 초기화',
    APPTYPE: 'APP TYPE(앱 타입을 선택하세요)',
    APPTYPE_ERR: 'APP의 종류와 DB 및 Dumpfile의 타입이 일치 하지 않습니다.',
    SETTING: 'APP 초기화 및 설정',
    NAMAING: '* h_2100101 [앱타입 h(nuriohome), s(nurioshop)]_[년도 2자리][서버순번 3자리][앱순번 2자리]\n* 컨테이너 이름은 가운데 _를 삭제한다.',
    IMAGE_DELETE: 'Docker Image',
    DOWNNOTI: 'localhost의 경우 파일 다운로드를 할수 없습니다.',
    IS_IMAGE_DELETE: 'Docker Image를 정말로 삭제하시겠습니까?\n삭제하려면 [delete] 문자를 아래에 입력하세요.',
  },
}


/**
 * 이미지들에 대한 정의
 */
export const IMG = {
  CAFE24_1: require('../images/cafe24_1.jpg'),

  WEBINST_1: require('../images/webinst_1.jpg'),
  WEBINST_2: require('../images/webinst_2.jpg'),

  WEBMAN_1: require('../images/webman_1.jpg'),
}

export const NURIMAN_HELP = [
  { type: 'text', value: '# nuriweb sverver 초기 설치 및 세팅 순서.' },
  { type: 'text', value: '1. 카페 24에서 가상서버 또는 가상 퀵서버 생성.', url: 'https://hosting.cafe24.com/?controller=product_page&type=server&page=virtual_linux' },
  { type: 'image', value: IMG.CAFE24_1, url: 'https://hosting.cafe24.com/?controller=product_page&type=server&page=virtual_linux' },
  { type: 'text', value: '2. SSH 터미널 연결후 디렉토리 생성 및 파일 설치', url: 'https://hosting.cafe24.com/?controller=myservice_hosting_main' },
  { type: 'desc', value: ' - 아래와 같이 설치를 위한 기본 디렉토리 생성' },
  { type: 'desc', value: ' - mkdir /home/nurio /home/nurio/nuriman' },
  { type: 'text', value: '3. nuriman 설치를 위한 초기작업.', url: 'https://ops.nurio.co.kr/webman' },
  { type: 'desc', value: ' - 앱메니저 화면으로 접근하여 상단 버튼에서 compose.yml, nuriman.ins 다운로드' },
  { type: 'image', value: IMG.WEBINST_1, url: 'https://ops.nurio.co.kr/webman' },
  { type: 'desc', value: ' - 다운로드 받은 위 두개의 파일을 nuriman을 설치한 서버의 /home/nurio/nuriman 디렉토리로 복사' },
  { type: 'desc', value: ' - chmod +777 nuriman.ins 명령어로 실행파일로 변경' },
  { type: 'desc', value: ' - /home/nurio/nuriman/nuriman.ins v0.0.1 inst 명령어 실행' },
  { type: 'desc', value: ' - "v0.0.1" : 첫번째 파리메터는 설치 버전에 맞도록 변경' },
  { type: 'desc', value: ' - "inst" : 두번째 파리메터는 docker, docker-compose 설치시 설정' },
  { type: 'desc', value: '정상적으로 설치가 완료될경우 아래와 같이 3개의 docker 프로세스가 실행되어야 한다.' },
  { type: 'image', value: IMG.WEBINST_2 },
  { type: 'text', value: '4. nuriman 설정 및 재시작.', url: 'https://ops.nurio.co.kr/webman' },
  { type: 'desc', value: ' - 앱메니저 화면으로 접근하여 상단 버튼에서 Copy config를 클릭하여 클립보드에 복사된 내용을 아래의 파일에 붙여넣기' },
  { type: 'desc', value: ' - /home/nurio/nuriman/webset/setting.env 파일에 복사' },
  { type: 'desc', value: ' - "sid": wm2021001 처럼 wm + 년도 + 해당년도 생성순번' },
  { type: 'desc', value: ' - "ip": 현재 서버 IP 주소, "port": "28050"' },
  { type: 'desc', value: ' - "domain": 있으면 도메인 주소 없으면 IP' },
  { type: 'desc', value: ' - "skey": 프로세스 생성시등에 사용될 비밀번호(?)' },
  { type: 'desc', value: '\n\n' },
  { type: 'line', value: '2px' },
  { type: 'desc', value: '\n\n' },
  { type: 'text', value: '# nurihome/nurishop 초기 설치 및 세팅 순서' },
  { type: 'text', value: '1. 웹메니져 화면에서 해당 웹시스템 선택.', url: 'https://ops.nurio.co.kr/webman' },
  { type: 'image', value: IMG.WEBMAN_1, url: 'https://ops.nurio.co.kr/webman' },
];


export const ST = KOR;
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Loading, Button, Formgroup, Editbox, Tablebox, Nodata, TabButton, Combobox } from 'object/src';
import { URL, CODE, PTYPE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import moment from 'moment';
import { SysTables } from './Webstat';
import { Util } from 'object/src/Utils';

export const HOSTLIST = [{ id: 'db.nurioshop.co.kr', name: 'db.nurioshop.co.kr' }, { id: 'db.nurioweb.co.kr', name: 'db.nurioweb.co.kr' }];

const StyledObject = styled.div`{
  &.web-schema { ${cs.bg.trans} ${cs.p.h30} ${cs.pos.relative} ${cs.min.h('calc(100vh - 100px)')} ${cs.p.bottom(20)}
    ${cs.max.wlg} ${cs.size.full} ${cs.noselect} 

    &.full { ${cs.max.w('100%')} }

    .title-bar { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} ${cs.m.b20} ${cs.p.t30} 
      ${cs.font.line(36)} ${cs.disp.block} ${cs.pos.relative}
      .tit { ${cs.disp.inblock} ${cs.mouse.pointer} &:hover { ${cs.font.primary} } }
      .sim { ${cs.disp.inblock} ${cs.p.h10} ${cs.font.gray} }
      span { ${cs.font.line(36)} ${cs.disp.inblock} }

      .back, .new { ${cs.align.rbottom} ${cs.right(0)} }
      .tab-grp { ${cs.align.bottom} ${cs.right(0)} }
    }

    .btn-schema { ${cs.disp.none} }
  }

  &.schema-list { ${cs.size.full} ${cs.disp.hide}
    &.show { ${cs.disp.block} ${cs.anim.showin()} }
    &.hide { ${cs.disp.hide} }
    .sl-left { ${cs.align.left} ${cs.pos.relative} ${cs.top(10)}
      .sl-host { ${cs.align.unset} ${cs.float.none} ${cs.min.width(280)} 
        ${cs.font.line(20)} ${cs.pos.relative}
        .cb-sel .cb-txt { ${cs.font.left} }
        .cb-ul { ${cs.min.w(280)} }
      }
    }
    .sl-tab { ${cs.m.l10} }
  }

  &.table-info { ${cs.size.full} ${cs.anim.showin()}
    .g-frame {
      ${cs.disp.block} ${cs.p.h10}
      .g-box {
        ${cs.box.radius} ${cs.bg.darkhover} ${cs.disp.block} ${cs.font.sm}
        ${cs.min.h(60)} ${cs.h.fit} ${cs.p.a10} ${cs.font.line(20)}
      }
    }
  }

  &.table-list { ${cs.max.w('100%')} }

  @media screen and (max-width : 600px) { 
  }
}`;

const API = URL.API;
const STS = ST.SCHEMA;

const Schema = (props) => {
  const [selected, setSelected] = useState(null);
  const [tab, setTab] = useState('main'); // main, table, list

  useEffect(() => {
    const param = actions.popParam();
    if (param && param.tschema) {
      if (param.tablename) {
        setTab('list');
      } else {
        setTab('table');
      }
      setSelected({ ...param })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickMain = (item, e) => {
    setSelected(item);
    setTab('table');
  }

  const onClickTable = (item) => {
    setSelected(item);
    setTab('list');
  }

  const onClose = (eid) => {
    setTab(eid);
  }

  return <StyledObject className={cx("web-schema", tab === 'list' && 'full')}>
    <div className={'frame'}>
      <SchemaList {...props} show={(tab === 'main')} onClick={onClickMain} />
      {tab === 'table' && <TableInfo {...props} item={selected} onClick={onClickTable} onClose={onClose} />}
      {tab === 'list' && <TableList {...props} item={selected} onClose={onClose} />}
    </div>
  </StyledObject >
};

export default Schema;

const SchemaList = (props) => {
  const TABS = [
    { id: 'schema', title: 'schema'.toUpperCase() },
    { id: 'users', title: 'users'.toUpperCase() },
    // { id: 'grent', title: 'grent'.toUpperCase() },
  ]

  const [popup, setPopup] = useState(null);
  const [host, setHost] = useState(HOSTLIST[0].id);
  const [tab, setTab] = useState(TABS[0].id);
  const [reload, setReload] = useState(null);

  useEffect(() => {
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHost = (eid, e, v) => {
    setHost(v.id);
    // doReload(1, null, order, limit, v.id);
  }

  const onClickClose = (reload = false) => {
    setPopup(false);
    reload && setReload(new Date().getTime());
  }

  const onClick = (item) => {
    props.onClick && props.onClick(item);
  }

  const { show } = props;
  return <StyledObject className={cx("schema-list", { show })}>
    <div className={cx('title-bar')}>
      {/* <span className={'tit'} >{ST.SCHEMA.TITLE}</span> */}
      <span className={'sl-left'}>
        <Combobox className={'combo dark md sl-host'} select={host} list={HOSTLIST} onChange={onChangeHost} />
        <TabButton className={"sl-tab"} size={'md'} onClick={(eid) => setTab(eid)} list={TABS}
          select={tab} color={'gd-gray'} />
      </span>
      <Button className={'green new'} title={ST.NEW} onClick={() => setPopup(true)} />
    </div>
    {tab === 'schema' && <SchemaInfo host={host} onClick={onClick} reload={reload} />}
    {tab === 'users' && <SchemaUsers host={host} onClick={onClick} reload={reload} />}
    {popup && <PopupSchema onClose={onClickClose} host={host} />}
  </StyledObject >
};

const SchemaInfo = (props) => {
  const SEARCH = [
    { name: STS.SCHEMA, id: 'table_schema' },
    { name: ST.CTIME, id: 'create_time' },
  ];

  const [loaded, setLoaded] = useState(false);
  const [list, setList] = useState(null);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const order = 'ctime desc';
  const limit = 20;

  const head = [
    { key: 'no', title: ST.NUM, flex: '1 1 40px' },
    { key: 'thost', title: STS.HOST, flex: '3 1 180px' },
    { key: 'tschema', title: STS.SCHEMA, flex: '3 1 180px' },
    { key: 'rows', title: `Appcfg ${STS.ROWS}`, flex: '1 1 100px', type: 'number' },
    { key: 'size', title: STS.SIZE, flex: '1 1 100px', unit: 'MB' },
    { key: 'ctime', title: ST.CTIME, flex: '2 1 100px', type: 'datetime', format: 'YY.MM.DD HH:mm', mobile: 'hide', align: 'left' },
  ];

  useEffect(() => {
    setLoaded(false);
    doReload(1, null, order, limit, props.host);
    // doReload();
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.host, props.reload]);

  const doReload = (p = page, s = search, o = order, l = limit, h = props.host) => {
    let value = {};

    if (s && s.value) {
      value['where'] = `\`${s.key}\` like '%${s.value}%'`;
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    actions.doList(API.SCHEMA, { ...value, host: h }).then(({ result }) => {
      if (!result || !result.page || !result.list) return;

      setTotal(result.page.total)
      setPage(result.page.current);
      setMax(result.page.max);
      setSearch(s);
      setList(result.list);
      setLoaded(true);
    })
  }

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  const onSelect = (id) => {
    let item = list.find(a => a.tschema === id);
    props.onClick && props.onClick(item);
  };

  const onClickDelete = (rid, e) => {
    let item = list.find(a => a.tschema === rid);

    if (item && item.rows > 0) {  // 삭제불가
      global.openConfirm({ type: 'info', size: 'sm', className: 'dark', msg: STS.NO_DEL_SCHEMA, cancel: false });
    } else {
      const param = { pass: 'tmzlaktkrwp', dbs: { host: props.host, database: item.tschema, user: item.tschema }, safemode: 'N' };
      global.openConfirm({
        type: 'info', size: 'sm', className: 'dark', onClicked: (isOk) => {
          (isOk) && actions.doInsert(API.MGMT_SCHEMA, param).then((result) => {
            const { code, err } = result;
            if (code !== CODE.SUCCESS || err) {
              props.showAlert && props.showAlert({ msg: `${STS.NOTI.FAIL}` });
            } else {
              props.showAlert && props.showAlert({ msg: `${STS.NOTI.SUCCESS}` });
            }
            doReload(page, search, order, limit, props.host);
          })
        }
      });
    }
  }

  if (loaded) {
    return <div className={'sch-info'}>
      <Tablebox className={cx('dark sm')} naviClass={'white'} head={head}
        list={list} searchkey={search ? search.key : SEARCH[0].id} onClickDelete={onClickDelete}
        onSelect={onSelect} onClickSearch={onClickSearch} onClickPage={onClickPage}
        pos={page} max={max} total={total} searchs={SEARCH} rowid="tschema" />
    </div >
  } else {
    return <Loading type={'ring'} />;
  }
};


const SchemaUsers = (props) => {
  const SEARCH = [
    { name: STS.SCHEMA, id: 'table_schema' },
    { name: ST.CTIME, id: 'create_time' },
  ];

  const [loaded, setLoaded] = useState(false);
  const [list, setList] = useState(null);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const order = 'user asc';
  const limit = 20;

  const head = [
    { key: 'no', title: ST.NUM, flex: '1 1 40px' },
    { key: 'thost', title: STS.HOST, flex: '2 1 100px' },
    {
      key: 'user', title: STS.USER, flex: '3 1 180px',
      formatter: (v, item) => {
        const { host } = item;
        return <span>{`'${v}'@'${host}'`}</span>
      }
    },
    { key: 'istable', title: 'ISTABLE', flex: '1 1 80px' },
    { key: 'grants', title: STS.GRENTS, flex: '4 1 240px', align: 'left' },
  ];

  useEffect(() => {
    setLoaded(false);
    doReload(1, null, order, limit, props.host);
    // doReload();
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.host, props.reload]);

  const doReload = (p = page, s = search, o = order, l = limit, h = props.host) => {
    let value = {};

    if (s && s.value) {
      value['where'] = `\`${s.key}\` like '%${s.value}%'`;
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    actions.doList(API.SCHEMA_USERS, { ...value, host: h }).then(({ result }) => {
      if (!result || !result.page || !result.list) return;

      setTotal(result.page.total)
      setPage(result.page.current);
      setMax(result.page.max);
      setSearch(s);
      setList(result.list);
      setLoaded(true);
    })
  }

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  const onSelect = (id) => {
    // let item = list.find(a => a.tschema === id);
    // props.onClick && props.onClick(item);
  };

  const onClickDelete = (rid, e) => {
    let item = list.find(a => a.user === rid);

    if ((item && item.istable) || item.user === 'nurio' || item.user === 'nuriman') {  // 삭제불가
      global.openConfirm({ type: 'info', size: 'sm', className: 'dark', msg: STS.NO_DEL_USERS, cancel: false });
    } else {
      const param = { host: props.host, deleteuser: item.user, safemode: 'N' };
      global.openConfirm({
        type: 'info', size: 'sm', className: 'dark', onClicked: (isOk) => {
          (isOk) && actions.doDelete(API.SCHEMA_USERS, param).then((result) => {
            const { code, err } = result;
            if (code !== CODE.SUCCESS || err) {
              props.showAlert && props.showAlert({ msg: `${STS.NOTI.FAIL}` });
            } else {
              props.showAlert && props.showAlert({ msg: `${STS.NOTI.SUCCESS}` });
            }
            doReload(page, search, order, limit, props.host);
          })
        }
      });
    }
  }

  if (loaded) {
    return <div className={'sch-info'}>
      <Tablebox className={cx('dark sm')} naviClass={'white'} head={head}
        list={list} searchkey={search ? search.key : SEARCH[0].id} onClickDelete={onClickDelete}
        onSelect={onSelect} onClickSearch={onClickSearch} onClickPage={onClickPage}
        pos={page} max={max} total={total} searchs={SEARCH} rowid="user" />
    </div >
  } else {
    return <Loading type={'ring'} />;
  }
};

const TableInfo = (props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item]);

  const doReload = () => {
    const { tschema, thost } = props.item;
    actions.doSelect(API.SCHEMA, { schema: tschema, host: thost }).then(({ result }) => {
      let temps = [];
      if (result) {
        result.map(a => (Object.keys(a).map(key => {
          const index = a[key] ? a[key].toLowerCase().indexOf('password') : -1;
          temps.push(index > 0 ? a[key].substr(0, index) + ' PASSWORD *****' : a[key]);
          return null;
        })));
      }
      setData(temps);
    })
  }

  const onSelect = (id) => {
    props.onClick && props.onClick({ ...props.item, tablename: id });
  };

  if (props.item) {
    const { tschema, thost } = props.item;
    return <StyledObject className="table-info">
      <div className={cx('title-bar')}>
        <span className={'tit'} onClick={() => props.onClose && props.onClose('main')}>{ST.SCHEMA.TITLE}</span>
        <span>
          <span className={'sim'}>{'/'}</span>
          <span className={'txt'}>{`${tschema}`}</span>
        </span>
        <Button className={'dark back'} title={ST.BACK} onClick={() => props.onClose && props.onClose('main')} />
      </div>
      <div className={'g-frame'}>
        {data && <ul className={'g-box'}>
          {data.map((a, i) => {
            return <li key={i} className={'g-li'}>{a}</li>
          })}
        </ul>}
      </div>
      {<SysTables item={{ tsid: tschema, host: thost }} showAlert={props.showAlert} onSelect={onSelect} />}
    </StyledObject >
  } else {
    return <Loading type={'ring'} />;
  }
}

const TableList = (props) => {
  const [list, setList] = useState(null);
  const [head, setHead] = useState(null);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [popup, setPopup] = useState(null);
  const order = '';
  const limit = 20;

  useEffect(() => {
    doReload();
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = (p = page, s = search, o = order, l = limit) => {
    let value = { host: props.item && props.item.thost };

    if (s && s.value) {
      value['where'] = `\`${s.key}\` like '%${s.value}%'`;
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    const { tschema, tablename } = props.item;
    actions.doList(API.TABLE, { tschema: tschema, tname: tablename, ...value }).then(({ result }) => {
      if (!result.page || !result.list) return;

      let shead = [];
      if (result.list && result.list[0]) {
        const keys = result.list[0];
        Object.keys(keys).map(key => {
          let type = '';
          let flex = '';

          switch (key) {
            case 'ctime': case 'utime': case 'stime': case 'etime': case 'ltime': type = "datetime"; flex = '3 1 80px'; break;
            case 'rowid': case 'userid': flex = '2 1 70px'; break;
            case 'no': type = 'number'; flex = '1 1 60px'; break;
            case 'pwd': case 'pass': case 'password': return null;
            default: type = 'text'; flex = '5 1 100px'; break;
          }
          shead.push({ key: key, title: key, flex, type });
          return null;
        });
      }

      setHead(head || shead);
      setTotal(result.page.total)
      setPage(result.page.current);
      setMax(result.page.max);
      setSearch(s);
      setList(result.list);
    })
  }

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  const onSelect = (id) => {
    let item = list.find(a => String(a.no) === String(id));
    (item) && setPopup(item);
    props.onClick && props.onClick(item);
  };

  if (list && head && props.item) {
    const { tschema, tablename } = props.item;
    const searchs = head.map(a => { return { name: a.key, id: a.key } }).filter(a => a.id !== 'no');
    return <StyledObject className="table-list">
      <div className={cx('title-bar')}>
        <span className={'tit'} onClick={() => props.onClose && props.onClose('main')}>{ST.SCHEMA.TITLE}</span>
        <span>
          <span className={'sim'}>{'/'}</span>
          <span className={'tit'} onClick={() => props.onClose && props.onClose('table')}>{`${tschema}`}</span>
        </span>
        <span>
          <span className={'sim'}>{'/'}</span>
          <span className={'txt'}>{`${tablename}`}</span>
        </span>
        <Button className={'dark back'} title={ST.BACK} onClick={() => props.onClose && props.onClose('table')} />
      </div>
      {head.length > 0
        ? <Tablebox className={cx('dark sm')} naviClass={'white'} head={head}
          list={list} searchkey={search ? search.key : searchs[0].id}
          onSelect={onSelect} onClickSearch={onClickSearch} onClickPage={onClickPage}
          pos={page} max={max} total={total} searchs={searchs} rowid={head[0].key} />
        : <Nodata />
      }
      {popup && <PopupTable item={popup} onClose={() => setPopup(null)} />}
    </StyledObject >
  } else {
    return <Loading type={'ring'} />;
  }
}


const StyledComp = styled.div`{
  &.popup-schema { ${cs.disp.popup(9999999, cs.color.trans, 600, 400)}
    .pop-box { ${cs.bg.lightblack} ${cs.border.shadow()} 
      .head { ${cs.bg.trans} ${cs.h.get(50)}
        .tit { ${cs.font.t0} ${cs.p.h10} ${cs.font.line(50)} ${cs.h.full} }
      }

      .body { ${cs.h.calc('100% - 100px')} ${cs.font.sm} ${cs.p.a20}
        .stat-box { ${cs.disp.block} ${cs.h.get(30)} ${cs.disp.flex}
          .stat { ${cs.m.r10} ${cs.border.radius(3)} ${cs.h.full} ${cs.w.get(100)} ${cs.disp.inblock} 
            ${cs.bg.gray} ${cs.font.line(30)} ${cs.font.center}
            
            &.title { ${cs.disp.hide} }
            &.success { ${cs.bg.primaryhover} }
            &.fail { ${cs.bg.redhomver} }
          }
        }

        .form-grp { ${cs.over.inherit} }
        .combo-box { .cb-label { ${cs.h.get(16)} ${cs.font.gray} } }
        .p-noti, .p-msg { ${cs.m.t10} ${cs.font.yellow} }
      }

      .foot { ${cs.h.get(50)} ${cs.font.right} ${cs.p.h20} 
        & > .button { ${cs.m.l20} }
        // .cancel { ${cs.align.left} }
      }
    }
  }

  &.popup-table { ${cs.disp.popup(9999999, cs.color.trans, 800, 600)}
    .pop-bg { ${cs.bg.alphablack} }
    .pop-box { ${cs.bg.lightblack} ${cs.border.shadow()} ${cs.h.fit} ${cs.textselect}
      .head { ${cs.bg.trans} ${cs.h.get(50)}
        .tit { ${cs.font.t0} ${cs.p.h10} ${cs.font.line(50)} ${cs.h.full} }
      }
      
      .body { ${cs.h.calc('100% - 100px')} ${cs.font.sm} ${cs.p.a20} ${cs.h.fit} ${cs.max.h(500)}
        ${cs.min.h(400)} ${cs.over.yauto} ${cs.scrollbar.t1}
        .t-ul { ${cs.disp.block} ${cs.disp.block} ${cs.fit}
          .t-li { ${cs.disp.block} ${cs.font.line(18)} ${cs.size.full} ${cs.h.get(18)} ${cs.m.t5}
            ${cs.font.md} ${cs.font.prewrap} ${cs.disp.contents}
            .label { ${cs.font.gray} ${cs.font.sm} ${cs.font.right} ${cs.w.get(80)} 
              ${cs.disp.inblock} ${cs.m.t5} ${cs.align.vertical('top')}
            }
            .text { ${cs.disp.inblock} ${cs.font.left} ${cs.w.calc('100% - 80px')} ${cs.p.l10} ${cs.m.t5} 
              ${cs.p.r50}
              &.link { ${cs.mouse.pointer} ${cs.font.underline} &:hover { ${cs.font.primary} } }
              &.sm { ${cs.font.sm} }
            }
          }          
        }
      }

      .foot { ${cs.h.get(50)} ${cs.font.right} ${cs.p.h20} 
        // .cancel { ${cs.align.left} }
      }
    }
  }

  @media screen and (max-width : 600px) { 
  }
}`;

/*******************************************************************
 Popup
*******************************************************************/
export const PopupSchema = (props) => {
  var refs = {};
  const [noti, setNoti] = useState(`* ${ST.MGMT.INPUT_ONLY}`);
  const [message, setMessage] = useState('');
  const [state, setState] = useState(null);
  const [stype, setStype] = useState(null);
  const [host, setHost] = useState(props.host);
  const [data, setData] = useState({ database: props.table, host: props.host || 'db.nurioshop.co.kr', user: props.user, pass: props.pass });

  useEffect(() => {
    setData({ database: props.table, host: props.host || 'db.nurioshop.co.kr', user: props.user, pass: props.pass });
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValidate = () => {
    // validate 체크하여 통과하지 못하면 false를 리턴(창이 닫히지 않는다.)
    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidate());
    if (!isvalidate) return false;

    const isCheck = (v) => {
      // eslint-disable-next-line no-useless-escape
      const r = /^[0-9a-zA-Z-_\.]*$/;
      return r.test(v); // 형식에 맞는 경우 true 리턴
    };

    if (!refs.database.isValidate(!isCheck(refs.database.getValue()), ST.MGMT.HOST_ONLY)) return false;
    if (!refs.user.isValidate(!isCheck(refs.user.getValue()), ST.MGMT.HOST_ONLY)) return false;
    if (!host) { setNoti(ST.MGMT.HOST_SELECT); return false; }
    if (!stype) { setNoti(STS.NOTI.STYPE_WARN); return false; }

    return true;
  }

  const onCreate = () => {
    if (!isValidate()) return;

    setNoti(STS.CREAT_SCHEMA);

    let values = { host: host };
    Object.keys(refs).map(key => values[key] = refs[key].getValue());
    console.dir(values);

    actions.doInsert(API.MGMT_SCHEMA, { pcode: refs['pass'].getValue(), pass: 'tmzlaktodtjd', dbs: values, stype }).then((result) => {
      const { code, err, status } = result;
      if (code !== CODE.SUCCESS || err) {
        setNoti(`${STS.NOTI.FAIL}`);
      } else setNoti(`${STS.NOTI.SUCCESS}`);

      setState(status);
    })
  };

  const onChange = (v, eid) => {
    if (v.length > 2 && !(v.indexOf('s_') === 0 || v.indexOf('h_') === 0 || v.indexOf('d_') === 0)) {
      setMessage(`${STS.NOTI.TYPE_WARN}`);
    }

    setData({ ...data, [eid]: v });
  }

  const onChangeHost = (eid, v) => {
    setHost(v);
  }

  const TABS = [
    { id: PTYPE.nurihome, title: PTYPE.nurihome.toUpperCase() },
    { id: PTYPE.nurishop, title: PTYPE.nurishop.toUpperCase() },
  ]

  return <StyledComp className={'popup-schema'} >
    <div className={'pop-bg'} />

    <div className={'pop-box'}>
      <div className={'head'}>
        <p className={'tit'}>{ST.MGMT.SCHEMA}</p>
      </div>
      <div className={'body'}>
        <Formgroup inline={true} >
          <Editbox ref={(ref) => { refs.database = ref }} className={"database"} value={data.database || ''} validate={true}
            label="database(schema)" type="text" focus={true} onChange={(v, e) => onChange(v, 'database')} />
          <Editbox ref={(ref) => { refs.user = ref }} className={"user"} value={data.user || ''} validate={true}
            label="user" type="text" onChange={(v, e) => onChange(v, 'user')} />
          <div className={'p-li'}>
            <Editbox ref={(ref) => { refs.password = ref }} className={"password"} value={data.pass || ''} validate={true}
              label="password" type={"text"} onChange={(v, e) => onChange(v, 'pass')} />
          </div>
        </Formgroup>
        <Formgroup inline={true} >
          <Combobox className={'combo dark sm host full'} select={host} label={'host'}
            list={[{ id: '', name: ST.SELECT }, ...HOSTLIST]} onChange={(eid, e, v) => onChangeHost('host', v && v.id)} />
          <Editbox ref={(ref) => { refs.pass = ref }} value={''} validate={true}
            label="pass" type="password" helper={ST.MGMT.CREATE_DB_PASS} />
        </Formgroup>
        <TabButton className={"tab-grp full"} size={'md'} onClick={(eid) => setStype(eid)} list={TABS}
          select={stype} color={'gd-gray'} />

        <p className={'p-noti'}>{noti}</p>
        {message && <p className={'p-msg'}>{message}</p>}

        <div className={'stat-box'}>
          {state && Object.keys(state).map(key => {
            const value = state[key];
            return <span key={key} className={cx('stat', key, value ? 'success' : 'fail')}>{key && key.toUpperCase()}</span>
          })}
        </div>
      </div>
      <div className={'foot'}>
        <Button className={'gray cancel'} title={ST.CANCEL} onClick={props.onClose} />
        <Button className={'red new'} title={ST.NEW} onClick={onCreate} />
        {state && <Button className={'gd-gray close'} title={ST.CLOSE} onClick={props.onClose} />}
      </div>
    </div>
  </StyledComp >
}


const PopupTable = (props) => {
  const { item = null } = props;

  return <StyledComp className={'popup-table'} >
    <div className={'pop-bg'} onClick={props.onClose} />

    <div className={'pop-box'}>
      <div className={'head'}>
        <p className={'tit'}>{ST.MGMT.INITS}</p>
      </div>
      <div className={'body'}>
        <ul className={'t-ul'}>
          {item && Object.keys(item).map(key => {
            let value = item[key];

            switch (key) {
              case 'ctime': case 'utime': case 'stime': case 'etime': case 'ltime': value = moment(item[key]).format("YYYY.MM.DD HH:mm:ss"); break;
              default: value = item[key];
            }

            return <ViewLine key={key} skey={key} svalue={value} />
            // <li className={cx('t-li', key)} key={key}>
            //   <span className={'label'}>{key && `${key.toUpperCase()}:`}</span>
            //   <span className={'text'}>{makeValue(key, value)}</span>
            // </li>
          })}
        </ul>
      </div>
      <div className={'foot'}>
        <Button className={'gd-gray close'} title={ST.CLOSE} onClick={props.onClose} />
      </div>
    </div>
  </StyledComp >
}

const ViewLine = (props) => {
  const { skey, svalue } = props;
  
  const makeValue = (k, v) => {
    let temp = v;
    let style = 'md';
    switch (k.toLowerCase()) {
      case 'pwd':
      case 'pass':
      case 'password':
        temp = '***********';
        break;
      case 'sval':
      case 'opts':
        style = 'sm';
        if (Util.isJson(temp)) {
          const json = temp ? JSON.parse(temp) : '';
          if (json) {
            temp = '';
            Object.keys(json).forEach(key => {
              let text = json[key];
              text = (typeof text !== "string") ? JSON.stringify(text) : text;
              temp += `${key} : ${text}\n`;
            });
          }
        }
        break;
      case 'jsons':
        style = 'sm';
        if (Util.isJson(temp)) {
          const json = temp ? JSON.parse(temp) : '';
          if (json) {
            temp = '';
            json.forEach(a => temp += `${JSON.stringify(a)}\n`);
          }
        }
        break;
      default: break;
    }
    return { value: temp, type: style };
  }

  const onClick = (url) => {
    url && window.open(url);
  }

  const { type, value } = makeValue(skey, svalue);
  const link = (value && String(value).indexOf('http') === 0);

  return <li className={cx('t-li', skey)}>
    <span className={'label'}>{skey && `${skey.toUpperCase()}:`}</span>
    <span className={cx('text', { link }, type)} onClick={link ? () => onClick(value) : null}>{value}</span>
  </li>
}
import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Button, Util, Formgroup, Editbox, Combobox } from 'object/src';
import { URL, CODE, PTYPE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import moment from 'moment';
import { PopupSchema, HOSTLIST } from './Schema';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const STW = ST.WEBMAN;

const StyledComp = styled.div`{
  &.popup-create {${cs.disp.popup(1999999, cs.color.trans, 800, 600)}
    .pop-box {${cs.bg.lightblack} ${cs.border.shadow()} ${cs.p.b50}
      .head {${cs.bg.trans} ${cs.h.get(50)}
        .tit { ${cs.disp.inblock} ${cs.font.t0} ${cs.p.l10} ${cs.font.line(50)} ${cs.h.full} }
        .sid { ${cs.disp.inblock} ${cs.font.gray} ${cs.font.lg} ${cs.m.l5} }
      }

      .body { ${cs.h.calc('100% - 100px')} ${cs.font.sm} ${cs.p.a20} ${cs.min.h(300)}
        ${cs.over.yauto} ${cs.scrollbar.t1} 
        .b-top {
          ${cs.p.b10} ${cs.m.b10} 
          .b-tool { ${cs.pos.relative} ${cs.border.bottom} ${cs.p.a5}
            .tit { ${cs.m.t10} ${cs.font.lg} ${cs.font.bold} }
            .combo { ${cs.w.full} .cb-label { ${cs.font.gray} } }

            .create { ${cs.m.r10} }

            &.b-schem {
              .schem-create { ${cs.m.l5} ${cs.font.sm} ${cs.font.redhover} ${cs.mouse.pointer}
                ${cs.p.h10} ${cs.p.v2} ${cs.border.radius(5)} ${cs.bg.gray}
                &:hover { ${cs.font.underline} }
              }
            }
          }
          .b-label { ${cs.font.line(28)} ${cs.p.h5} ${cs.font.md} ${cs.font.bold} }
          .btn { ${cs.align.rbottom} ${cs.bottom(7)} }
        }
        .b-bottom {
          .b-noti { ${cs.anim.showout('5s', 1, 0.3)} }
        }

        .b-noti { ${cs.font.sm} ${cs.font.prewrap} ${cs.font.yellow} ${cs.p.a5} }
      }

      .foot {${cs.h.get(50)} ${cs.font.right} ${cs.p.h20}
        & > .button {${cs.m.l20} }
        .cancel {${cs.align.left} }
      }
    }
  }

  &.result-box { ${cs.font.md}
    .txt { ${cs.p.h5} ${cs.font.lightgray} }
    
    .state { ${cs.m.l20} ${cs.font.dark} ${cs.font.sm} ${cs.border.radius(5)} ${cs.bg.gray} 
      ${cs.min.w(80)} ${cs.p.h10} ${cs.p.v2} ${cs.font.center} ${cs.disp.inblock}
      &.success { ${cs.bg.green} ${cs.font.white} }
      &.fail { ${cs.bg.red} ${cs.font.white} }
      &.ready { ${cs.bg.gray} ${cs.font.dark} }
    }
    
    .msg { ${cs.p.a5} ${cs.font.sm} ${cs.font.gray} 
      & > div { ${cs.m.a3} ${cs.min.h(20)} }
      .t-label { ${cs.disp.inblock} ${cs.w.get(100)} ${cs.float.left} ${cs.font.right} ${cs.font.lightgray} }
      .t-value { ${cs.disp.inblock} ${cs.w.calc('100% - 120px')} ${cs.font.prewrap} ${cs.p.l10}
        .t-stat {
          ${cs.font.sm} ${cs.min.w(60)} ${cs.p.h5} ${cs.font.center} ${cs.border.radius(3)} ${cs.bg.gray}
          ${cs.m.h5} ${cs.font.dark}
          &.ok { ${cs.bg.green} ${cs.font.white} }
          &.fail { ${cs.bg.red} ${cs.font.white} }
          &.none { ${cs.bg.gray} ${cs.font.dark} }
        }
      }
      .err { ${cs.font.red} }
    }
  }

  &.popup-editor {${cs.disp.popup(1999999, cs.color.trans, 300, 400)}
    .pop-box {${cs.bg.lightblack} ${cs.border.shadow()}
      .head {${cs.bg.trans} ${cs.h.get(50)}
        .tit { ${cs.disp.inblock} ${cs.font.t0} ${cs.p.l10} ${cs.font.line(50)} ${cs.h.full} }
        .sid { ${cs.disp.inblock} ${cs.font.gray} ${cs.font.lg} ${cs.m.l5} }
      }

      .body { ${cs.h.calc('100% - 100px')} ${cs.font.sm} ${cs.p.a20} ${cs.min.h(300)}
        ${cs.over.yauto} ${cs.scrollbar.t1} 
        .edit-box { ${cs.m.t20} .ed-label { ${cs.font.orange} } }
      }

      .foot {${cs.h.get(50)} ${cs.font.right} ${cs.p.h20}
        & > .button {${cs.m.l20} }
        .cancel {${cs.align.left} }
      }
    }
  }

  &.popup-toolbar {${cs.disp.popup(1999999, cs.color.trans, 800, 600)}
    .pop-box {${cs.bg.lightblack} ${cs.border.shadow()}
      .head {${cs.bg.trans} ${cs.h.get(50)}
        .tit { ${cs.disp.inblock} ${cs.font.t0} ${cs.p.l10} ${cs.font.line(50)} ${cs.h.full} }
        .sid { ${cs.disp.inblock} ${cs.font.gray} ${cs.font.lg} ${cs.m.l5} }
      }

      .body { ${cs.h.calc('100% - 100px')} ${cs.font.sm} ${cs.p.a20} ${cs.min.h(300)} ${cs.p.h40} 
        ${cs.over.yauto} ${cs.scrollbar.t1}
        .pv-line { ${cs.disp.block} ${cs.p.get('8px 5px')}
          .pv-label { ${cs.disp.inblock} ${cs.w.get(100)} ${cs.font.right} ${cs.font.darkgray} ${cs.float.left} ${cs.font.line(20)} }
          .pv-value { ${cs.disp.inblock} ${cs.w.calc('100% - 120px')} ${cs.font.left} ${cs.p.l10} ${cs.font.line(20)}
            &.nodata { ${cs.font.gray} }
            &.click { ${cs.mouse.pointer} ${cs.box.line} ${cs.box.gray} ${cs.p.h10} ${cs.p.v2} ${cs.border.radius(5)}
              ${cs.w.full}
              &:hover { ${cs.font.underline} ${cs.font.orange} }
            }

          }
          
          .form-grp { ${cs.m.t5} ${cs.over.inherit}
            .fg-row .fg-col { ${cs.pos.relative} ${cs.m.a0} 
              .button { ${cs.min.w(100)} ${cs.float.right} ${cs.p.t5} }
              .edit-box { ${cs.m.r10} }
            }
          }
          // &.inline { ${cs.disp.inblock} }
        }
        .pv-div { ${cs.h.get(1)} ${cs.bg.gray} ${cs.w.full} ${cs.m.v10} }
      }

      .foot {${cs.h.get(50)} ${cs.font.right} ${cs.p.h20}
        & > .button {${cs.m.l20} }
        .cancel {${cs.align.left} }
      }
    }
  }

  &.popup-schema {${cs.disp.popup(1999999, cs.color.trans, 700, 500)}
    .pop-box {${cs.bg.lightblack} ${cs.border.shadow()}
      .head {${cs.bg.trans} ${cs.h.get(50)}
        .tit { ${cs.disp.inblock} ${cs.font.t0} ${cs.p.l10} ${cs.font.line(50)} ${cs.h.full} }
        .sid { ${cs.disp.inblock} ${cs.font.gray} ${cs.font.lg} ${cs.m.l5} }
      }

      .body { ${cs.h.calc('100% - 100px')} ${cs.font.sm} ${cs.p.a20} ${cs.min.h(300)} ${cs.p.h40} 
        ${cs.over.yauto} ${cs.scrollbar.t1}
        .pv-line { ${cs.disp.block} ${cs.p.get('8px 5px')}
          .pv-label { ${cs.disp.inblock} ${cs.w.get(100)} ${cs.font.right} ${cs.font.darkgray} ${cs.float.left} ${cs.font.line(20)} }
          .pv-value { ${cs.disp.inblock} ${cs.w.calc('100% - 120px')} ${cs.font.left} ${cs.p.l10} ${cs.font.line(20)}
            &.nodata { ${cs.font.gray} }
            &.click { ${cs.mouse.pointer} ${cs.box.line} ${cs.box.gray} ${cs.p.h10} ${cs.p.v2} ${cs.border.radius(5)}
              ${cs.w.full}
              &:hover { ${cs.font.underline} ${cs.font.orange} }
            }
          }
          
          .form-grp { ${cs.m.t5} ${cs.over.inherit}
            .fg-row .fg-col { ${cs.pos.relative} ${cs.m.a0} 
              .button { ${cs.min.w(100)} ${cs.float.right} ${cs.p.t5} }
              .edit-box { ${cs.m.r10} }
              .button { ${cs.m.l10} }
              .create { ${cs.float.left} ${cs.m.l0} }
              // .pass { ${cs.m.r0} }
            }
          }

          .btn-grp { ${cs.m.t20} }
          .combo-box { ${cs.float.none} }
        }
        .pv-div { ${cs.h.get(1)} ${cs.bg.gray} ${cs.w.full} ${cs.m.v10} }
      }

      .foot {${cs.h.get(50)} ${cs.font.right} ${cs.p.h20}
        & > .button {${cs.m.l20} }
        .cancel {${cs.align.left} }
      }
    }
  }

  &.popup-backup {${cs.disp.popup(1999999, cs.color.trans, 400, 500)}
    .pop-box {${cs.bg.lightblack} ${cs.border.shadow()} ${cs.noselect}
      .head {${cs.bg.trans} ${cs.h.get(50)}
        .tit { ${cs.disp.inblock} ${cs.font.t0} ${cs.p.l10} ${cs.font.line(50)} ${cs.h.full} }
        .sid { ${cs.disp.inblock} ${cs.font.gray} ${cs.font.lg} ${cs.m.l5} }
      }

      .body {${cs.h.calc('100% - 100px')} ${cs.font.sm} ${cs.p.a20} ${cs.min.h(300)}
        ${cs.over.yauto} ${cs.scrollbar.t1}
        .pb-value { ${cs.disp.block} ${cs.w.full} ${cs.font.left} ${cs.p.l10} 
          ${cs.font.prewrap} ${cs.font.line(24)} ${cs.font.md} ${cs.font.lightgray}

          &.click { ${cs.mouse.pointer} &:hover { ${cs.font.underline} ${cs.font.orange} } }
        }

        .pb-nofile { ${cs.font.center} ${cs.font.gray} ${cs.align.center} ${cs.font.lg} ${cs.w.full} ${cs.disp.block} }

        .t-tit { ${cs.font.line(20)} ${cs.font.center} ${cs.bg.alphablack} ${cs.w.full} ${cs.m.t10}
          .t-txt { ${cs.w.full} ${cs.disp.block} }
        }
        .pb-div { ${cs.h.get(1)} ${cs.bg.gray} ${cs.w.full} ${cs.m.v10} }
      }

      .foot {${cs.h.get(50)} ${cs.font.right} ${cs.p.h20}
        & > .button {${cs.m.l20} }
        .cancel {${cs.align.left} }
      }
    }
  }

  &.popup-upgrade {${cs.disp.popup(1999999, cs.color.trans, 700, 600)}
    .pop-box {${cs.bg.lightblack} ${cs.border.shadow()}
      .head {${cs.bg.trans} ${cs.h.get(50)}
        .tit { ${cs.disp.inblock} ${cs.font.t0} ${cs.p.l10} ${cs.font.line(50)} ${cs.h.full} }
        .sid { ${cs.disp.inblock} ${cs.font.gray} ${cs.font.lg} ${cs.m.l5} }
      }

      .body { ${cs.h.calc('100% - 100px')} ${cs.font.sm} ${cs.p.a20} ${cs.min.h(300)} ${cs.p.h40}
        ${cs.over.yauto} ${cs.scrollbar.t1} 
        .pu-line { ${cs.disp.block} ${cs.p.get('8px 5px')}
          .pu-label { ${cs.disp.inblock} ${cs.w.get(100)} ${cs.font.right} ${cs.font.darkgray} ${cs.float.left} }
          .pu-value { ${cs.disp.inblock} ${cs.w.calc('100% - 120px')} ${cs.font.left} ${cs.p.l10}
            &.click { ${cs.mouse.pointer} &:hover { ${cs.font.underline} ${cs.font.orange} } }
          }
          .form-grp { ${cs.over.inherit} }
        }
        .pu-div { ${cs.h.get(1)} ${cs.bg.gray} ${cs.w.full} ${cs.m.v10} }
      }

      .foot {${cs.h.get(50)} ${cs.font.right} ${cs.p.h20}
        & > .button {${cs.m.l20} }
        .cancel {${cs.align.left} }
      }
    }
  }

  
  &.popup-setter {${cs.disp.popup(1999999, cs.color.trans, 300, 340)}
    .pop-box {${cs.bg.lightblack} ${cs.border.shadow()}
      .head {${cs.bg.trans} ${cs.h.get(50)}
        .tit { ${cs.disp.inblock} ${cs.font.t0} ${cs.p.l10} ${cs.font.line(50)} ${cs.h.full} }
        .sid { ${cs.disp.inblock} ${cs.font.gray} ${cs.font.lg} ${cs.m.l5} }
      }

      .body { ${cs.h.calc('100% - 100px')} ${cs.font.sm} ${cs.p.a20} ${cs.min.h(200)}
        .h-link { ${cs.h.get(30)} ${cs.w.full} ${cs.box.line} ${cs.border.radius(3)} 
          ${cs.pos.relative} ${cs.font.line(30)} ${cs.disp.block} ${cs.m.t10} ${cs.font.center}
        }
        .b-copy { ${cs.disp.block} ${cs.w.full} ${cs.h.get(30)} ${cs.m.t20} ${cs.border.top} }
      }

      .foot {${cs.h.get(50)} ${cs.font.right} ${cs.p.h20}
        & > .button {${cs.m.l20} }
        .cancel {${cs.align.left} }
      }
    }
  }

  @media screen and (max-width : 600px) {
  }
}`;

const API = URL.API.WEBMAN;
// const VERSION = [{ id: '', name: ST.SELECT }, { id: 'v0.0.1', name: 'v0.0.1' }, { id: 'v0.0.2', name: 'v0.0.2' }];
const APPTYPE = [{ id: '', name: ST.SELECT }, { id: 'nurihome', name: 'nurihome' }, { id: 'nurishop', name: 'nurishop' }];
const VERSION = (list, repo) => {
  let array = [{ id: '', name: ST.SELECT }];
  if (!list) return array;
  else {
    let temps = list.filter(a => a.repo === repo);
    array = temps ? [...array, ...temps.map(a => { return { id: a.name, name: a.name } })] : array;
    return array;
  }
}

var timer = null;

export const PopupCreate = (props) => {
  // const INST = { sid: '', version: '', webapp: '', key: '' };
  const INST = { sid: '', version: 'v0.0.1', webapp: 'nurihome', key: '', cname: '' };
  const SCHEMA = { user: '', pass: '', table: '' };
  const WEBSET = { table: '' };

  const { data } = props;
  // const [running, setRunning] = useState(false);
  const [step, setStep] = useState('ready');
  const [noti, setNoti] = useState(null);
  const [cmdid, setCmdid] = useState(null);
  const [isschema, showSchema] = useState(false);
  const [guide, setGuide] = useState({});
  const [apptype, setApptype] = useState(null);
  const [schema, setSchema] = useState({ ...SCHEMA });
  const [webset, setWebset] = useState({ ...WEBSET });
  const [inst, setInst] = useState({ ...INST, sid: data ? data.sid : '' });

  useEffect(() => {
    setInst({ ...INST, sid: data ? data.sid : '' });
    setSchema({ ...SCHEMA });

    if (props.data && props.data.app) {
      const { domain } = props.data.app;

      if (domain.indexOf(PTYPE.nurihome) === 0 || domain.indexOf(PTYPE.nuriohome) === 0) onChangeApptype('', PTYPE.nurihome);
      else onChangeApptype('', PTYPE.nurishop);
    }

    return () => {
      timer && clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const onClickInst = () => {
    // 성공하면 인스톨도 진행
    setStep("installing");
    setCmdid(null);
    // const { sid, version, key } = this.state.result;
    actions.doRun(API.INSTALL, { ...inst, webapp: apptype }).then((res) => {
      const { code, result, err } = res;

      if (code === CODE.SUCCESS) {
        // setStep("install");
        setCmdid(result.cmdid);
      } else {  // fail
        if (result && result.cmdid) setCmdid(result.cmdid);
        if (err) setNoti(err.status);
      }
    });

    actions.doSelect('https://registry.hub.docker.com/v1/repositories/sentron/nurihome/tags').then(res => {
      console.dir(res);
    })
  }


  const onChangeApptype = (label, value) => {
    setApptype(value);
    setNoti(null);
    setStep("apptype");

    let odr = '01';
    if (props.param) {
      let arr = props.param.filter(a => a.type === 'container');
      arr = arr && arr.filter(a => a.repo.indexOf(`sentron/nurihome`) === 0 || a.repo.indexOf(`sentron/nurishop`) === 0);
      arr = arr && arr.filter(a => !(a.size.indexOf(PTYPE.nurihome) === 0 || a.size.indexOf(PTYPE.nurishop) === 0));
      arr = arr && arr.sort((a, b) => {
        return (a.size > b.size) ? -1 : 1;
      });
      if (arr && arr.length > 0) {
        const item = arr[0];
        odr = item && item.size.substr(6, 2);
        odr = (Number(odr) + 1);
        (odr < 10) && (odr = `0${odr}`);
      }
    }

    const { sid } = props.data;
    const prefix = sid.substr(sid.length - 5, 5);
    if (value === 'nurihome') {
      setGuide({ table: `h_${prefix}${odr}`, container: `h${prefix}${odr}`, port: `210${odr}` })
    } else {
      setGuide({ table: `s_${prefix}${odr}`, container: `s${prefix}${odr}`, port: `310${odr}` })
    }
  }

  const onChangeInst = (label, value) => {
    const temp = { ...inst, [label]: value };
    setInst(temp);
    setNoti(null);
    setStep("connect");
  }

  const onChangeSchema = (label, value) => {
    const temp = { ...schema, [label]: value };
    setSchema(temp);
    setNoti(null);
    setStep("apptype");
  }

  const onClickSchema = () => {
    showSchema(true);
  }

  const onClickConnect = () => {
    setStep("connecting");
    actions.doSelect(URL.API.MGMT_SCHEMA, { ...schema }).then((res) => {
      const { code, err } = res;
      if (code === CODE.SUCCESS) {
        setStep("connect");
        setNoti(ST.WEBMAN.OK_CONNECT);
      } else {
        setNoti(err ? `${ST.WEBMAN.NO_CONNECT}\n${err.msg}` : ST.WEBMAN.NO_CONNECT);
      }
    });
  }

  const onChangeWebset = (label, value) => {
    const temp = { ...webset, [label]: value };
    setWebset(temp);
    setNoti(null);
  }

  const onClickWebset = () => {
    setStep("websetting");
    setCmdid(null);
    const value = { ...schema, ...inst, ...webset, webapp: apptype, host: schema.host };
    console.dir(value);

    actions.doRun(API.INITIAL, value).then((res) => {
      const { code, result, err } = res;

      if (code === CODE.SUCCESS) {
        // setStep("install");
        setCmdid(result.cmdid);
      } else {  // fail
        if (result && result.cmdid) setCmdid(result.cmdid);
        if (err) setNoti(err.status);
      }
    });
  }

  const onResult = (event) => {
    if (event === 'success') {
      setStep("install");
    } else if (event === 'fail') {
      setStep("fail");
    } else {
      setStep("installing");
    }
  }

  return <Fragment>
    <StyledComp className={'popup-create'} >
      <div className={'pop-bg'} />

      <div className={'pop-box'}>
        <div className={'head'}>
          <span className={'tit'}>{`${ST.MAIN.CREAPP}`}</span>
          {inst && <span className={'sid'}>{`(${inst.sid})`}</span>}
        </div>
        <div className={'body'}>
          <div className={'b-noti'} ><p>{STW.NAMAING}</p></div>
          <div className={'b-top'}>
            <Formgroup className={'inherit'} inline={true}>
              <div className={'b-label'}>{ST.WEBMAN.APPTYPE}</div>
              <Combobox className={'combo dark sm app full'} select={apptype}
                list={APPTYPE} onChange={(eid, e, v) => onChangeApptype('apptype', v && v.id)} />
            </Formgroup>

            <div className={'b-tool b-schem'}>
              <div className={'tit'}>{ST.WEBMAN.SCHEMA}<span className={'schem-create'} onClick={onClickSchema}>{`${ST.NEW}`}</span></div>
              <Formgroup className={'inherit'} inline={true}>
                <Editbox className={'edit table'} label={"SCHEMA"} value={schema && schema.table}
                  placeholder={guide.table} onChange={(v) => onChangeSchema('table', v)} />
                <Editbox className={'edit user'} label={"USER"} value={schema && schema.user}
                  placeholder={guide.table} onChange={(v) => onChangeSchema('user', v)} />
                <Editbox className={'edit pass'} label={"PASSWORD"} value={schema && schema.pass}
                  placeholder={guide.table} onChange={(v) => onChangeSchema('pass', v)} />
                <Combobox className={'combo dark sm host full'} select={schema && schema.host} label={'host'}
                  list={[{ id: '', name: ST.SELECT }, ...HOSTLIST]} onChange={(eid, e, v) => onChangeSchema('host', v && v.id)} />

                <span className={'btn'}>
                  {/* <Button className={'red sm create'} title={'CREATE'} onClick={onClickSchema} disabled={step !== 'apptype'} /> */}
                  <Button className={'green sm connect'} title={'CONNECTION'} onClick={onClickConnect} disabled={(step !== 'apptype')} />
                </span>
              </Formgroup>
            </div>
            <div className={'b-tool b-inst'}>
              <div className={'tit'}>{ST.WEBMAN.INSTALL}</div>
              <Formgroup className={'inherit'} inline={true}>
                <Editbox className={'edit cname'} label={"CONTAINER NAME"} value={inst && inst.cname}
                  placeholder={guide.container} onChange={(v) => onChangeInst('cname', v)} />
                <Editbox className={'edit cname'} label={"CONTAINER NAME"} value={inst && inst.port}
                  placeholder={guide.port} onChange={(v) => onChangeInst('port', v)} />
                <Editbox className={'edit key'} label={"KEY"} value={inst && inst.key}
                  placeholder={props.data && props.data.app && props.data.app.skey} onChange={(v) => onChangeInst('key', v)} />
                <Combobox className={'combo dark sm ver'} label={"VERSION"} select={inst && inst.version}
                  list={apptype ? VERSION(props.dockerlist, `sentron/${apptype}`) : null} onChange={(eid, e, v) => onChangeInst('version', v && v.id)} />
                {/* <Combobox className={'combo dark sm app'} label={"APP TYPE"} select={inst && inst.webapp}
                  list={APPTYPE} onChange={(eid, e, v) => onChangeInst('webapp', v && v.id)} /> */}
                <span className={'btn'}>
                  <Button className={'green sm red'} title={'INSTALL'} onClick={onClickInst} disabled={step !== 'connect'} />
                </span>
              </Formgroup>
            </div>
            <div className={'b-tool b-inst'}>
              <div className={'tit'}>{ST.WEBMAN.SETTING}</div>
              <Formgroup className={'inherit'} inline={true}>
                <Editbox className={'edit table'} label={"TABLE"} value={webset && webset.table}
                  placeholder={guide.table} onChange={(v) => onChangeWebset('table', v)} />

                <span className={'btn'}>
                  <Button className={'green sm red'} title={'INITIALIZE'} onClick={onClickWebset} disabled={step !== 'install'} />
                </span>
              </Formgroup>
            </div>
          </div>
          <div className={'b-bottom'}>
            {cmdid && <MessageBox cmdid={cmdid} doReload={props.doReload} onResult={onResult} onRefresh={props.onRefresh} />}
            {noti && <div className={'b-noti'} onAnimationEnd={() => setNoti(null)}><p>{noti}</p></div>}
          </div>
        </div>
        <div className={'foot'}>
          <Button className={'gd-gray close'} title={ST.CLOSE} onClick={() => props.onClick && props.onClick('close')} />
        </div>
      </div>
    </StyledComp>
    {isschema && <PopupSchema {...schema} table={guide.table} user={guide.table} pass={guide.table} onClose={() => showSchema(false)} />}
  </Fragment>
}

const MessageBox = (props) => {
  const [data, setData] = useState(null);
  // const [proc, setProc] = useState(null);

  useEffect(() => {
    if (!props.cmdid) return;

    doReload(props.cmdid);
    timer = setInterval(() => {
      doReload(props.cmdid);
    }, 1000 * 5); //5초마다 갱신

    return () => {
      clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const doReload = (cmdid) => {
    actions.doSelect(API.CMDSTATUS, { cmdid }).then(({ result }) => {
      const stat = result.cres ? JSON.parse(Util.replaceAll(result.cres, '`', '＇')) : {};
      const temp = {
        ...result, stat,
        utime: moment(result.utime.substr(0, 8) + " " + result.utime.substr(8, 6)).format("YYYY.MM.DD HH:mm:ss"),
      };
      setData(temp);
      props.onResult && props.onResult('running');

      if (temp && temp.state === 'success' && timer) {
        clearInterval(timer);
        timer = null;
        props.onResult && props.onResult(temp.errs ? 'fail' : 'success');
        props.onRefresh && props.onRefresh();
      }
    });
  }

  if (!data) return null;

  return <StyledComp className={'result-box'}>
    <div className={'msg'}>
      <div>
        <span className={'t-label'}>{'RESULT :'}</span>
        <span className={'t-value'}>
          <span className={'txt tit'}>{data.ctag}</span>
          <span className={'txt time'}>{data.utime}</span>
          <span className={cx('txt state', data.state || 'ready')}>{data.state}</span>
        </span>
      </div>
      <div className={'stat'}>
        <span className={'t-label'}>{'PROCESS :'}</span>
        <span className={'t-value'}>
          {/* {`${JSON.stringify(data.stat)}\n`} */}
          {data.stat && Object.keys(data.stat).map(key => {
            let state = data.stat[key];
            let name = key.toUpperCase();
            if (key === 'start' || key === 'end') {
              name = `${key.substr(0, 1).toUpperCase()}-${moment(state).format('HH:mm:ss')}`;
              state = 'ok';
            }
            if (key === 'version') {
              name = state;
              state = 'ok';
            }
            return <span key={key} className={cx('t-stat', state)}>{name}</span>
          })}
          <span></span>
        </span>
      </div>
      <div className={'cmds'}>
        <span className={'t-label'}>{'COMMANDS :'}</span>
        <span className={'t-value'}>{data.cmds}</span>
      </div>
      {data.errs && <div className={'err'}>
        <span className={'t-label'}>{'ERRORS :'}</span>
        <span className={'t-value'}>{data.errs}</span>
      </div>}
    </div>
  </StyledComp >
}

export const PopupEditor = (props) => {
  const CONFIG = { domain: 'localhost', ip: 'localhost', port: '28050', skey: 'abcdefg', sid: 'wm2021001' };
  const { data } = props;
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (!props.data) return;
    const { data } = props;
    setValue({ ...CONFIG, ...data.app });

    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const onChange = (label, v) => {
    const temp = { ...value, [label]: v };
    setValue(temp);
  }

  if (!value) return null;

  return <StyledComp className={'popup-editor'} >
    <div className={'pop-bg'} />

    <div className={'pop-box'}>
      <div className={'head'}>
        <span className={'tit'}>{`${ST.WEBMAN.EDITAPP}`}</span>
        {value && <span className={'sid'}>{`(${data.sid})`}</span>}
      </div>
      <div className={'body'}>
        <div className={'b-tool b-schem'}>
          <Editbox className={'edit sid inline'} label={"SID"} value={value && value.sid} onChange={(v) => onChange('sid', v)} />
          <Editbox className={'edit domain inline'} label={"DOMAIN"} value={value && value.domain} onChange={(v) => onChange('domain', v)} />
          <Editbox className={'edit ip inline'} label={"IP"} value={value && value.ip} onChange={(v) => onChange('ip', v)} />
          <Editbox className={'edit port inline'} label={"PORT"} value={value && value.port} onChange={(v) => onChange('port', v)} />
          <Editbox className={'edit key inline'} label={"KEY"} value={value && value.skey} onChange={(v) => onChange('skey', v)} />

        </div>
      </div>
      <div className={'foot'}>
        {/* <Button className={'red save left'} title={ST.SAVE} onClick={onClick} disabled={!enable} /> */}
        <Button className={'gd-gray close'} title={ST.CLOSE} onClick={() => props.onClick && props.onClick('close')} />
      </div>
    </div>
  </StyledComp>
}

let fpath = '';
export const PopupToolbar = (props) => {
  const { data, param } = props;
  const [cmdid, setCmdid] = useState(null);
  const [noti, setNoti] = useState(null);
  const [path, setPath] = useState(param.backup || '');
  const [dumpfile, setDumpfile] = useState(param.dumpfile || '');
  // const [cloneresult, setCloneResult] = useState(null);
  const [value, setValue] = useState(null);
  const [dbclear, setDbclear] = useState(null);

  const url = path ? `http://${data.app.ip}:${data.app.port}${path}` : '';
  const skey = param.size && param.size.replace('h21001', 'h_21001').replace('s21001', 's_21001');
  const isclear = skey.indexOf('h_21001') === 0 || skey.indexOf('s_21001') === 0;
  const dumpurl = dumpfile ? `/backup/${dumpfile}` : '';

  useEffect(() => {
    if (!props.data) return;
    setPath(param.backup || '');
    if (props.param) {
      const { opts } = props.param;
      if (opts) {
        const { domain } = JSON.parse(opts);
        if (domain) {
          setValue({ ...value, host: domain });
        }
      }

      props.param.size && doSelectDumps();
    }

    // actions.doSelect('https://registry.hub.docker.com/v1/repositories/sentron/nurihome/tags').then(res => {
    //   console.dir(res);
    // })

    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const doSelectDumps = () => {
    actions.doSelect(URL.API.WEBMAN.DBDUMP, { dockid: param.size }).then((res) => {
      const { result } = res;
      if (result && result.length > 0) {
        setDumpfile(result[0].path);
      }
    });
  }

  const onClick = (eid) => {
    const { app } = data;

    let api = '';
    let val = '';

    if (eid === 'backup') {
      api = API.BACKUP;
      val = { ...app, key: app.skey, cname: param.size, dockid: param.rowid };
    } else if (eid === 'dbdump') {
      api = API.DBDUMP;
      let database = param.size && param.size.charAt(0) + "_" + param.size.substr(1);
      val = { ...app, key: app.skey, cname: param.size, dockid: param.rowid, database };
    } else if (eid === 'dbclone') {
      api = API.DBCLONE;
      let database = param.size && param.size.charAt(0) + "_" + param.size.substr(1);
      // let database = value.targetdb && value.targetdb.charAt(0) + "_" + value.targetdb.substr(1);
      const type = param.size.charAt(0);
      if (database.charAt(0) !== type || value.sourcefile.charAt(0) !== type) {
        global.showAlert({ type: 'warn', msg: STW.APPTYPE_ERR });
        return;
      }
      val = { ...app, key: app.skey, cname: param.size, dockid: param.rowid, database, dumpfile: value.sourcefile }; //'h_2100102_20210406.sql.gz' };
    } else if (eid === 'host') {
      api = API.DOMAIN;
      val = { ...app, key: app.skey, cname: param.size, dockid: param.rowid, ...value };
    } else if (eid === 'restart') {
      api = API.RESTART;
      val = { ...app, key: app.skey, cname: param.size, dockid: param.rowid };
    } else if (eid === 'upgrade') {
      api = API.DOCK_UPGRADE;
      const webapp = param.repo.replace('sentron/', '');
      if (!(webapp === 'nurihome' || webapp === 'nurishop')) {
        return global.showAlert({ msg: STW.TYPE_ERROR, type: 'warn' });
      }

      val = { ...app, key: app.skey, cname: param.size, dockid: param.rowid, ...value, webapp: webapp, list: [param.size] };
    } else if (eid === 'dbclear') {
      if (dbclear !== skey) return global.showAlert({ msg: STW.DB_INITIAL_KEY });

      api = API.DBCLEAR;
      val = { ...app, key: app.skey, cname: param.size, dockid: param.rowid, database: skey };
    } else {
      return;
    }

    setCmdid(null);

    actions.doRun(api, val).then((res) => {
      const { code, result, err } = res;
      if (code === CODE.SUCCESS) {
        if (api === API.BACKUP) {
          setPath('');
          fpath = result.status && (result.status.path || '');
        } else if (api === API.DBDUMP) {
          setDumpfile(result.path);
        } else if (api === API.DBCLONE) {
          // setCloneResult(result);
          console.dir(result);
        }
        result.cmdid && setCmdid(result.cmdid);
      } else {  // fail
        if (result && result.cmdid) setCmdid(result.cmdid);
        if (err) setNoti(err.status);
      }
    });
  }

  const onChange = (label, v) => {
    const temp = { ...value, [label]: v };
    setValue(temp);
  }

  const onResult = (event) => {
    // setStep(event); //success, fail, ...
    if (event === 'success') setPath(fpath);
  }

  const placeholder = param.size.charAt(0) === 's' ? 's_nurishop.sql.gz' : 'h_nurihome.sql.gz';
  // const VERSION = props.dockerlist && props.dockerlist.filter(a => a.repo === param.repo).map(a => { return { id: a.name, name: a.name } })

  return <StyledComp className={'popup-toolbar'} >
    <div className={'pop-bg'} />

    <div className={'pop-box'}>
      <div className={'head'}>
        <span className={'tit'}>{`${ST.WEBMAN.SETAPP}`}</span>
        <span className={'sid'}>{`(${data.sid})`}</span>
      </div>
      <div className={'body'}>
        <div className={'pv-line'}>
          <span className={'pv-label'}>{'SID :'}</span>
          <span className={'pv-value'}>{`${data.sid || ''}`}</span>
        </div>
        <div className={'pv-line'}>
          <span className={'pv-label'}>{'CONTAINER :'}</span>
          <span className={'pv-value'}>{`${param.type + '-' + param.size || ''}`}</span>
        </div>

        <div className={'pv-div'} />

        <div className={'pv-line'}>
          <Formgroup inline>
            <span className={''}>{STW.RESTART_CONTAINER}</span>
            {/* <Editbox className={'edit sid'} value={value && value.host} onChange={(v) => onChange('host', v)} /> */}
            <Button className={'green sm button'} title={'Restart'} onClick={() => onClick('restart')} />
          </Formgroup>
        </div>

        <div className={'pv-line'}>
          <span className={'pv-title'}>{STW.BACKUP_MEDIA}</span>
          <Formgroup inline>
            {path ?
              <a className={'pv-value click'} href={url} download>{`${path}`}</a> :
              <span className={'pv-value nodata'}>{`${STW.NO_BACKUP}`}</span>
            }

            <Button className={'green sm button'} title={'Backup File'} onClick={() => onClick('backup')} />
          </Formgroup>
        </div>

        <div className={'pv-line'}>
          <span className={'pv-title'}>{STW.DATA_DUMP}</span>
          <Formgroup inline>
            {dumpfile ?
              <a className={'pv-value click'} href={dumpurl} download>{`${dumpfile}`}</a> :
              <span className={'pv-value nodata'}>{`${STW.NO_DUMPFILE}`}</span>
            }

            <Button className={'green sm button'} title={'Dump File'} onClick={() => onClick('dbdump')} />
          </Formgroup>
        </div>

        {isclear && <div className={'pv-line'}>
          <span className={'pv-title'}>{STW.DATA_CLONE}</span>
          <Formgroup inline>
            <Editbox className={'edit targetdb'} value={param.size} placeholder={param.size} onChange={(v) => onChange('targetdb', v)} readonly />
            <Editbox className={'edit sourcefile'} value={value && value.sourcefile} placeholder={placeholder} onChange={(v) => onChange('sourcefile', v)} />
            <Button className={'green sm button'} title={'Clone Data'} onClick={() => onClick('dbclone')} disabled={!(value && value.sourcefile)} />
          </Formgroup>
        </div>}

        <div className={'pv-line'}>
          <span className={'pv-title'}>{STW.UPDATE_DOMAIN}</span>
          <Formgroup inline>
            <Editbox className={'edit sid'} value={value && value.host} onChange={(v) => onChange('host', v)} />
            <Button className={'green sm button'} title={'Domain'} onClick={() => onClick('host')} disabled={!(value && value.host)} />
          </Formgroup>
        </div>

        <div className={'pv-line'}>
          <span className={'pv-title'}>{STW.DB_INITIAL}</span>
          <Formgroup inline>
            <Editbox className={'edit sid'} value={dbclear} placeholder={skey} onChange={(v) => setDbclear(v)} />
            <Button className={'green sm button'} title={'Clear Database'} onClick={() => onClick('dbclear')} disabled={dbclear !== skey} />
          </Formgroup>
        </div>

        <div className={'pv-line'}>
          <span className={'pv-title'}>{STW.DOCK_UPGRADE}</span>
          <Formgroup inline={true}>
            <Combobox className={'combo dark sm ver inline full'} label={"VERSION"} select={(value && value.version) || ''}
              list={VERSION(props.dockerlist, props.param.repo)} onChange={(eid, e, v) => onChange('version', v && v.id)} />
            <Button className={'green sm button'} title={'Ugrade'} onClick={() => onClick('upgrade')} disabled={!(value && value.version)} />
          </Formgroup>
        </div>

        <div className={'pv-div'} />
        <div className={'b-bottom'}>
          {cmdid && <MessageBox cmdid={cmdid} doReload={props.doReload} onResult={onResult} onRefresh={props.onRefresh} />}
          {noti && <div className={'b-noti'} onAnimationEnd={() => setNoti(null)}><p>{noti}</p></div>}
        </div>
      </div>
      <div className={'foot'}>
        <Button className={'gd-gray close'} title={ST.CLOSE} onClick={() => props.onClick && props.onClick('close')} />
      </div>
    </div>
  </StyledComp>
}

export const PopupChangeSchema = (props) => {
  const { data, param } = props;
  const [cmdid, setCmdid] = useState(null);
  const [noti, setNoti] = useState(null);
  const [value, setValue] = useState(null);
  const [isschema, showSchema] = useState(false);
  const [isconnect, setConnect] = useState(false);

  // const skey = param.size && param.size.replace('h21001', 'h_21001').replace('s21001', 's_21001');

  useEffect(() => {
    if (!props.data) return;
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const onClickConnect = () => {
    setCmdid(null);

    actions.doSelect(URL.API.MGMT_SCHEMA, { ...value }).then((res) => {
      const { code, err } = res;
      if (code === CODE.SUCCESS) {
        setNoti(ST.WEBMAN.OK_CONNECT);
        setConnect(true);
      } else {
        setNoti(err ? `${ST.WEBMAN.NO_CONNECT}\n${err.msg}` : ST.WEBMAN.NO_CONNECT);
        setConnect(false);
      }
    });
  }

  const onClickSave = () => {
    const { app } = data;
    const api = API.INITIAL;
    const val = { ...app, key: app.skey, cname: param.size, dockid: param.rowid, ...value };

    setNoti(null);
    setCmdid(null);
    actions.doRun(api, val).then((res) => {
      const { code, result, err } = res;
      if (code === CODE.SUCCESS) {
        result.cmdid && setCmdid(result.cmdid);
      } else {  // fail
        if (result && result.cmdid) setCmdid(result.cmdid);
        if (err) setNoti(err.status);
      }
    });
  }

  const onChange = (label, v) => {
    const temp = { ...value, [label]: v };
    setValue(temp);
  }

  const onResult = (event) => {
  }

  return <Fragment>
    <StyledComp className={'popup-schema'} >
      <div className={'pop-bg'} />

      <div className={'pop-box'}>
        <div className={'head'}>
          <span className={'tit'}>{`${STW.SCHEMA_CHANGE}`}</span>
          <span className={'sid'}>{`(${data.sid})`}</span>
        </div>
        <div className={'body'}>
          <div className={'pv-line'}>
            <span className={'pv-label'}>{'SID :'}</span>
            <span className={'pv-value'}>{`${data.sid || ''}`}</span>
          </div>
          <div className={'pv-line'}>
            <span className={'pv-label'}>{'CONTAINER :'}</span>
            <span className={'pv-value'}>{`${param.type + '-' + param.size || ''}`}</span>
          </div>

          <div className={'pv-div'} />

          <div className={'pv-line'}>
            {/* <span className={'pv-title'}>{STW.SCHEMA_CHANGE}</span> */}

          </div>

          <div className={'pv-line'}>
            <Formgroup className={'inherit'} inline={true}>
              <Editbox className={'edit table'} value={value && value.table} label={'schema'}
                placeholder={'SCHEMA'} onChange={(v) => onChange('table', v)} />
              <Editbox className={'edit user'} value={value && value.user} label={'user'}
                placeholder={'USER'} onChange={(v) => onChange('user', v)} />
              <Editbox className={'edit pass'} value={value && value.pass} label={'pass'}
                placeholder={'PASSWORD'} onChange={(v) => onChange('pass', v)} />
              <Combobox className={'combo dark sm host full'} select={value && value.host} label={'host'}
                list={[{ id: '', name: ST.SELECT }, ...HOSTLIST]} onChange={(eid, e, v) => onChange('host', v && v.id)} />
            </Formgroup>
          </div>

          <div className={'pv-line'}>
            <Formgroup className={'btn-grp'} >
              <Button className={'red sm save '} title={'SAVE'} onClick={onClickSave} disabled={!isconnect} />
              <Button className={'green sm connect '} title={'CONNECTION'} onClick={onClickConnect}
                disabled={!(value && value.table && value.user && value.pass && value.host)} />
              <Button className={'primary sm create '} title={'CREATE'} onClick={() => showSchema(true)} />
            </Formgroup>
          </div>

          <div className={'pv-div'} />

          <div className={'b-bottom'}>
            {cmdid && <MessageBox cmdid={cmdid} doReload={props.doReload} onResult={onResult} onRefresh={props.onRefresh} />}
            {noti && <div className={'b-noti'} onAnimationEnd={() => setNoti(null)}><p>{noti}</p></div>}
          </div>
        </div>
        <div className={'foot'}>
          <Button className={'gd-gray close'} title={ST.CLOSE} onClick={() => props.onClick && props.onClick('close')} />
        </div>
      </div>
    </StyledComp>
    {isschema && <PopupSchema {...value} onClose={() => showSchema(false)} />}
  </Fragment>
}

export const PopupBacklist = (props) => {
  const { data, param } = props;
  const { backups } = param;

  const [list, setList] = useState(null);
  const [array, setArray] = useState(null);

  useEffect(() => {
    actions.doSelect(URL.API.WEBMAN.DBDUMP, { sid: param.sid }).then((res) => {
      const { result } = res;
      result && setList(result.sort((a, b) => String(a.path).localeCompare(b.path)));
    });

    setArray(backups && backups.sort((a, b) => String(a).localeCompare(b)));

    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const url = path ? `http://${data.app.domain}:${data.app.port}${path}` : '';

  const isnodata = (!array || array.length < 1) && (!list || list.length < 1)

  return <StyledComp className={'popup-backup'} >
    <div className={'pop-bg'} />

    <div className={'pop-box'}>
      <div className={'head'}>
        <span className={'tit'}>{`${ST.WEBMAN.BACKLIST}`}</span>
        <span className={'sid'}>{`(${data.sid})`}</span>
      </div>
      <div className={'body'}>
        <div className={'t-tit'}>
          <span className={'t-txt'}>MEDIA BACKUP LIST</span>
        </div>
        {array && array.map((item, i) => {
          const url = `http://${data.app.ip}:${data.app.port}/backup/${item}`;
          if (data.app.domain === 'localhost') {
            return <div key={`a-${i}`} className={'pb-value click'} onClick={() => global.showAlert({ msg: ST.WEBMAN.DOWNNOTI })}>{item}</div>
          } else {
            return <a key={`a-${i}`} className={'pb-value click'} href={url} download>{`${item}`}</a>
          }
        })}
        <div className={'t-tit'}>
          <span className={'t-txt'}>DB BACKUP LIST</span>
        </div>
        {list && list.map((item, i) => {
          if (data.app.domain === 'localhost') {
            return <div key={`b-${i}`} className={'pb-value click'} onClick={() => global.showAlert({ msg: ST.WEBMAN.DOWNNOTI })}>{item.path}</div>
          } else {
            return <a key={`b-${i}`} className={'pb-value click'} href={'/backup/' + item.path} download>{`${item.path}`}</a>
          }
        })}
        {isnodata && <div className={'pb-nofile'}>{"NO BACKUP FILES"}</div>}
      </div>
      <div className={'foot'}>
        <Button className={'gd-gray close'} title={ST.CLOSE} onClick={() => props.onClick && props.onClick('close')} />
      </div>
    </div>
  </StyledComp>
}

export const PopupUpgrade = (props) => {
  const { data, param } = props;
  const [cmdid, setCmdid] = useState(null);
  const [noti, setNoti] = useState(null);
  const [version, setVersion] = useState(props.version || null);
  const [step, setStep] = useState('ready');
  const [tags, setTags] = useState(null);

  useEffect(() => {
    if (!props.data) return;

    const list = VERSION(props.dockerlist, `sentron/${getWebapp()}`);
    setTags(list);

    // 일단 최신 버전으로 선택
    (list && list.length > 1) && setVersion(list[1].id);

    if (props.onAutoRun) {
      setTimeout(() => {
        setVersion(props.version);
        onClick(props.version);
      }, 1000);
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWebapp = () => {
    if (props.param) {
      const temps = props.param.slice(",");
      let list = temps.filter(a => (a.indexOf('h') === 0 || a.indexOf(PTYPE.nurihome) === 0));
      if (list.length === temps.length) return PTYPE.nurihome;

      list = temps.filter(a => (a.indexOf('s') === 0 || a.indexOf(PTYPE.nurishop) === 0));
      if (list.length === temps.length) return PTYPE.nurishop;

      return null;
    }
  }

  const onClick = (ver = version) => {
    let webapp = getWebapp();
    if (!webapp) {
      global.showAlert({ msg: STW.DOCK_UPGRADE_ERR })
      return;
    }

    props.onAutoRun && props.onAutoRun('start');
    let value = props.data ? props.data.app : { sid: 'all', key: 'all' };
    value = { ...value, webapp: webapp, list: param, version: ver };
    actions.doRun(API.DOCK_UPGRADE, { ...value, key: value.skey }).then((res) => {
      const { code, result, err } = res;
      if (code === CODE.SUCCESS) {
        setCmdid(result.cmdid);
        setStep('running');
      } else {  // fail
        if (result && result.cmdid) setCmdid(result.cmdid);
        if (err) setNoti(err.status);
      }
    });
  }

  const onResult = (event) => {
    if (event === 'success' || event === 'fail') {
      setStep('ready');
      props.onRefresh && props.onRefresh();
      props.onAutoRun && props.onAutoRun('finish');
    }
  }

  const onChange = (label, value) => {
    setVersion(value);
    setNoti(null);
  }

  return <StyledComp className={'popup-upgrade'} >
    <div className={'pop-bg'} />

    <div className={'pop-box'}>
      <div className={'head'}>
        <span className={'tit'}>{`${ST.WEBMAN.DOCK_UPGRADE}`}</span>
        <span className={'sid'}>{`(${data.sid})`}</span>
      </div>
      <div className={'body'}>
        <div className={'pu-line'}>
          <span className={'pu-label'}>{'SID :'}</span>
          <span className={'pu-value'}>{`${data.sid || ''}`}</span>
        </div>
        <div className={'pu-line'}>
          <span className={'pu-label'}>{'CONTAINERS :'}</span>
          <span className={'pu-value'}>{`${Util.replaceAll(param, ",", ", ")}`}</span>
        </div>

        <div className={'pu-line'}>

        </div>

        <div className={'pu-line'}>
          <Formgroup inline={true}>
            <Combobox className={'combo dark sm ver inline full'} label={"VERSION"} select={version}
              list={tags} onChange={(eid, e, v) => onChange('version', v && v.id)} />
            <Button className={'gd-gray sm full'} title={'UPGRADE'} onClick={() => onClick(version)} disabled={(!version) || (step !== 'ready')} />
          </Formgroup>
        </div>

        <div className={'pu-div'} />

        <div className={'b-bottom'}>
          {cmdid && <MessageBox cmdid={cmdid} doReload={props.doReload} onResult={onResult} onRefresh={props.onRefresh} />}
          {noti && <div className={'b-noti'} onAnimationEnd={() => setNoti(null)}><p>{noti}</p></div>}
        </div>
      </div>
      <div className={'foot'}>
        <Button className={'gd-gray close'} title={ST.CLOSE} onClick={() => props.onClick && props.onClick('close')} />
      </div>
    </div>
  </StyledComp>
}

export const PopupSetter = (props) => {
  const config = `module.exports = {"dbs":{}, "app":{sid: 'wh2021001',"ip":"1.234.5.132", "port":"28050", "domain":"1.234.5.132","skey":"qlalfdldi"}}`;

  return <StyledComp className={'popup-setter'} >
    <div className={'pop-bg'} />

    <div className={'pop-box'}>
      <div className={'head'}>
        <span className={'tit'}>{`${ST.WEBMAN.SETAPP}`}</span>
      </div>
      <div className={'body'}>
        <a className={'gd-gray h-link'} href='/down/proxy-set.conf' download>Down proxy-set.conf</a>
        <a className={'gd-gray h-link'} href='/down/docker-compose.yml' download>Down compose.yml</a>
        <a className={'gd-gray h-link'} href='/down/nuriman.ins' download>Down nuriman.ins</a>
        <CopyToClipboard text={config} onCopy={() => global.showAlert({ msg: STW.COPYCLIPBOARD })} >
          <Button className={'white config b-copy'} title={STW.CONFIG} onClick={() => { }} />
        </CopyToClipboard>
      </div>
      <div className={'foot'}>
        <Button className={'gd-gray close'} title={ST.CLOSE} onClick={() => props.onClick && props.onClick('close')} />
      </div>
    </div>
  </StyledComp>
}
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Util, Loading, Button, TabButton, Tablebox, Svg, Listbox, Thumbbox, Editbox, Nodata } from 'object/src';
import { URL, PTYPE, CODE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import moment from 'moment';

const PAYALARM_LIMIT = 15;  // 결제일 경고 알림

const StyledObject = styled.div`{
  &.web-stat { ${cs.bg.trans} ${cs.p.h30} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(20)}
    ${cs.max.wxl} ${cs.w.full} ${cs.noselect}

    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} ${cs.m.b20} ${cs.m.t30} 
      ${cs.font.line(36)} ${cs.disp.block}
      .tit { ${cs.disp.inblock} ${cs.mouse.pointer} &:hover { ${cs.font.primary} } }
      .sim { ${cs.disp.inblock} ${cs.p.h10} ${cs.font.gray} }
    }

    .frame { ${cs.h.fit} ${cs.disp.block} 
      .wl { ${cs.w.get(240)} ${cs.border.right} ${cs.over.hidden} ${cs.over.yauto} ${cs.scrollbar.t2}
        ${cs.h.full} ${cs.disp.inblock} ${cs.p.h10} ${cs.min.h('calc(100vh - 200px)')}
        .li { ${cs.font.line(30)} ${cs.border.bottom} ${cs.border.darkgray} ${cs.mouse.pointer} ${cs.m.t2}
          ${cs.p.h10} ${cs.font.sm}
          &:hover { ${cs.bg.alphablack} }
          &.active { ${cs.bg.black} }
          &.ready { ${cs.font.yellow} }
          &.disable { ${cs.font.darkgray} }
        }
      }
      .wr { ${cs.w.calc('100% - 240px')} ${cs.disp.inblock} ${cs.top(0)} ${cs.float.right} 
        ${cs.p.l20} 
        .wr-frame { ${cs.h.full} ${cs.h.get('calc(100vh - 200px)')} }
      }
    }

    & > .tab-grp { ${cs.align.rtop} ${cs.right(30)} }
  }

  &.stat-box { ${cs.size.full}
    .line { ${cs.font.line(30)} ${cs.font.md} ${cs.pos.relative}
      .tit { ${cs.font.gray} ${cs.w.get(200)} ${cs.font.right} ${cs.p.r10} ${cs.disp.inblock} ${cs.font.sm} }
      .txt { ${cs.p.h10} ${cs.p.v5} ${cs.border.radius(3)}
        .tf { }
        .ts { ${cs.font.sm} ${cs.font.color("#bebebe")} ${cs.p.l5} }

        &.link:hover { ${cs.bg.alphablack} ${cs.font.lightprimary} ${cs.mouse.pointer} .ts { ${cs.font.lightprimary} } }
        &.btn { ${cs.bg.black} ${cs.p.a5} ${cs.p.h20} ${cs.border.radius(3)} ${cs.mouse.pointer} }
      }
      .ed-btn { ${cs.m.l0} ${cs.align.bottom} ${cs.bottom(5)} }

      &.warn {
        .txt { ${cs.font.yellow} 
          .ts { ${cs.font.yellow} } 
          &.link:hover { ${cs.bg.redhover} ${cs.font.yellow} .ts { ${cs.font.yellow} } } 
        } 
      }
      &.err { ${cs.bg.redhover} }

      &.primary { ${cs.bg.black} }
      // .txt { &:hover { ${cs.bg.alphablack} } }
    }

    .p-hist { ${cs.m.t30} ${cs.pos.relative}
      .p-tit { ${cs.border.bottom} ${cs.font.t0} ${cs.p.b5} ${cs.p.l5} }
      .p-stat { ${cs.align.rtop}
        .stat { ${cs.font.md} ${cs.m.l10} ${cs.p.h10} ${cs.p.v3} ${cs.border.radius(3)} ${cs.bg.gray} 
          &.active { ${cs.bg.green} }
          &.warn { ${cs.bg.red} }

          &.alarm { ${cs.bg.orange} }
        }
        .limit, .alarm { ${cs.mouse.pointer} }
      }
      .p-ul { ${cs.p.a10}
        .p-li { ${cs.disp.inblock} ${cs.w.get(300)} ${cs.h.get(100)} ${cs.over.hidden} ${cs.box.line}
          ${cs.box.radius} ${cs.m.h10} ${cs.p.a5}
          .tit { ${cs.w.full} ${cs.font.bold} ${cs.font.md} ${cs.m.b5} }
          .thumb { ${cs.w.get(80)} }
          .cont { ${cs.disp.inblock} ${cs.float.right} ${cs.w.get(200)} ${cs.h.full} 
            .txt { ${cs.disp.block} ${cs.font.sm} ${cs.p.l10} ${cs.m.t5} ${cs.font.line(15)} }
          }
        }
      }
    }
  }

  @media screen and (max-width : 600px) { 
  }
}`;

const API = URL.API;
const MB = 1024 * 1024;
const GB = 1024 * MB;

const Webstat = (props) => {
  const [title, setTitle] = useState('');
  const [list, setList] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [selected, setSelected] = useState(null);
  const [refresh, setRefresh] = useState(new Date());
  const [tab, setTab] = useState('info');

  useEffect(() => {
    doReload();
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = (callback = null) => {
    const param = actions.getParam();
    actions.doSelect(API.WEBSTAT).then((res) => {
      if (callback) return callback(res);

      const array = res.result && [
        ...res.result.filter(a => { if (a.tsid.indexOf("d_nuri") >= 0) { a['disable'] = true; return true; } else return false }),
        ...res.result.filter(a => { if (a.tsid.indexOf("_21001") >= 0) { a['disable'] = true; return true; } else return false }),
        ...res.result.filter(a => a.tsid.indexOf("d_nuri") < 0 && a.tsid.indexOf("_21001") < 0)
      ]

      setList(array);

      let item = array[0];
      (param && param.rowid) && (item = array.find(a => a.webid === param.rowid));
      selected && (item = array.find(a => a.webid === selected.webid));

      setTitle(item.wname);
      setSelected(item);
      setLoaded(true);
    });
  }

  const onClick = (eid, item, e) => {
    setTab(eid);
  }

  const onSelected = (item) => {
    setRefresh(new Date());
    setSelected(item);
    setTitle(item.wname);
  }

  const onClickStat = (eid, item) => {
    if (eid === 'log') {
      setTab('querys');
      // setSelected(item);
    } else if (eid === 'table') {
      setTab('systables');
    } else if (eid === 'query') {
      setTab('logins');
    }
  }

  const onSelect = (tablename) => {
    if (selected) {
      const { tsid } = selected;
      actions.go(URL.SCHEMA, { tschema: tsid, tablename: tablename })
    }
  }

  let TABS = [
    { id: 'info', title: STA.INFO },
    { id: 'layouts', title: STA.LAYOUTS },
    { id: 'systables', title: STA.TABLES },
    { id: 'logins', title: STA.LOGINS },
    { id: 'querys', title: STA.QUERYS },
  ];

  if (loaded) {
    return <StyledObject className="web-stat">
      <div className={cx('title')}>
        <span className={'tit'} onClick={() => actions.go(URL.MAIN)}>{ST.MAIN.WEBAPP}</span>
        <span className={'sim'}>{'>'}</span>
        <span className={'txt'}>{`${title || selected.tsid} ${ST.MAIN.WEBSTAT}`}</span>
      </div>
      <TabButton className={"tab-grp"} size={'md'} onClick={onClick} list={TABS} select={tab} color={'gd-gray'} />
      <div className={'frame'}>
        <ul className={cx('wl ul')}>
          {list && list.map((item, i) => {
            const active = item.webid === selected.webid;
            const disable = item.disable || false; //item.tsid.indexOf("d_nuri") >= 0;
            return <li key={i} className={cx('li', { active }, !item.wname && 'ready', { disable })} onClick={() => onSelected(item)}>
              <div>{`${item.wname || ST.READY}(${item.tsid})`}</div>
            </li>
          })}
        </ul>
        {selected && <div className={'wr'}>
          {tab === 'info' && <div className={cx('wr-frame', tab)}>
            <Statbox item={selected} doReload={doReload} refresh={refresh} onClick={onClickStat} />
          </div>}
          {tab === 'layouts' && <div className={cx('wr-frame', tab)}>
            <SysLayouts item={selected} doReload={doReload} refresh={refresh} />
          </div>}
          {tab === 'systables' && <div className={cx('wr-frame', tab)}>
            <SysTables item={selected} doReload={doReload} refresh={refresh} onSelect={onSelect} />
          </div>}
          {tab === 'logins' && <div className={cx('wr-frame', tab)}>
            <LoginLogs item={selected} doReload={doReload} refresh={refresh} />
          </div>}
          {tab === 'querys' && <div className={cx('wr-frame', tab)}>
            <QueryLogs item={selected} doReload={doReload} refresh={refresh} />
          </div>}
        </div>}
      </div>
    </StyledObject >
  } else {
    return <Loading />;
  }
};

export default Webstat;

const Statbox = (props) => {
  const { item } = props;
  const [popup, setPopup] = useState(null);

  const { url, ip, stat, tsid, wname, webid, host, apptype = '', userid = null, accountid = null } = item;
  const status = stat ? JSON.parse(stat) : null;
  const pays = item.pays ? JSON.parse(item.pays) : null;

  useEffect(() => {
    const param = actions.popParam();
    if (param && param.action && param.action === 'user') {
      setPopup(props.item);
    } else {
      setPopup(null);
    }
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = (eid) => {
    if (eid === 'url') {
      window.open(item.url || item.ip);
    } else {
      props.onClick && props.onClick(eid, item);
    }
  }

  const onClickEdit = (eid, e) => {
    if (eid === 'url') {
      e.stopPropagation();
      global.openModal({
        title: ST.WEBSTAT.URL, className: 'dark',
        size: 'xs', children: ModalUrl, data: item, onOk: (value) => {
          if (value) {
            actions.doUpdate(API.WEBSTAT_URL, { ...value, rowid: webid, tsid: tsid }).then(({ code }) => {
              if (code !== CODE.SUCCESS) Util.showAlert(props, code);
              props.doReload && props.doReload();

              // global.openConfirm({ type: 'info', size: 'sm', className: 'dark', msg: STA.PASS_RESET(value.pwd), cancel: false });
              // doReload();
            });
          }
        },
      });
    }
  }

  const onClickUser = () => {
    setPopup(item);
  }

  const onSave = (v) => {
    if (props.item) {
      props.item['userid'] = v.userid;
      props.item['accountid'] = v.accountid;
    };
    setPopup(null);
  }

  if (!status || !item) return <StyledObject className={cx('stat-box')}>
    <span className={'noitem'}>{"NO SERVICE"}</span>
  </StyledObject>

  const { lifetime = null, diskspace = {}, tablesize = {}, querylogs = {}, sslinfo = {}, folder = {} } = status;
  const time = lifetime ? moment(lifetime.lifetime.substr(0, 8) + ' ' + lifetime.lifetime.substr(8, 16)) : 0;
  const { free = 0, size = 0 } = diskspace;
  const { rows = 0, dsize = 0 } = tablesize;
  const { endtime = 0 } = sslinfo;
  const { errlogs = 0, lasterr = 0, dayerr = 0, dayserr = 0, weekerr = 0, montherr = 0 } = querylogs;

  // 11분 이상 차이날 경우 오류
  const lifestate = time.valueOf() < moment().valueOf() - (11 * 60 * 1000);

  // 20% 이하일 경우 경고
  const diskstate = (free / size) <= 0.2;

  // 로그 에러 상태
  const logstate = dayerr > 0 || dayserr > 0;

  // Upload 폴더 사이즈
  const { disk = 0 } = folder;
  const upstate = Number(disk) > 100 * MB;  //100M보다 크면

  // SSl 만료일자 상태
  const sslstate = moment(endtime).format("YYYYMMDD") - moment().format("YYYYMMDD") <= 5; // 만료 5일전이면 경고
  const isuser = accountid || userid;

  return <StyledObject className={'stat-box'}>
    <div className={'line'} onClick={() => onClick('url')}>
      <span className={'tit'}>{`${ST.WEBSTAT.NAME} : `}</span>
      <span className={'txt link'}>
        <span className={'tf'}>{wname}</span>
        <span className={'ts'}>{`(${webid})`}</span>
      </span>
    </div>
    <div className={'line'}>
      <span className={'tit'}>{`${ST.MAIN.APPTYPE} : `}</span>
      <span className={'txt link'}>
        <span className={'tf'}>{apptype}</span>
      </span>
    </div>
    <div className={'line'}>
      <span className={'tit'}>{`${ST.MAIN.ACCOUNTID} : `}</span>
      <span className={cx('txt link', !isuser && 'btn')}>
        {isuser
          ? <span className={'tf'} onClick={onClickUser} >{accountid || userid}</span>
          : <span className={'tf'} onClick={onClickUser} >{ST.MAIN.USERSET}</span>}
      </span>
    </div>
    <div className={'line'} onClick={() => onClick('url')}>
      <span className={'tit'}>{`${ST.WEBSTAT.URL} : `}</span>
      <span className={'txt link'}>
        <span className={'tf'}>{url}</span>
        <span className={'ts'}>{`(${ip})`}</span>
      </span>
      <Svg className={'md white ed-btn'} icon={'edit'} onClick={(eid, e) => onClickEdit('url', e)} />
    </div>
    <div className={cx('line', sslstate && 'warn')}>
      <span className={'tit'}>{`${ST.MAIN.SSL} : `}</span>
      <span className={'txt'}>
        <span className={'tf'}>{`${moment(endtime).format("YYYY.MM.DD")}`}</span>
        <span className={'ts'}>{ST.FROM}</span>
      </span>
    </div>
    <div className={cx('line', lifestate && 'warn')} onClick={() => onClick('log')}>
      <span className={'tit'}>{`${ST.WEBSTAT.LIFETIME} : `}</span>
      <span className={'txt link'}>
        <span className={'tf'}>{time.format("YYYY.MM.DD HH:mm:ss")}</span>
      </span>
    </div>
    <div className={'line'}>
      <span className={'tit'}>{`${ST.WEBSTAT.DBS} : `}</span>
      <span className={'txt '}>
        <span className={'tf'}>{tsid}</span>
        <span className={'ts'}>{`(${host})`}</span>
      </span>
    </div>
    <div className={cx('line', diskstate && 'warn')}>
      <span className={'tit'}>{`${ST.WEBSTAT.DISK} : `}</span>
      <span className={'txt'}>
        <span className={'tf'}>{`${(Number(size - free) / GB).toFixed(1)}${ST.GB}`}</span>
        <span className={'ts'}>{`(${((size - free) / size * 100).toFixed(0)}%)`}</span>
      </span>
    </div>
    <div className={cx('line', upstate && 'warn')}>
      <span className={'tit'}>{`${ST.WEBSTAT.UPLOAD} : `}</span>
      <span className={'txt'}>
        <span className={'tf'}>{`${(Number(disk) / MB).toFixed(1)}${ST.MB}`}</span>
        <span className={'ts'}>{`(${((size - (size - disk)) / size * 100).toFixed(2)}%)`}</span>
      </span>
    </div>
    <div className={cx('line')} onClick={() => onClick('table')}>
      <span className={'tit'}>{`${ST.WEBSTAT.TABLE} : `}</span>
      <span className={'txt link'}>
        <span className={'tf'}>{`${(Number(dsize) / MB).toFixed(1)}${ST.MB}`}</span>
        <span className={'ts'}>{`(${Util.commas(rows)}${ST.COUNT})`}</span>
      </span>
    </div>
    <div className={cx('line', logstate && 'warn')} onClick={() => onClick('log')}>
      <span className={'tit'}>{`${ST.WEBSTAT.LOGS} : `}</span>
      <span className={'txt link'}>
        <span className={'tf'}>{`${dayerr || 0}${ST.COUNT}`}</span>
        {/* <span className={'tf'}>{`${Util.commas(errlogs)}${ST.COUNT}`}</span> */}
        <span className={'ts'}>{`[3Day(${dayserr || 0}), Week(${weekerr || 0}), Month(${montherr || 0}${ST.COUNT}), Total:(${Util.commas(errlogs)})]`}</span>
      </span>
    </div>
    <div className={cx('line', logstate && 'warn')} onClick={() => onClick('log')}>
      <span className={'tit'}>{`${ST.WEBSTAT.LASTLOG} : `}</span>
      <span className={'txt link'}>
        <span className={'tf'}>{`${lasterr ? Util.toStringSymbol(lasterr) : 'no error'}`}</span>
      </span>
    </div>
    <OrderHistory pays={pays} userid={userid} webid={webid} item={item} doReload={props.doReload} />
    {/* {loading && <Loading className={''} type='ring' />} */}
    {popup && <PopupUser item={popup} onSave={onSave} onClose={() => setPopup(null)} />}
  </StyledObject>
}

const NURISHOP = 'https://www.nurioshop.co.kr';
const OrderHistory = (props) => {
  const [list, setList] = useState(null);
  const { start, end } = props.pays || { start: 0, end: 0, check: 0 };
  const { apptype = PTYPE.nurishop } = props.item;

  useEffect(() => {
    setList(null);
    if (props.userid) {
      doReload(props.userid);
    }
    // const a = moment.duration(moment(`20210517 000000`).diff(moment().format('YYYYMMDD'))).asDays();
    // console.dir(a);
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userid]);

  const doReload = (value) => {
    actions.doSelect(API.WEBSTAT_ORDER, { userid: value }).then((result) => {
      const array = result.trans && result.trans.sval && JSON.parse(result.trans.sval);
      const sets = result.package && result.package.sval && JSON.parse(result.package.sval);
      // // 앱타입과 동일한 타입의 결제타입만 필터링.
      // sets = sets && sets.filter(item => item.type === apptype);

      if (result.value && result.value.length > 0) {
        let temps = result.value.map(item => {
          const status = array && array.find(a => String(a.id) === String(item.status));
          status && (item['state'] = status.title);
          const type = sets && sets.find(a => String(a.id) === String(item.prodid));
          type && (item['type'] = type.type);
          // item.days = item.type === 'init' || item.type === 'month' ? 30 : item.type === 'year' ? 365 : 0;
          return item;
        });

        // 앱타입과 동일한 타입의 결제타입만 필터링.
        temps = temps && temps.filter(item => item.type === apptype);

        setList(temps);
      } else {
        setList(null);
      }
    }).catch(() => {
      setList(null);
    });
  }

  const onClickPay = () => {
    let value = end && moment(end).format("YYYY-MM-DD");
    global.openConfirm({
      size: 'sm', type: 'info', className: 'dark', title: ST.ALARM, ok: ST.YES, cancel: ST.NO,
      children: () => {
        const StyledPop = styled.div`{
          &.pay-pop { 
            .i-limit { ${cs.m.t20} }
            .label { ${cs.m.t10} }
          }
        }`;

        const onChange = (v, e, l) => {
          value = v;
        }

        return <StyledPop className={'pay-pop'}>
          <Editbox className={"i-limit"} type={'date'} size={'sm'} value={value} onChange={onChange} />
          <p className={'label'}>{ST.MAIN.SET_LIMIT}</p>
        </StyledPop>
      },
      onClicked: (isOk) => {
        if (isOk) {
          const param = { ...props.pays, end: Util.replaceAll(value, '-', '') };
          // value = JSON.stringify(param);
          actions.doUpdate(API.WEBAPP_PAY, { rowid: props.webid, value: param }).then(({ code, result }) => {
            props.doReload && props.doReload();
          });
        }
      }
    });
  }

  const onClickEmail = () => {
    // console.dir(email);

    global.openConfirm({
      size: 'sm', type: 'info', className: 'dark', title: ST.ALARM, ok: ST.YES, cancel: ST.NO, msg: ST.MAIN.SEND_PAY_EMAIL,
      onClicked: (isOk) => {
        if (isOk) {
          actions.doUpdate(API.WEBAPP_PAY_MAIL, { webid: props.webid }).then(({ code, result }) => {
            props.doReload && props.doReload();
          });
        }
      }
    });
  }

  const days = moment(end || new Date()).diff(new Date(), 'days');
  const islimit = days >= PAYALARM_LIMIT && props.item && props.item.sendmail === '00000000';
  return <div className={'p-hist'}>
    <div className={'p-tit'}>{ST.MAIN.HISTORY}</div>
    <span className={'p-stat'}>
      <span className={cx('stat', start ? 'active' : 'warn')}>{ST.MAIN.INIT(start ? 'OK' : 'NOK')}</span>
      <span className={cx('stat limit', days >= PAYALARM_LIMIT ? 'active' : 'warn')} onClick={onClickPay}>{ST.MAIN.LIMITDAYS(days ? days + 1 : 0)}</span>
      {islimit && <span className={cx('stat alarm')} onClick={onClickEmail}>{'SEND EMAIL'}</span>}
    </span>
    {list && list.length > 0 && <ul className={'p-ul'}>
      {list.map((hist, i) => {
        const { product, thumb, state, prodid, price, ctime, count } = hist;
        return <li key={i} className={'p-li'}>
          <p className={'tit'}>{product}</p>
          <Thumbbox className={'thumb sm'} src={`${NURISHOP}${thumb}`} />
          <div className={'cont sm'}>
            <span className={'txt'}>{prodid}</span>
            <span className={'txt'}>{`${Util.commas(price)}${ST.WON}/${count}${ST.COUNT} (${state})`}</span>
            <span className={'txt'}>{`${moment(ctime).format("YYYY.MM.DD")}`}</span>
          </div>
        </li>
      })}
      {/* {running && <Loading className={'relative'} type='ring' />} */}
    </ul>}
  </div>
}

const StyledComp = styled.div`{
  .p-line {${cs.w.full} ${cs.border.top} ${cs.border.darkgray} ${cs.h.get(1)} ${cs.max.wmd} ${cs.align.bottom} }

  &.sect {
      ${cs.m.v0} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.b10}
    ${cs.max.wmd} ${cs.w.full} ${cs.noselect} ${cs.h.full}
    .title {${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} }
    & > .sec-box {${cs.p.a10}
      div.edit-box { .guide {${cs.font.xs} ${cs.font.lightprimary} ${cs.m.t3} } }
      .tab-grp {${cs.align.rtop} ${cs.top(15)} ${cs.right(10)} }
    }
    .p-noti {${cs.m.v10} ${cs.font.yellow} ${cs.font.preline} ${cs.p.l5} ${cs.font.sm}
      &.hover:hover {${cs.font.underline} ${cs.mouse.pointer} }
    }
    .sec-foot {${cs.m.t20} ${cs.font.right} ${cs.p.h10}
      & > .button {${cs.m.l10} }
      .save, .test {${cs.w.get(120)} }
    }
  }

  &.mg-tables {
      ${cs.max.wlg}
    .card-list .tline .trow .tcol {${cs.font.md} ${cs.font.bold} ${cs.font.center}
      .tcol-label {${cs.font.prewrap} ${cs.font.line(16)} ${cs.align.ycenter} ${cs.pos.relative}
        ${cs.font.right}
      }
      .tcol-txt { }
    }

    .btn-schema { ${cs.align.top} }
  }

  &.mg-logins {
      ${cs.max.wlg}
    .search-frame { .sc - combo {${cs.z.menu} } }
    .auth {${cs.font.gray} ${cs.p.h2} ${cs.font.sm} }
  }


  &.mg-querys {
      ${cs.max.wxl}
    .search-frame { .sc - combo {${cs.z.menu} } }
    .table-box {
      .p - query {${cs.font.ellipsis} }
      .p-error {${cs.font.ellipsis} }
    }
  }
  
  &.mg-layout { ${cs.max.w('100%')}
    .p-list {
      .p-li {
        ${cs.disp.inblock} ${cs.w.r33} ${cs.h.get(80)} ${cs.over.hidden} 
        ${cs.p.a5} ${cs.mouse.pointer}
        .box {
          ${cs.box.radius} ${cs.size.full} ${cs.box.line} ${cs.p.v5}
          .thumb { ${cs.w.get(80)} ${cs.disp.inblock} }
          .cont { ${cs.disp.inblock} ${cs.float.right} ${cs.w.calc('100% - 90px')} ${cs.h.full}
            ${cs.font.sm}
            .tit { ${cs.w.full} ${cs.font.bold} ${cs.m.b10} ${cs.p.a5} }
            .txt { ${cs.disp.block} ${cs.font.gray} ${cs.p.a5} ${cs.font.line(18)} }
          }
        }
      }
    }
    .p-linebar { ${cs.w.full} ${cs.h.get(3)} ${cs.bg.red} ${cs.m.v10} }
  }

  &.pop-query {
    ${cs.m.t50} ${cs.pos.fixed} ${cs.left(0)} ${cs.top(0)} ${cs.size.full} ${cs.z.popup}
    ${cs.over.hidden}

    .p-bg {${cs.size.full} ${cs.disp.block} }

    .p-frame {${cs.align.center} ${cs.bg.black} ${cs.border.shadow()} ${cs.h.fit}
      ${cs.border.radius(10)} ${cs.box.light} ${cs.over.hidden} ${cs.w.dslg}
      .p-head {${cs.h.get(40)} ${cs.border.bottom} ${cs.border.dark} ${cs.align.top} ${cs.left(0)}
        ${cs.w.full} ${cs.disp.block} ${cs.bg.black} ${cs.z.front}
        .cancel {${cs.top(10)} ${cs.right(10)} }
        & > p {${cs.font.lg} ${cs.font.white} ${cs.font.thickbold} ${cs.font.line(40)} ${cs.p.l20} }
      }
      .p-body {${cs.min.h(300)}
        .pb-box {${cs.scrollbar.t3} ${cs.over.hidden} ${cs.over.yauto} ${cs.max.h(600)} ${cs.p.v50} ${cs.p.h20} }
        .p-tit {${cs.disp.block} ${cs.w.full} ${cs.font.md} ${cs.p.b5} ${cs.p.t5} ${cs.font.underline} 
          ${cs.font.gray} ${cs.font.bold} 
        }
        .p-txt {${cs.font.sm} ${cs.font.preline} ${cs.p.b10} ${cs.p.h5} ${cs.font.lightgray} }
      }
      .p-foot {${cs.h.get(40)} ${cs.border.top} ${cs.border.dark} ${cs.align.bottom} ${cs.left(0)}
        ${cs.w.full} ${cs.disp.block} ${cs.bg.black} ${cs.z.front}
        .iamport {${cs.m.t5} ${cs.m.l10} }
      }
    }
  }

  &.popup-user {${cs.disp.popup(1999999, cs.color.trans, 500, 400)}
    .pop-box {${cs.bg.lightblack} ${cs.border.shadow()}
      .head {${cs.bg.trans} ${cs.h.get(50)}
        .tit {${cs.font.t0} ${cs.p.h10} ${cs.font.line(50)} ${cs.h.full} }
      }

      .body {${cs.h.calc('100% - 100px')} ${cs.font.sm} ${cs.p.a20} ${cs.min.h(300)}
        .p-list { }
        .search-frame .search-box { ${cs.w.full} ${cs.m.b10} }
      }

      .foot {${cs.h.get(50)} ${cs.font.right} ${cs.p.h20}
        & > .button {${cs.m.l20} }
        .cancel {${cs.align.left} }
      }
    }
  }

  @media screen and (max-width : 1024px) {
    .card - list.tline.trow {
      ${cs.w.calc('50% - 10px')}
    }
  }

  @media screen and (max-width : 600px) {
    .search - frame.search - box {${cs.w.full} }

    &.mg-querys, &.mg-logins { 
      .table - box {${cs.m.t50} }
      .tab-grp {${cs.p.l0} ${cs.align.unset} ${cs.align.left} ${cs.left(10)} ${cs.top(40)}
        .button {${cs.p.h0} .btn-label {${cs.font.xs} } }
      }
    }
    .card-list .tline .trow {
      ${cs.w.full} ${cs.m.b30}
    }
  }
}`;

const STA = ST.MGMT;


const SysLayouts = (props) => {
  const { item } = props;
  const [list, setList] = useState(null);
  // const [layout, setLayout] = useState(null);
  const [others, setOthers] = useState(null);

  useEffect(() => {
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const doReload = () => {
    if (!item || item.apptype === 'nurishop') return;

    actions.doSelect(API.LAYOUTS, { schema: item.tsid || item.schema, host: item.host }).then((res) => {
      // const { value, layouts } = res;
      setList(res.value);
      // setLayout(res.layouts);

      let arr = [];
      res.layouts && res.layouts.forEach(a => {
        const temp = res.value && res.value.find(b => a.layid === b.layid);
        (!temp) && (arr.push(a));
      });

      setOthers(arr);
    })
  };

  const onClickDelete = (v) => {
    global.openConfirm({
      type: 'info', size: 'sm', msg: ST.WEBSTAT.LAYOUT_DEL(v.title), className: 'dark', onClicked: (isOk) => {
        (isOk) && actions.doDelete(API.LAYOUTS, { layid: v.layid, tschema: item.tsid, host: item.host }).then(() => {
          doReload();
        })
      }
    });
  }

  const onClickNew = (v) => {
    global.openConfirm({
      type: 'info', size: 'sm', msg: ST.WEBSTAT.LAYOUT_NEW(v.title), className: 'dark', onClicked: (isOk) => {
        (isOk) && actions.doInsert(API.LAYOUTS, { ...v, tschema: item.tsid, host: item.host }).then(() => {
          doReload();
        })
      }
    });
  }

  if (!item || item.apptype === 'nurishop') return <div>{'NO DATA'}</div>;
  return <StyledComp className={'mg-layout sect'}>
    <div className={'p-list'}>
      {list && list.map((a, i) => {
        return <LayoutForm key={i} item={a} onClick={() => onClickDelete(a)} />
      })}
      {list && <div className={'p-linebar'} />}
      {others && others.map((a, i) => {
        return <LayoutForm key={`i-${i}`} item={a} onClick={() => onClickNew(a)} />
      })}
    </div>
  </StyledComp>
}

const LayoutForm = (props) => {
  const { title, cont, thumb, ctag, layid } = props.item;

  return <li key={layid} className={'p-li p-form'} onClick={props.onClick}>
    <div className={'box'}>
      <Thumbbox className={'thumb sm'} src={`${thumb}`} />
      <div className={'cont sm'}>
        <span className={'tit'}>{`${title}(${ctag})`}</span>
        <span className={'txt'}>{cont}</span>
      </div>
    </div>
  </li>
}

export const SysTables = (props) => {
  const [list, setList] = useState(null);
  const [running, setRunning] = useState(false);

  const head = [
    { key: 'name', title: STA.TABEL, flex: '1 1 80px', align: 'left' },
    { key: 'commant', title: STA.COMMENT, flex: '5 1 200px', align: 'left', mobile: 'hide' },
    {
      key: 'rows', title: STA.ROWS, flex: '1 1 80px', type: 'number', tablet: 'hide',
      formatter: (v, item, pos) => {
        let color = cs.color.white;
        if (Number(v) > 100000) color = cs.color.red;
        return <span style={{ color: color }}>{Util.commas(v)}</span>
      }
    },
    {
      key: 'size', title: STA.SIZE, flex: '1 1 100px', type: 'number',
      formatter: (v, item, pos) => {
        let color = cs.color.white;
        if (Number(v) > 100) color = cs.color.red;
        return <span style={{ color: color }}>{Util.commas(v)}</span>
      }
    },
    {
      key: 'utime', title: ST.UTIME, flex: '2 1 100px', mobile: 'hide',
      formatter: (v, item, pos) => {
        if (v) {
          return moment(v).format("YY.MM.DD HH:mm");
        } else {
          return moment(item.ctime).format("YY.MM.DD HH:mm");
        }
      }
    },
    {
      key: 'button', title: STA.CLEAR, flex: '2 1 100px',
      formatter: (v, item, pos) => {
        const onClick = (e, param) => {
          e.stopPropagation();

          var value = 3;
          global.openConfirm({
            size: 'sm', type: 'info', className: 'dark', title: ST.ALARM, msg: STA.CLEARL_MSG, ok: ST.YES, cancel: ST.NO,
            children: () => {
              const StyledPop = styled.div`{ &.clear-pop { .label {${cs.m.t5} ${cs.m.b20} } .tab-grp {${cs.pos.relative} ${cs.m.t10} } } }`;
              const tabs = [{ id: '3', title: STA.THREE }, { id: '4', title: STA.FOUR }, { id: '5', title: STA.FIVE }];
              const onClickTab = (eid) => {
                value = eid;
              }

              return <StyledPop className={'clear-pop'}>
                <TabButton className={"tab-grp"} size={'sm'} onClick={onClickTab} list={tabs} select={tabs[0].id} color={'gd-gray'} />
                <p className={'label'}>{STA.CLEARL_MSG}</p>
              </StyledPop>
            },
            onClicked: (isOk) => {
              const schema = props.item && props.item.tsid;
              (isOk) && actions.doDelete(API.MGMT_TABLE, { value: value, table: param.name, schema }).then(({ code, result }) => {
                Util.showAlert(props, code);
                doReload();
              });
            }
          });
        }

        // 10MB가 넘거나 10000 레코드가 넘을경우에만 정리 버튼 표시
        const is = () => (item.size > 10 || item.rows > 10000);
        let enable;
        switch (item.name) {
          case 'notify': enable = is(); break;
          case 'z_login': enable = is(); break;
          case 'z_query': enable = is(); break;
          default: return '';
        }

        return <Button className={'primary sm'} title={STA.CLEAR} disable={!enable} onClick={(eid, e) => onClick(e, item)} />
      }
    },
  ];

  useEffect(() => {
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const doReload = (value) => {
    const { item } = props;
    setRunning(true);
    actions.doSelect(API.MGMT_TABLE, { schema: item.tsid || item.schema, host: item.host }).then(({ result }) => {
      result && result.map((a, i) => a['no'] = i + 1);
      setList(result);
      setRunning(false);
    })
  }

  const onClickSchema = () => {
    if (props.item) {
      const { tsid } = props.item;
      actions.go(URL.SCHEMA, { tschema: tsid, tablename: tsid })
    }
  }

  return <StyledComp className={cx('mg-tables sect')}>
    {/* <p className={'title'}>{STA.TITLE}</p> */}
    <div className={'sec-box'}>
      <p className={'p-noti'}>{STA.CLEAR_NOTI}</p>
      <Button className={'right gd-gray btn-schema'} title={'SCHEMA'} onClick={onClickSchema} />
      <Tablebox className={'dark shadow md'} head={head} list={list} rowid="name" height={30} onSelect={props.onSelect} />
    </div>
    {running && <Loading className={''} type='ring' />}
  </StyledComp>
}

const LoginLogs = (props) => {
  const [list, setList] = useState(null);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [tab, setTab] = useState('admin');
  const [running, setRunning] = useState(false);
  // const [order, setOrder] = useState('stime desc');
  // const [limit, setLimit] = useState(15);
  const order = 'stime desc';
  const limit = 15;

  const head = [
    { key: 'no', title: ST.NUM, flex: '1 1 40px' },
    { key: 'userid', title: STA.USERID, flex: '2 1 100px' },
    {
      key: 'user', title: STA.ACCOUNTID, flex: '5 1 200px', align: 'left',
      formatter: (v, item, pos) => {
        const data = JSON.parse(v);
        return <div>
          <span>{data.accountid}</span><span className={'auth'}>{`(${data.auth})`}</span>
        </div>
      }
    },
    { key: 'stime', title: STA.LOGINTIME, flex: '2 1 100px', type: 'datetime', format: 'YY.MM.DD HH:mm', mobile: 'hide' },
  ];

  useEffect(() => {
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const searchs = [
    { name: STA.USERID, id: 'userid' },
    { name: STA.ACCOUNT, id: 'accountid' },
    { name: STA.AUTH, id: 'auth' },
  ];

  const doReload = (p = page, s = search, o = order, l = limit, stab = tab) => {
    setRunning(true);

    let value = { host: props.item && props.item.host };

    if (s && s.value) {
      value['where'] = `${s.key} like '%${s.value}%'`;

      if (s.key === 'accountid') {
        value['where'] = `user like '%"accountid":%${s.value}%'`;
      }

      if (s.key === 'auth') {
        value['where'] = `user like '%"auth":%${s.value}%'`;
      }
    }

    let where = '';
    if (stab === 'admin')
      where = `(user like '%"auth":"system"%' or user like '%"auth":"super"%')`;
    else
      where = `(user like '%"auth":"user"%')`;

    if (value.where) {
      value['where'] += ' and ' + where;
    } else {
      value['where'] = where;
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;
    value['schema'] = props.item.tsid;

    actions.doList(API.MGMT_LOGS, value, true).then(({ result }) => {
      if (!result.page) return;

      setTotal(result.page.total)
      setPage(result.page.current);
      setMax(result.page.max);
      setSearch(s);
      setList(result.list);
      setRunning(false);
    })
  };

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  const onClick = (eid, item, e) => {
    setTab(eid);
    doReload(1, search, order, limit, eid);
  }

  if (!list) return null;

  const tabs = [{ id: 'admin', title: STA.ADMIN }, { id: 'user', title: STA.USER }];
  return <StyledComp className={cx('mg-logins sect')}>
    {/* <p className={'title'}>{STA.LOGINS}</p> */}
    <div className={'sec-box'}>
      <TabButton className={"tab-grp"} size={'sm'} onClick={onClick} list={tabs} select={tab} color={'gd-gray'} activeColor={'primary'} />
      <Tablebox className={cx('dark')} naviClass={'white'} head={head} list={list} searchkey={search ? search.key : searchs[0].id}
        onClickSearch={onClickSearch} onClickPage={onClickPage} pos={page} max={max} total={total} searchs={searchs} rowid="rowid" />
      {(!list || list.length < 1) && <Nodata />}
    </div>
    {running && <Loading className={''} type='ring' />}
  </StyledComp>
}

const QueryLogs = (props) => {
  const [list, setList] = useState(null);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [tab, setTab] = useState('error');
  const [running, setRunning] = useState(false);
  const [popup, setPopup] = useState(null);
  // const [order, setOrder] = useState('stime desc');
  // const [limit, setLimit] = useState(15);
  const order = 'ctime desc';
  const limit = 15;

  const head = [
    { key: 'no', title: ST.NUM, flex: '1 1 40px' },
    { key: 'type', title: STA.TYPE, flex: '2 1 100px' },
    {
      key: 'query', title: STA.QUERY, flex: '5 1 200px', align: 'left', mobile: 'hide',
      formatter: (v, item, pos) => {
        return <div className={'p-query'}><span>{v}</span></div>
      }
    },
    {
      key: 'error', title: STA.ERROR, flex: '5 1 200px', align: 'left',
      formatter: (v, item, pos) => {
        let text;
        if (!v || v === '""') {
          text = '';
        } else {
          text = v;
          // text = JSON.parse(v);
        }
        return <div className={'p-error'}><span>{text}</span></div>
      }
    },
    { key: 'ctime', title: ST.CTIME, flex: '2 1 100px', type: 'datetime', format: 'YY.MM.DD HH:mm:ss', mobile: 'hide' },
  ];

  useEffect(() => {
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const searchs = [
    // {name: STA.TYPE, id: 'type' },
    { name: STA.QUERY, id: 'query' },
    { name: STA.ERROR, id: 'error' },
  ];

  const doReload = (p = page, s = search, o = order, l = limit, stab = tab) => {
    setRunning(true);

    let value = { host: props.item && props.item.host };

    if (s && s.value) {
      value['where'] = `${s.key} like '%${s.value}%'`;
    }

    let where = '';
    if (stab && stab !== 'all') where = `type='${stab}'`;
    if (stab && stab === 'error') where = `error != '""'`;

    if (value.where && where) {
      value['where'] += ' and ' + where;
    } else if (!value.where && where) {
      value['where'] = where;
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;
    value['schema'] = props.item.tsid;

    actions.doList(API.MGMT_LOGS, { ...value, type: 'query' }, true).then(({ result }) => {
      if (!result.page || !result.list) return;

      result.list.map(a => a.type = a.type.toLowerCase());

      setTotal(result.page.total)
      setPage(result.page.current);
      setMax(result.page.max);
      setSearch(s);
      setList(result.list);
      setRunning(false);
    })
  };

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  const onClick = (eid, item, e) => {
    setTab(eid);
    if (eid === 'all') {
      doReload(1, search, order, limit, '');
    } else {
      doReload(1, search, order, limit, eid);
    }
  }

  const onSelect = (id) => {
    let item = list.find(a => Number(a.rowid) === Number(id));
    setPopup(item);
  };

  const onClickPopup = () => {
    setPopup(null);
  }

  if (!list) return null;

  const tabs = [
    { id: 'all', title: 'ALL' }, { id: 'insert', title: 'INSERT' },
    { id: 'update', title: 'UPDATE' }, { id: 'delete', title: 'DELETE' },
    { id: 'error', title: 'ERROR' }
  ];

  return <StyledComp className={cx('mg-querys sect')}>
    {/* <p className={'title'}>{STA.QUERYS}</p> */}
    <div className={'sec-box'}>
      <TabButton className={"tab-grp"} size={'sm'} onClick={onClick} list={tabs} select={tab} color={'gd-gray'} activeColor={'primary'} />
      <Tablebox className={cx('dark')} naviClass={'white'} head={head}
        list={list} searchkey={search ? search.key : searchs[0].id}
        onSelect={onSelect} onClickSearch={onClickSearch} onClickPage={onClickPage}
        pos={page} max={max} total={total} searchs={searchs} rowid="rowid" />
      {(!list || list.length < 1) && <Nodata />}
    </div>
    {running && <Loading className={''} type='ring' />}
    {popup && <PopQuery data={popup} onClick={onClickPopup} />}
  </StyledComp>
}

/*******************************************************************
 Popup
*******************************************************************/

const PopQuery = (props) => {
  const { onClick, data } = props;
  const { query, error = '' } = data;

  return <StyledComp className={'pop-query'} >
    <div className={'p-bg'} onClick={(e) => onClick('cancel', e)} />
    <div className={'p-frame'} >
      <div className={'p-head'}>
        <p>{STA.QUERYS}</p>
        <Svg className={'cancel right'} icon={'cancel'} onClick={(e) => onClick('cancel', e)} />
      </div>
      <div className={'p-body'}>
        <div className={'pb-box'}>
          <span className={'p-tit'}>{STA.QUERY}</span>
          <p className={'p-txt'}>{query}</p>
          <span className={'p-tit'}>{STA.ERROR}</span>
          <p className={'p-txt p-log'}>{error !== '""' ? error : ''}</p>
        </div>
      </div>
      <div className={'p-foot'}>
        <Button className={'right'} icon={'exit'} title={ST.CLOSE} onClick={(e) => onClick('cancel', e)} />
      </div>
    </div>
  </StyledComp>
}


/*******************************************************************
 Popup
*******************************************************************/

const PopupUser = (props) => {
  const [list, setList] = useState(null);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [running, setRunning] = useState(false);
  const order = 'ctime desc';
  const limit = 10;

  useEffect(() => {
    doReload();
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const doReload = (p = page, s = search, o = order, l = limit) => {
    setRunning(true);

    let value = {};
    if (s && s.value) {
      value['where'] = `${s.key} like '%${s.value}%'`;
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    setList(null);
    actions.doList(API.WEBSTAT_USERS, value, true).then(({ result }) => {
      if (!result.page) return;

      const temps = result && result.data.map(a => {
        a.title = `[${a.userid}] ${a.accountid}`;
        if (a.name) a.title += `(${a.name})`;
        return a;
      });

      setTotal(result.page.total)
      setPage(result.page.current);
      setMax(result.page.max);
      setSearch(s);
      setList(temps);
      setRunning(false);
    })
  };

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  const onSelect = (id, e, item) => {
    const { webid, userid } = props.item;
    if (userid) {
      global.openConfirm({
        type: 'info', size: 'sm', msg: ST.MAIN.USER_CHANGE, className: 'dark', onClicked: (isOk) => {
          (isOk) && actions.doUpdate(API.WEBSTAT_USERS, { userid: item.userid, rowid: webid }).then((result) => {
            props.onSave && props.onSave(item);
          })
        }
      });
    } else {
      actions.doUpdate(API.WEBSTAT_USERS, { userid: item.userid, rowid: webid }).then((result) => {
        props.onSave && props.onSave(item);
      })
    }
  }

  const searchs = [
    { name: STA.ACCOUNT, id: 'accountid' },
    { name: STA.USERID, id: 'userid' },
    { name: STA.NAME, id: 'name' },
  ];

  return <StyledComp className={'popup-user'} >
    <div className={'pop-bg'} />

    <div className={'pop-box'}>
      <div className={'head'}>
        <p className={'tit'}>{ST.MAIN.USERSET}</p>
      </div>
      <div className={'body'}>
        <Listbox className={"p-list dark"} list={list} rowid="userid" title={'subject'}
          datealign={'bottom'} titlealign={'left'} naviClass={'white'}
          searchkey={search ? search.key : searchs[0].id}
          onSelect={onSelect} onClickSearch={onClickSearch} onClickPage={onClickPage}
          pos={page} max={max} total={total} searchs={searchs} />
        {running && <Loading type='ring' />}
      </div>
      <div className={'foot'}>
        <Button className={'gd-gray close'} title={ST.CLOSE} onClick={props.onClose} />
      </div>
    </div>
  </StyledComp >
}


/*******************************************************************
 Popup
*******************************************************************/
const ModalUrl = (props) => {
  var refs = {};
  const { data } = props;
  const [guide, setGuide] = useState(null);

  useEffect(() => {
    if (!props.data) return;

    const { tsid } = props.data;
    actions.doSelect(API.WEBMAN.DOCKER, { sname: tsid.replace('_', '') }).then(({ result }) => {
      if (result && result.opts) {
        const temp = JSON.parse(result.opts);
        temp && (setGuide({ ip: temp.ip, domain: temp.domain }));
      }
    });

    return () => {
    }
  }, [props.data])

  props.act.getData = (checkValidate) => {
    // validate 체크하여 통과하지 못하면 false를 리턴(창이 닫히지 않는다.)
    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidate());
    if (!isvalidate) return false;

    const url = refs.url.getValue();
    if (!Util.isUrl(url)) {
      refs.url.showNoti(ST.WEBSTAT.IS_URL);
      return false;
    }

    const ip = refs.ip.getValue();
    if (!Util.isIp(ip)) {
      refs.ip.showNoti(ST.WEBSTAT.IS_IP);
      return false;
    }

    return { url, ip };
  }

  return (
    <StyledObject className={'url-modal'}>
      <Editbox name="url" className="right" type="text" ref={(ref) => { refs.url = ref }}
        value={data.url || (guide && `https://${guide.domain}`)} label={ST.WEBSTAT.ONLY_URL} placeholder={guide && guide.domain} focus={true} />
      <Editbox name="ip" className="right" type="text" ref={(ref) => { refs.ip = ref }}
        value={data.ip || (guide && guide.ip)} label={ST.WEBSTAT.IP} placeholder={guide && guide.ip} />
    </StyledObject>
  );
}
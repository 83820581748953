import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Loading, Button, Util, TabButton, Combobox, Svg } from 'object/src';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import moment from 'moment';

const PAYALARM_LIMIT = 15;  // 결제일 경고 알림

const StyledObject = styled.div`{
  &.ad-main { ${cs.bg.trans} ${cs.p.h30} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(80)}
    ${cs.w.full} ${cs.noselect}

    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} ${cs.m.b20} ${cs.m.t30} 
      ${cs.pos.relative}
      & > p { ${cs.w.fit} ${cs.disp.inblock} ${cs.float.left} }
      .tab-tool { ${cs.m.left(30)}
        .tab-grp { ${cs.m.l30} }
      }
      .p-noti { ${cs.font.md} ${cs.font.gray} ${cs.font.line(30)} ${cs.p.l10} ${cs.align.bottom} ${cs.bottom(5)} }
    }
    .up-time { ${cs.align.rtop} ${cs.font.gray} ${cs.top(30)} ${cs.right(10)} ${cs.font.sm} }
  }

  &.stat-box {
    ${cs.w.full}
    .stat-body { ${cs.size.full} ${cs.disp.block} ${cs.pos.relative} 
      .shortcat { ${cs.align.rbottom} ${cs.z.over} ${cs.font.sm} ${cs.font.gray} ${cs.font.underline} 
        ${cs.mouse.pointer} ${cs.bg.black} ${cs.p.a3} ${cs.p.h10} ${cs.border.radius(3)}
      }
    }
  }

  &.web-stat {
    .w-ul {
      .w-li { ${cs.disp.inblock} ${cs.w.get('20%')} ${cs.h.fit} ${cs.min.h(100)} ${cs.p.a5} ${cs.mouse.pointer} }
    }

    &.small { .w-ul .w-li { ${cs.w.get('10%')} ${cs.min.h(100)} } }
  }

  &.w-box { ${cs.box.line} ${cs.size.full} ${cs.box.radius} ${cs.anim.showin()} ${cs.pos.relative}
    ${cs.font.sm} ${cs.font.line(14)} ${cs.over.hidden} ${cs.p.h3} ${cs.min.h(240)} ${cs.over.inherit}

    .head { 
      ${cs.font.md} ${cs.font.bold} ${cs.font.center} ${cs.border.bottom} ${cs.border.darkgray}
      ${cs.font.line(20)} ${cs.font.yellow} ${cs.over.inherit}
      .txt { ${cs.font.sm} ${cs.opac.get(0.7)} ${cs.font.line(12)} ${cs.disp.block} ${cs.p.b5} }
      .ticon { ${cs.align.left} ${cs.left(-10)} ${cs.top(-10)} .svg-path { ${cs.opac.get(0.9)} } }
    }

    .frame { ${cs.p.a5} ${cs.h.full} ${cs.font.sm}
      & > div { ${cs.disp.block} ${cs.font.lightgray} ${cs.p.h5} ${cs.m.v3} ${cs.border.radius(2)} }
      .label { ${cs.font.xs} ${cs.font.gray} ${cs.w.get(60)} ${cs.disp.inblock} ${cs.font.right} ${cs.p.r10} }
      .unit { ${cs.font.xs} ${cs.font.gray} }
      .send-mail { ${cs.pos.relative}
        .mail { ${cs.align.left} ${cs.left(20)} ${cs.top(-3)} }
      }
    }

    .noitem { ${cs.align.center} ${cs.font.gray} ${cs.font.bold} ${cs.m.t15} }
    .nouser { ${cs.bg.redhover} ${cs.font.center} ${cs.p.a10} & > span { ${cs.font.yellow} } }

    &.primary { ${cs.bg.black} }
    &:hover { ${cs.bg.alphablack} }

    .warn, &.warn { ${cs.bg.color("#821200")} }
    .err { ${cs.bg.redhover} }

    &.small { ${cs.min.h(70)} .head { ${cs.font.sm} } .frame { ${cs.font.center} } &.warn { ${cs.border.color(cs.color.red)} } }
  }

  @media screen and (max-width : 600px) { 
  }
}`;

const API = URL.API;

const FILTERS = [
  { id: 'all', title: 'ALL', where: '', noti: '모든 서비스 제품', },
  {
    id: 'svcweb', title: 'PRODUCTS', noti: '상용 판매된(서비스중인) nurishop/nurihome 제품',
    where: 'apptype in ("nurihome", "nurishop") and !(tsid like "d_%" or url like "%nurio.co.kr") and tsid not in ("s_nurishop", "h_nurihome")'
  },
  {
    id: 'nuriweb', title: 'NURIWEB', noti: '자사몰(nurioshop.cokr/nuriohome.co.kr)을 포함한 PRODUCTS 제품',
    where: 'apptype in ("nurihome", "nurishop") and !(tsid like "d_%" or url like "%nurio.co.kr")'
  },
  {
    id: 'nurihome', title: 'NURIHOME', noti: 'nurihome의 모든 제품',
    where: 'apptype="nurihome"'
  },
  {
    id: 'nurishop', title: 'NURISHOP', noti: 'nurishop의 모든 제품',
    where: 'apptype="nurishop"'
  },
  {
    id: 'testweb', title: 'TESTWEB', noti: 'nurihome/nurishop의 테스트용 제품',
    where: 'tsid like "d_%" or url like "%nurio.co.kr"'
  },
];

const Mainpage = (props) => {
  const TABS = [{ id: 'small', title: 'SMALL' }, { id: 'full', title: 'FULL' }];

  const [loaded, setLoaded] = useState(false);
  const [issmall, setSmall] = useState(true);
  const [filter, setFilter] = useState(1);

  useEffect(() => {
    doReload();

    actions.doSelect(API.APPINFO, { stag: 'system', skey: 'init' }).then(({ result }) => {
      const onClosePopup = () => {
        actions.doUpdate(API.SETTING, { stag: 'system', skey: 'init', sval: 'popclose' }).then(() => {
          window.location.href = URL.MAIN;
        });
      }

      const StyledComp = styled.div`{
        &.sys-init { } 
          .close-btn { ${cs.align.lbottom} ${cs.bottom(15)} ${cs.left(0)} ${cs.font.gray} } 
        }
      }`;

      const { sval } = result && result.length > 0 && result[0];
      if (sval === 'ready') {
        setTimeout(() => {
          props.openConfirm({
            type: 'info', size: 'md', cancel: false, className: 'black',
            children: () => {
              return <StyledComp className={'sys-init'}>
                <span>{ST.SYSINIT.CONFIRM}</span>
                <Button className={'close-btn'} title={ST.SYSINIT.POP_CLOSE} onClick={onClosePopup} />
              </StyledComp>
            },
            onClicked: (isOk) => {
              if (isOk) {
                // 시스템 초기 가이 화면으로 이동.
                window.location.href = URL.SYSINIT
                // onSystemInit(() => window.location.href = URL.SYSINIT);
              }
            }
          });
        }, 200);
      }
    });

    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.noti) return;
    doReload();
    return () => {
    }
  }, [props.noti]);

  const doReload = () => {
    setLoaded(true);
  }

  const onClickTab = (eid) => {
    setSmall(eid === 'small');
  }

  const onChangeCombo = (v, eid, i) => {
    setFilter(i);
  }

  if (loaded) {
    return <StyledObject className="ad-main">
      <div className={'title'}>
        <p>{ST.MAIN.WEBAPP}</p>
        <span className={'up-time'}>{`UPTIME : ${moment(props.refresh).format('HH:mm:ss')}`}</span>

        <span className={'tab-tool'}>
          <TabButton className={"tab-grp"} size={'sm'} onClick={onClickTab} list={TABS} select={issmall ? 'small' : 'full'} color={'gd-gray'} />
          <Combobox className={'tab-grp dark sm nofloat'} flex={"2 1 120px"} options={{ text: { align: 'center' } }}
            list={FILTERS} onChange={(eid, e, v, i) => onChangeCombo(v, 'id', i)} pos={filter} />
          <span className={'p-noti'}>{FILTERS[filter].noti}</span>
        </span>
      </div>
      <Webstat className={""} icon={'cart'} small={issmall} filter={filter} />
    </StyledObject >
  } else {
    return <Loading />;
  }
};

export default Mainpage;

const MB = 1024 * 1024;
const GB = 1024 * MB;

var where = '';
var timer = null;

const Webstat = (props) => {
  const { filter } = props;
  const [list, setList] = useState(null);

  useEffect(() => {
    const temp = FILTERS[filter];
    where = temp ? temp.where : '';

    doReload(where);
    timer = setInterval(() => {
      doReload(where);
    }, 5 * 60 * 1000);  // 5분마다
    return () => {
      timer && clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const temp = FILTERS[props.filter];
    where = temp ? temp.where : '';

    doReload(where);

    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter])

  const doReload = (param) => {
    actions.doSelect(API.WEBAPP, { where: param }).then((res) => {
      const array = res.result && [...res.result.filter(a => a.tsid.indexOf("_21001") >= 0), ...res.result.filter(a => a.tsid.indexOf("_21001") < 0)]
      setList(array);
      // setList(res.result);
    })
  };

  const onClick = (item, e) => {
    e.stopPropagation();
    // (item && item.webid && item.url) && actions.go(URL.WEBSTAT, { rowid: item.webid, title: item.wname });
    (item && item.webid) && actions.go(URL.WEBSTAT, { rowid: item.webid, title: item.wname, action: 'main' });
  }

  const { small } = props;
  return <StyledObject className={cx("web-stat", props.className, { small })}>
    <ul className={'w-ul'}>
      {list && list.map((item, i) => {
        return <li key={i} className={'w-li'} onClick={(e) => onClick(item, e)}>
          <Webbox item={item} small={small} />
        </li>
      })}
    </ul>
  </StyledObject >
}

const Webbox = (props) => {

  useEffect(() => {
    return () => {
    }
  }, []);

  const { small } = props;
  const { wname = null, url, stat, tsid, pays, apptype = '', userid = null, sendmail = '00000000' } = props.item;
  const status = stat ? JSON.parse(stat) : null;
  const { start = '', end = '' } = pays ? JSON.parse(pays) : { start: '', end: '' };
  const limit = moment(end || new Date()).diff(new Date(), 'days');

  const primary = url && (url.indexOf('localhost') >= 0 || url.indexOf('nurioshop') >= 0 || url.indexOf('nuriohome') >= 0) ? true : false;
  if (!status) return <StyledObject className={cx('w-box', { primary }, { small })}>
    <div className={'head'}>{`${wname || tsid}`}</div>
    <div className={'frame'}><span className={'noitem'}>{"NO SERVICE"}</span></div>
  </StyledObject>

  const { lifetime = null, diskspace = {}, tablesize = {}, querylogs = {}, sslinfo = {}, folder = {} } = status;
  const time = lifetime ? moment(lifetime.lifetime.substr(0, 8) + ' ' + lifetime.lifetime.substr(8, 16)) : 0;
  const { rows = 0, dsize = 0 } = tablesize;
  const { endtime = 0 } = sslinfo;

  // 11분 이상 차이날 경우 오류
  const lifestate = time.valueOf() < moment().valueOf() - (11 * 60 * 1000);

  // 20% 이하일 경우 경고
  const { free = 0, size = 0 } = diskspace;
  const diskstate = (free / size) <= 0.2;

  // 로그 에러 상태
  const { totlogs = 0, dayerr = 0, dayserr = 0, weekerr = 0, montherr = 0 } = querylogs;
  const logstate = dayerr > 0 || dayserr > 0;

  // Upload 폴더 사이즈
  const { disk = 0 } = folder;
  const upstate = Number(disk) > 100 * MB;  //100M보다 크면

  // 결제 상태 정보
  // const { days, limits, start = null } = paystat;

  const isstart = !start;
  // const islimit = limit <= PAYALARM_LIMIT;
  const islimit = limit <= PAYALARM_LIMIT && sendmail === '00000000';

  // SSl 만료일자 상태
  // console.dir(moment(endtime).format("YYYYMMDD") - moment().format("YYYYMMDD"));
  const sslstate = moment(endtime).format("YYYYMMDD") - moment().format("YYYYMMDD") <= 5; // 만료 5일전이면 경고
  const isnok = lifestate || sslstate || diskstate || logstate || isstart || islimit || sendmail !== '00000000';
  const mail = `${sendmail === '00000000' ? '' : moment(sendmail + ' 000000').format('YY/MM/DD')}`;

  const onClick = (item, e) => {
    e.stopPropagation();
    (item && item.webid) && actions.go(URL.WEBSTAT, { rowid: item.webid, title: item.wname, action: 'user' });
  }

  return <StyledObject className={cx('w-box', { primary }, { small }, small && isnok && 'warn')}>
    <div className={'head'}>{`${wname || 'N/A'}`}
      <Svg className={'md white ticon'} icon={apptype === 'nurihome' ? 'home' : 'cart'} />
      <span className={'txt'}>{`(${tsid})`}</span>
    </div>
    {small ?
      <div className={cx('frame')}>
        <div className={cx('apptype', isnok && 'warn')}>
          <span className={'txt'}>{`${ST.MAIN.LIMITDAYS(limit)}`}</span>
        </div>
        {mail ? <div className={'send-mail'}>
          <Svg className={'sm white mail'} icon={'mailbox'} />
          <span className={'txt'}>{mail}</span>
        </div> : <span>OK</span>}
        {/* {mail && <span className={'txt'}>{`${sendmail === '00000000' ? 'OK' : moment(sendmail + ' 000000').format('YY/MM/DD')}`}</span>} */}
      </div> :
      <div className={'frame'}>
        <div className={'apptype'}>
          <span className={'label'}>{`${ST.MAIN.APPTYPE} : `}</span>
          <span className={'txt'}>{`${apptype}`}</span>
        </div>
        <div className={'host'}>
          <span className={'label'}>{`${ST.MAIN.HOST} : `}</span>
          <span className={'txt'}>{`${url}`}</span>
        </div>
        <div className={cx('ltime', lifestate && 'warn')}>
          <span className={'label'}>{`${ST.MAIN.UPTIME} : `}</span>
          <span className={'txt'}>{`${time.format("MM/DD HH:mm")}`}</span>
        </div>
        <div className={cx('ssl', sslstate && 'warn')}>
          <span className={'label'}>{`${ST.MAIN.SSL} : `}</span>
          <span className={'txt'}>{`${moment(endtime).format("YYYY.MM.DD")}`}</span>
          <span className={'unit'}>{ST.FROM}</span>
        </div>
        <div className={cx('disk', diskstate && 'warn')}>
          <span className={'label'}>{`${ST.MAIN.DISK} : `}</span>
          <span className={'txt'}>{`${(Number(size - free) / GB).toFixed(1)}`}</span>
          <span className={'unit'}>{ST.GB}</span>
          <span className={'txt'}>{`(${((size - free) / size * 100).toFixed(0)}`}</span>
          <span className={'unit'}>{'%'}</span>
          <span className={'txt'}>{')'}</span>
        </div>
        <div className={cx('upload', upstate && 'warn')}>
          <span className={'label'}>{`${ST.MAIN.UPLOAD} : `}</span>
          <span className={'txt'}>{`${(Number(disk) / MB).toFixed(1)}`}</span>
          <span className={'unit'}>{ST.MB}</span>
          <span className={'txt'}>{`(${((size - (size - disk)) / size * 100).toFixed(2)}`}</span>
          <span className={'unit'}>{'%'}</span>
          <span className={'txt'}>{')'}</span>
        </div>
        <div className={'table'}>
          <span className={'label'}>{`${ST.MAIN.TABLE} : `}</span>
          <span className={'txt'}>{`${(Number(dsize) / MB).toFixed(1)}`}</span>
          <span className={'unit'}>{ST.MB}</span>
          <span className={'txt'}>{` (${Util.commas(rows)}`}</span>
          <span className={'unit'}>{ST.COUNT}</span>
          <span className={'txt'}>{')'}</span>
        </div>
        <div className={cx('logs', logstate && 'warn')}>
          <span className={'label'}>{`${ST.MAIN.LOGS} : `}</span>
          <span className={'txt'}>{`${dayerr || 0}, ${dayserr || 0}, ${weekerr || 0}, ${montherr || 0}`}</span>
          <span className={'unit'}>{ST.COUNT}</span>
          <span className={'txt'}>{` (${Util.commas(totlogs)})`}</span>
        </div>
        {userid
          ? <Fragment>
            <div className={cx('pays', isstart && 'warn')}>
              <span className={'label'}>{`${ST.MAIN.PAYS} : `}</span>
              <span className={'txt'}>{ST.MAIN.INIT(start ? 'OK' : 'NOK')}</span>
            </div>
            <div className={cx('pays', islimit && 'warn')}>
              <span className={'label'}>{`${ST.MAIN.LIMIT} : `}</span>
              <span className={'txt'}>{`${ST.MAIN.LIMITDAYS(limit ? limit + 1 : 0)}`}</span>
            </div>
          </Fragment>
          : <div className={cx('nouser')} onClick={(e) => onClick(props.item, e)}>
            <span className={''}>{`${ST.MAIN.NOUSER}`}</span>
          </div>}
      </div>}
  </StyledObject >
}